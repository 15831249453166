function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V12C0 11.45 0.195833 10.9792 0.5875 10.5875C0.979167 10.1958 1.45 10 2 10H16C16.55 10 17.0208 10.1958 17.4125 10.5875C17.8042 10.9792 18 11.45 18 12H2V16H12V18H2ZM2 8C1.45 8 0.979167 7.80417 0.5875 7.4125C0.195833 7.02083 0 6.55 0 6V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V6C18 6.55 17.8042 7.02083 17.4125 7.4125C17.0208 7.80417 16.55 8 16 8H2ZM2 6H16V2H2V6ZM16 20V18H14V16H16V14H18V16H20V18H18V20H16Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
