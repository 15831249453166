/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Button from './Button';

import MainBody from './Body';
import Subheader from './Subheader';

import { Avatar } from '@bigheads/core';
import ModalProfileSteps from './ModalProfileSteps';
import toast from 'react-hot-toast';

const MyAvatar = inject('store')(
  observer(({ store }) => {
    // Download SVG
    const svgRef = useRef(null);

    // Avatar controller variables and functions
    const accessoryMap = ['none', 'roundGlasses', 'tinyGlasses', 'shades'];
    const bodyMap = ['chest', 'breasts'];
    const clothingMap = [
      'naked',
      'shirt',
      'dressShirt',
      'vneck',
      'tankTop',
      'dress',
    ];
    const clothingColorMap = ['white', 'blue', 'black', 'green', 'red'];
    const eyebrowsMap = [
      'raised',
      'leftLowered',
      'serious',
      'angry',
      'concerned',
    ];
    const eyesMap = [
      'normal',
      'leftTwitch',
      'happy',
      'content',
      'squint',
      'simple',
      'dizzy',
      'wink',
      'heart',
    ];
    const facialHairMap = ['none', 'stubble', 'mediumBeard'];
    const hairMap = [
      'none',
      'long',
      'bun',
      'short',
      'pixie',
      'balding',
      'buzz',
      'afro',
      'bob',
    ];
    const hairColorMap = [
      'blonde',
      'orange',
      'black',
      'white',
      'brown',
      'blue',
      'pink',
    ];
    const hatMap = ['none', 'beanie', 'turban'];
    const hatColorMap = ['white', 'blue', 'black', 'green', 'red'];
    const lipColorMap = ['red', 'purple', 'pink', 'turqoise', 'green'];
    const mouthsMap = [
      'grin',
      'sad',
      'openSmile',
      'lips',
      'open',
      'serious',
      'tongue',
    ];
    const skinToneMap = ['light', 'yellow', 'brown', 'dark', 'red', 'black'];

    const selectRandomValue = (array) => {
      return array[Math.floor(Math.random() * array.length)];
    };

    const [indexAccessory, setIndexAccessory] = useState(0);
    const [indexBody, setIndexBody] = useState(0);
    const [indexClothing, setIndexClothing] = useState(0);
    const [indexClothingColor, setIndexClothingColor] = useState(0);
    const [indexEyebrows, setIndexEyebrows] = useState(0);
    const [indexEyes, setIndexEyes] = useState(0);
    const [indexFacialHair, setIndexFacialHair] = useState(0);
    const [indexHair, setIndexHair] = useState(0);
    const [indexHairColor, setIndexHairColor] = useState(0);
    const [indexHat, setIndexHat] = useState(0);
    const [indexHatColor, setIndexHatColor] = useState(0);
    const [indexLipColor, setIndexLipColor] = useState(0);
    const [indexMouth, setIndexMouth] = useState(0);
    const [indexSkinTone, setIndexSkinTone] = useState(0);

    const features = store.profile.avatar.features;

    const [accessory, setAccessory] = useState(
      features.accessory ? features.accessory : selectRandomValue(accessoryMap)
    );
    const [body, setBody] = useState(
      features.body ? features.body : selectRandomValue(bodyMap)
    );
    const [clothing, setClothing] = useState(
      features.clothing ? features.clothing : selectRandomValue(clothingMap)
    );
    const [clothingColor, setClothingColor] = useState(
      features.clothingColor
        ? features.clothingColor
        : selectRandomValue(clothingColorMap)
    );
    const [eyebrows, setEyebrows] = useState(
      features.eyebrows ? features.eyebrows : selectRandomValue(eyebrowsMap)
    );
    const [eyes, setEyes] = useState(
      features.eyes ? features.eyes : selectRandomValue(eyesMap)
    );
    const [facialHair, setFacialHair] = useState(
      features.facialHair
        ? features.facialHair
        : selectRandomValue(facialHairMap)
    );
    const [hair, setHair] = useState(
      features.hair ? features.hair : selectRandomValue(hairMap)
    );
    const [hairColor, setHairColor] = useState(
      features.hairColor ? features.hairColor : selectRandomValue(hairColorMap)
    );
    const [hat, setHat] = useState(
      features.hat ? features.hat : selectRandomValue(hatMap)
    );
    const [hatColor, setHatColor] = useState(
      features.hatColor ? features.hatColor : selectRandomValue(hatColorMap)
    );
    const [lipColor, setLipColor] = useState(
      features.lipColor ? features.lipColor : selectRandomValue(lipColorMap)
    );
    const [mouth, setMouth] = useState(
      features.mouth ? features.mouth : selectRandomValue(mouthsMap)
    );
    const [skinTone, setSkinTone] = useState(
      features.skinTone ? features.skinTone : selectRandomValue(skinToneMap)
    );

    const [currentAvatar, setCurentAvatar] = useState(store.profile.avatar.svg);

    const [loadingBar, setLoadingBar] = useState(false);

    const handleClickAccessory = () => {
      setCurentAvatar(null);
      setIndexAccessory((indexAccessory + 1) % accessoryMap.length);
      setAccessory(accessoryMap[indexAccessory]);
    };

    const handleClickBody = () => {
      setCurentAvatar(null);
      setIndexBody((indexBody + 1) % bodyMap.length);
      setBody(bodyMap[indexBody]);
    };

    const handleClickClothing = () => {
      setCurentAvatar(null);
      setIndexClothing((indexClothing + 1) % clothingMap.length);
      setClothing(clothingMap[indexClothing]);
    };

    const handleClickClothingColor = () => {
      setCurentAvatar(null);
      setIndexClothingColor((indexClothingColor + 1) % clothingColorMap.length);
      setClothingColor(clothingColorMap[indexClothingColor]);
    };

    const handleClickEyebrows = () => {
      setCurentAvatar(null);
      setIndexEyebrows((indexEyebrows + 1) % eyebrowsMap.length);
      setEyebrows(eyebrowsMap[indexEyebrows]);
    };

    const handleClickEyes = () => {
      setCurentAvatar(null);
      setIndexEyes((indexEyes + 1) % eyesMap.length);
      setEyes(eyesMap[indexEyes]);
    };

    const handleClickFacialHair = () => {
      setCurentAvatar(null);
      setIndexFacialHair((indexFacialHair + 1) % facialHairMap.length);
      setFacialHair(facialHairMap[indexFacialHair]);
    };

    const handleClickHair = () => {
      setCurentAvatar(null);
      setIndexHair((indexHair + 1) % hairMap.length);
      setHair(hairMap[indexHair]);
    };

    const handleClickHairColor = () => {
      setCurentAvatar(null);
      setIndexHairColor((indexHairColor + 1) % hairColorMap.length);
      setHairColor(hairColorMap[indexHairColor]);
    };

    const handleClickHat = () => {
      setCurentAvatar(null);
      setIndexHat((indexHat + 1) % hatMap.length);
      setHat(hatMap[indexHat]);
    };

    const handleClickHatColor = () => {
      setCurentAvatar(null);
      setIndexHatColor((indexHatColor + 1) % hatColorMap.length);
      setHatColor(hatColorMap[indexHatColor]);
    };

    const handleClickLipColor = () => {
      setCurentAvatar(null);
      setIndexLipColor((indexLipColor + 1) % lipColorMap.length);
      setLipColor(lipColorMap[indexLipColor]);
    };

    const handleClickMouth = () => {
      setCurentAvatar(null);
      setIndexMouth((indexMouth + 1) % mouthsMap.length);
      setMouth(mouthsMap[indexMouth]);
    };

    const handleClickSkinTone = () => {
      setCurentAvatar(null);
      setIndexSkinTone((indexSkinTone + 1) % skinToneMap.length);
      setSkinTone(skinToneMap[indexSkinTone]);
    };

    const randomAvatar = () => {
      setCurentAvatar(null);
      setAccessory(selectRandomValue(accessoryMap));
      setBody(selectRandomValue(bodyMap));
      setClothing(selectRandomValue(clothingMap));
      setClothingColor(selectRandomValue(clothingColorMap));
      setEyebrows(selectRandomValue(eyebrowsMap));
      setEyes(selectRandomValue(eyesMap));
      setFacialHair(selectRandomValue(facialHairMap));
      setHair(selectRandomValue(hairMap));
      setHairColor(selectRandomValue(hairColorMap));
      setHat(selectRandomValue(hatMap));
      setHatColor(selectRandomValue(hatColorMap));
      setLipColor(selectRandomValue(lipColorMap));
      setMouth(selectRandomValue(mouthsMap));
      setSkinTone(selectRandomValue(skinToneMap));
    };

    // Save avatar
    const save = async () => {
      const svg = svgRef.current;
      if (svg) {
        setLoadingBar(true);
        const svgData = new XMLSerializer().serializeToString(svg);
        try {
          const features = {
            accessory: accessory,
            body: body,
            clothing: clothing,
            clothingColor: clothingColor,
            eyebrows: eyebrows,
            eyes: eyes,
            facialHair: facialHair,
            hair: hair,
            hairColor: hairColor,
            hat: hat,
            hatColor: hatColor,
            lipColor: lipColor,
            mouth: mouth,
            skinTone: skinTone,
          };
          await store.api
            .post('/user/avatar', {
              svg: svgData,
              features,
            })
            .then(async () => {
              store.profile.avatar.svg = svgData;
              store.profile.avatar.features = features;
              setLoadingBar(false);
              toast.success('Avatar saved');
            });
        } catch (err) {
          console.log(err);
          setLoadingBar(false);
          toast.error('Error');
        }
      } else {
        toast('Play with the buttons first', {
          icon: '🕹️',
        });
      }
    };
    // modal instructions
    const [openModal, setOpenModal] = useState(false);
    const onCloseModal = () => setOpenModal(false);

    useEffect(() => {
      if (!Object.values(store.profile?.avatar?.features).includes(null)) {
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    }, [store.profile?.avatar?.features]);

    return (
      <>
        <Helmet>
          <title>{`My avatar - Straico`}</title>
        </Helmet>

        <Subheader
          title="My avatar"
          description="Design your avatar, relax and let your imagination flow"
          icon="UserCircleIcon"
          loading={loadingBar}
        />

        <MainBody className="px-4 py-4 md:px-28 md:py-4 lg:py-4">
          {/* Avatar image */}
          <div className="flex items-center justify-center mt-2">
            <ModalProfileSteps open={openModal} onClose={onCloseModal} />
            <div className="w-52 lg:w-72 self-center relative">
              {currentAvatar ? (
                <div dangerouslySetInnerHTML={{ __html: currentAvatar }} />
              ) : (
                <Avatar
                  ref={svgRef}
                  accessory={accessory}
                  body={body}
                  circleColor="blue"
                  clothing={clothing}
                  clothingColor={clothingColor}
                  eyebrows={eyebrows}
                  eyes={eyes}
                  faceMask={false}
                  facialHair={facialHair}
                  graphic="none"
                  hair={hair}
                  hairColor={hairColor}
                  hat={hat}
                  hatColor={hatColor}
                  lashes={false}
                  lipColor={lipColor}
                  mask={true}
                  mouth={mouth}
                  skinTone={skinTone}
                />
              )}

              <div
                onClick={randomAvatar}
                className="absolute lg:bottom-2 lg:right-12 bottom-0 right-8 h-14 w-14 rounded-full items-center justify-center flex cursor-pointer bg-snow-100 text-graphite-400 dark:bg-graphite-600 dark:text-snow-200 dark:hover:bg-graphite-700 shadow-2xl hover:bg-vanilla-100 border border-snow-900"
              >
                <span className="material-symbols-outlined w-12 h-12 text-4xl text-center mt-2">
                  shuffle
                </span>
              </div>
            </div>
          </div>

          {/* Control section */}
          <div className="relative w-[22rem] m-auto mt-4">
            {/* <div className="absolute inset-0 bg-teal-400 shadow-lg transform -rotate-2 rounded-md"></div> */}

            <div className="align-bottom bg-snow-100 dark:bg-graphite-600 rounded-md text-left shadow-lg transform hover:shadow-2xl border border-snow-900">
              <div className="px-4 pt-6 pb-4">
                <div className="flex items-center justify-center">
                  <div className="">
                    <Section text="Body" color="crayola-400"></Section>
                    <Divider></Divider>
                    <div className="flex flex-row justify-center">
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100 dark:text-snow-100"
                        onClick={handleClickBody}
                        icon="man"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100 dark:text-snow-100"
                        onClick={handleClickSkinTone}
                        icon="palette"
                      />
                    </div>

                    <Section
                      text="Accessories"
                      color="crayola-400"
                      margin="mt-8"
                    ></Section>
                    <Divider></Divider>
                    <div className="flex flex-row justify-center">
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickAccessory}
                        icon="eyeglasses"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickClothing}
                        icon="checkroom"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickClothingColor}
                        icon="opacity"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickHat}
                        icon="school"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickHatColor}
                        icon="colorize"
                      />
                    </div>

                    <Section
                      text="Face"
                      color="crayola-400"
                      margin="mt-8"
                    ></Section>
                    <Divider></Divider>
                    <div className="flex flex-row justify-center">
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickMouth}
                        icon="mood"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickEyes}
                        icon="visibility"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickEyebrows}
                        icon="preview"
                      />
                    </div>
                    <div className="flex flex-row justify-center">
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickFacialHair}
                        icon="self_care"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickHair}
                        icon="face"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickHairColor}
                        icon="face_retouching_natural"
                      />
                      <RoundButton
                        color="vanilla-100 dark:text-snow-100"
                        onClick={handleClickLipColor}
                        icon="border_color"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center p-2">
            <Button title={'Save'} onClick={() => save()} />
          </div>
        </MainBody>
      </>
    );
  })
);

// TODO: Change icons of material icons to match the ones in the design
const RoundButton = ({ icon, onClick, color }) => {
  return (
    <div
      onClick={onClick}
      className={`w-1/8 rounded-full hover:bg-${color} dark:hover:bg-graphite-400 text-graphite-200 mx-1 cursor-pointer`}
    >
      <span className="material-symbols-outlined w-12 h-12 text-3xl text-center items-center justify-center flex">
        {icon}
      </span>
    </div>
  );
};

const Divider = () => {
  return (
    <div className="flex flex-row justify-center">
      <div className={`divide-y divide-snow-900 pt-1 pb-2 w-80`}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

const Section = ({ text, color, margin }) => {
  return (
    <div
      className={`font-lexend uppercase tracking-wide text-sm text-${color} font-semibold leading-none ${margin}`}
    >
      {text}
    </div>
  );
};

export default MyAvatar;
