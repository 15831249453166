import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LightBulbIcon,
  PlusIcon,
  PencilIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';
import Button from './Button';
import { observer, inject } from 'mobx-react';
import Loader from './Loader';
import Toast from './Toast';

const CreatePrompt = inject('store')(
  observer(({ store, onInteraction }) => {
    const navigate = useNavigate();

    // ----- Form
    const [result, setResult] = useState(false);
    const [resultMessage, setResultMessage] = useState(false);
    const [resultSuccess, setResultSuccess] = useState(false);
    const [resultError, setResultError] = useState(false);

    const [loader, setLoader] = useState(false);
    const [urlCreation, setUrlCreation] = useState('');
    const [loaderMessage, setLoaderMessage] = useState(false);
    const [exampleImage, setExampleImage] = useState(false);

    const [fPurpose, setFPurpose] = useState(undefined);
    const [fTemperature, setFTemperature] = useState('');
    const [sModel, setSModel] = useState(undefined);
    const [inputHintList, setInputHintList] = useState([
      { index: 0, value: '' },
      { index: 1, value: '' },
    ]);

    const optionsModel = [
      { value: 'text-davinci-003', label: 'Davinci' },
      { value: 'text-curie-001', label: 'Curie' },
      { value: 'text-babbage-001', label: 'Babbage' },
      { value: 'text-ada-001', label: 'Ada' },
    ];

    const addMoreHintInput = () => {
      let length = inputHintList.length;
      setInputHintList((inputHintList) => [
        ...inputHintList,
        { index: length, value: '' },
      ]);
    };

    const onInputHintList = (index, value) => {
      let items = [...inputHintList];
      let item = { ...inputHintList[index] };
      item.value = value;
      items[index] = item;
      setInputHintList(items);
    };

    const createPrompt = async () => {
      setLoader(true);
      setLoaderMessage('AI is doing its best to create the best prompt ever');

      let inputs = [];
      inputHintList.map((item) => {
        if (item.value) inputs.push(item.value);
      });

      try {
        await store.api
          .post('/ai/builder', {
            suggestion: fPurpose,
            inputs: inputs,
            coins: 10,
            // model: sModel.value,
            // temperature: fTemperature
          })
          .then(({ data }) => {
            if (!data.success) {
              if (data.error == 'no_coins') {
                publishMessage(data.message, 'red-500', 'white');
              } else if (data.error == 'missing_coins') {
                publishMessage(data.message, 'yellow-400', 'yellow-900');
              }
              setLoader(false);
              return false;
            }
            setLoader(false);
            setResult(true);
            setResultMessage(data.output.message);
            setUrlCreation(data.output.url);
            store.setNewTool(data.output.tool);
            setResultSuccess(true);
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        setLoader(false);
        setResult(true);
        setResultMessage(err);
      }
    };

    const viewMyCreation = () => {
      navigate(urlCreation);
      onInteraction('close');
    };

    // ----- Tabs
    const [currentTabOption, setCurrentTabOption] = useState('My creation');

    const onTabExample = async () => {
      setCurrentTabOption('Example');
      setResult(false);
      setResultSuccess(false);
      setLoaderMessage('AI is doing its best to create the best prompt ever');

      setInputHintList([
        { index: 0, value: 'Ingredients' },
        { index: 1, value: 'Time' },
      ]);
      setSModel({ value: 'text-curie-001', label: 'Curie' });
      setFTemperature(0.4);

      let label =
        'Give me recipes ideas based on my ingredients and time to cook.';
      let field = '';
      for (let a = 0; a < label.length; a++) {
        await sleep(30);
        field = field + label[a];
        setFPurpose(field);
      }

      await sleep(2000);

      setLoader(true);
      await sleep(5000);
      setLoader(false);

      setExampleImage(true);
      await sleep(10000);
      onTabMyCreation();
    };

    const onTabMyCreation = () => {
      setExampleImage(false);
      setCurrentTabOption('My creation');

      setFPurpose(undefined);
      setInputHintList([
        { index: 0, value: '' },
        { index: 1, value: '' },
      ]);
      setSModel(undefined);
      setFTemperature('');
    };

    const optionsTab = [
      {
        title: 'My creation',
        Icon: PencilIcon,
        color: 'green',
        onClick: onTabMyCreation,
      },
      {
        title: 'Example',
        color: 'yellow',
        Icon: EyeIcon,
        onClick: onTabExample,
      },
    ];

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    // Toast manager
    const [random, setRandom] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastColorBox, setToastColorBox] = useState(null);
    const [toastColorText, setToastColorText] = useState(null);

    const publishMessage = (message, colorBox, colorText) => {
      setRandom(Math.floor(Math.random() * 50000));
      setToastMessage(message);
      setToastColorBox(colorBox);
      setToastColorText(colorText);
    };

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* Toast */}
          <Toast
            message={toastMessage}
            colorBox={toastColorBox}
            colorText={toastColorText}
            time={5000}
            random={random}
          />

          <div className="relative bg-white rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col">
            <div className="px-6 overflow-auto relative">
              <div className="sticky top-0 bg-white">
                <div className="flex items-center pt-6">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                    <LightBulbIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                      Create your own tool
                      {/* <span className="text-sm text-gray-500 bg-yellow-300 px-2 rounded-md	">Beta-2*</span> */}
                    </div>
                    <p className="text-sm text-gray-500">
                      Unlock the power of Straico to easily generate customized
                      tools for all your writing needs.
                    </p>
                  </div>
                </div>

                <br></br>
                {/* <p className="text-xs text-gray-500 text-right	">
                                * This feature is in Beta mode, help us to use it and we'll appreciate your feedback
                            </p> */}

                <div className="container mx-auto pt-4 select-none">
                  <div className="align-bottom bg-white text-left transform transition-all sm:align-middle transition grid grid-cols-2">
                    {optionsTab.map((option, index) => (
                      <Option
                        key={index}
                        currentOption={currentTabOption}
                        {...option}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="dialog-content-container overflow-auto pr-2">
                <div className="mt-4">
                  <label className="text-gray-600 font-medium text-md">
                    Purpose*
                  </label>
                </div>

                <TextareaAutosize
                  minRows={2}
                  maxRows={10}
                  name={fPurpose}
                  id={fPurpose}
                  value={fPurpose || ''}
                  placeholder="What is the purpose of your prompt scheme?"
                  className={`outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4 min-w-full border border-gray-300 font-regular focus:border-gray-400 border-gray-300font-regular mt-2`}
                  onChange={(e) => setFPurpose(e.target.value)}
                />

                <div className="mt-3">
                  <label className="text-gray-600 font-medium text-md">
                    Input fields
                  </label>
                  <div
                    className="inline float-right"
                    onClick={() => {
                      addMoreHintInput();
                    }}
                  >
                    <PlusIcon className="w-6 h-6 text-gray-500 rounded-full hover:bg-gray-300" />
                  </div>
                  <p className="text-sm text-gray-500">
                    What are the key elements of your prompt scheme?
                  </p>
                </div>

                {inputHintList.map((item, index) => (
                  <div key={index} className="flex flex-col flex-1 mt-2">
                    <input
                      value={item.value}
                      key={item.index}
                      onChange={(e) => onInputHintList(index, e.target.value)}
                      type="text"
                      className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto"
                      placeholder={`Hint ${index + 1}`}
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                ))}

                <div className="pb-6"></div>

                {/* <div className="mt-4">
                                <label className="text-gray-600 font-medium text-md">
                                    Model*
                                </label>
                                <p className="text-sm text-gray-500">
                                    Select the AI model for your prompt scheme
                                </p>
                                <ul className="list-disc text-xs text-gray-400 mx-6">
                                    <li>Davinci - Complex intent, cause and effect, summarization for audience</li>
                                    <li>Curie - Language translation, summarization, text sentiment</li>
                                    <li>Babbage - Moderate classification, semantic search classification</li>
                                    <li>Ada - Parsing text, simple classification, address correction, keywords</li>
                                </ul>
                            </div>

                            <Fragment>
                                <Select
                                    className="basic-single mt-2"
                                    classNamePrefix="select"
                                    value={sModel}
                                    name="model"
                                    onChange={value => setSModel(value)}
                                    options={optionsModel}
                                />
                            </Fragment>

                            <div className="mt-4">
                                <label className="text-gray-600 font-medium text-md">
                                    Temperature*
                                </label>
                                <p className="text-sm text-gray-500">
                                    Set the temperature level (0-1) for your prompt scheme
                                </p>
                                <ul className="list-disc text-xs text-gray-400 mx-6">
                                    <li>The temperature controls how much randomness is in the output</li>
                                    <li>Value to 0 will choose words with a higher probability of occurrence</li>
                                    <li>Value to 1 will deliver sometimes interesting results</li>
                                </ul>
                            </div>

                            <div className="flex flex-col flex-1 mt-2 pb-6">
                                <input value={fTemperature} onChange={e => setFTemperature(e.target.value)} type="number" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="From 0.0 to 1.0" min="0.0" max="1.0" step="0.1" />
                            </div> */}

                {loader ? (
                  <div className="flex flex-col items-center">
                    <Loader active className="w-10 h-10" />
                    <p className="text-sm text-gray-500 mt-2">
                      {loaderMessage}
                    </p>
                  </div>
                ) : (
                  <></>
                )}

                {exampleImage ? (
                  <div className="flex flex-col items-center">
                    <img className="" src="/pr-example-create-prompt.png" />
                  </div>
                ) : (
                  <></>
                )}

                {result ? (
                  <div className="flex flex-col items-center pb-4">
                    <p className="text-sm text-gray-500 mt-2 px-7 text-center">
                      {resultMessage}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="flex justify-between w-full px-6 pb-6 bottom-0">
              <Button title={'Close'} onClick={() => onInteraction('close')} />
              {resultSuccess ? (
                <Button title={'View'} onClick={() => viewMyCreation()} />
              ) : (
                <Button
                  title={'Create'}
                  onClick={() => createPrompt()}
                  disabled={
                    fPurpose == undefined ||
                    fPurpose == '' ||
                    inputHintList[0].value == ''
                  }
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  })
);

export function Option({ title, Icon, onClick, currentOption, color }) {
  let active = currentOption === title;
  return (
    <div
      className={`lg:py-4 lg:px-8 py-3 px-6 flex transition text-${
        active ? `${color ? color : 'green'}-800` : 'gray-500'
      } font-medium border-b -mb-px rounded-t-lg border-${
        active ? `${color ? color : 'green'}-500` : 'gray-400'
      } bg-${active ? `${color ? color : 'green'}-100` : 'white'} hover:bg-${
        active ? `${color ? color : 'green'}-200` : 'gray-100'
      } cursor-pointer `}
      onClick={() => onClick(title)}
    >
      <div
        className={`md:mr-4  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
          active ? `${color ? color : 'green'}` : 'gray'
        }-300 text-${active ? `${color ? color : 'green'}` : 'gray'}-600`}
      >
        <Icon
          className={`h-4 w-4 text-${
            active ? `${color ? color : 'green'}` : 'gray'
          }-600`}
          aria-hidden="true"
        />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default CreatePrompt;
