function Button({
  title,
  onClick,
  Icon,
  disabled,
  noMarginTop,
  children,
  color,
  type,
  CustomIcon,
  fontColor,
  fontSize,
  customRound,
  customPaddig,
}) {
  return (
    <button
      type={type}
      className={
        disabled
          ? `select-none ${
              customPaddig ? customPaddig : 'py-2 px-6'
            }  bg-graphite-100 hover:bg-graphite-200 disabled hover:to-gray-700 ${
              fontColor ? fontColor : 'text-snow-100'
            }  ${
              customRound ? customRound : 'rounded-md'
            } flex md:inline-flex ${
              fontSize ? fontSize : 'text-lg'
            } cursor-pointer ${
              !noMarginTop && 'mt-4'
            } md:mx-0 transition uppercase font-lexend font-bold`
          : `select-none ${customPaddig ? customPaddig : 'py-2 px-6'} ${
              !color ? 'bg-[#E57A44]' : color
            } hover:bg-celadon-700 ${
              fontColor ? fontColor : 'text-snow-100'
            }  ${
              customRound ? customRound : 'rounded-md'
            } flex md:inline-flex ${
              fontSize ? fontSize : 'text-lg'
            } cursor-pointer ${
              !noMarginTop && 'mt-4'
            } md:mx-0 transition uppercase font-lexend font-bold flex items-center justify-center gap-2`
      }
      onClick={disabled ? null : onClick}
    >
      {Icon && (
        <Icon
          className={
            disabled
              ? `h-6 w-6 mr-1 text-snow-100`
              : 'h-6 w-6 mr-1 text-snow-200'
          }
        />
      )}
      {CustomIcon && CustomIcon}
      {title}
      {children}
    </button>
  );
}

export default Button;
