import React, { useState } from 'react';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';
import Button from './Button';
import { observer, inject } from 'mobx-react';

const QualifyExperience = inject('store')(
  observer(({ store, modal, onInteraction }) => {
    const [success, setSuccess] = useState(false);

    const [fStars, setFStars] = useState(undefined);
    const [fReturn, setFReturn] = useState(undefined);
    const [fImprove, setFImprove] = useState(undefined);

    const sendCommentBasic = async () => {
      try {
        let data = await store.api
          .post('/user/grade', {
            stars: fStars,
            improve: fImprove,
            modal: modal,
          })
          .then(async ({ data }) => {
            setSuccess(true);
            await sleep(2000);
            onInteraction('close');
          });
      } catch (err) {
        console.log(err);
      }
    };

    const sendCommentMedium = async () => {
      try {
        let data = await store.api
          .post('/user/grade', {
            stars: fStars,
            improve: fImprove,
            modal: modal,
            return: fReturn,
          })
          .then(async ({ data }) => {
            setSuccess(true);
            await sleep(2000);
            onInteraction('close');
          });
      } catch (err) {
        console.log(err);
      }
    };

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* Modal - Review Basic */}
          {modal == 'review_basic' ? (
            <div className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full">
              <div className="px-6 pt-6 h-min">
                <div className="flex items-center">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                    <ClipboardIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                      Give us your feedback
                    </div>
                    <p className="text-sm text-gray-500">
                      Straico team appreciate your comments.
                    </p>
                  </div>
                </div>

                <div className="dialog-content-container-grade overflow-auto pr-2">
                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      How would you rate Straico so far?*
                    </label>
                  </div>

                  <div className="flex items-center w-full mt-2 mb-6">
                    <StarRating sendStars={setFStars} />
                  </div>

                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      We would greatly appreciate your feedback
                    </label>
                  </div>

                  <TextareaAutosize
                    minRows={4}
                    maxRows={10}
                    name={fImprove}
                    id={fImprove}
                    value={fImprove || ''}
                    placeholder="If you have any advice for us or have encountered any bugs, please feel free to share"
                    className={`outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4 min-w-full border border-gray-300 font-regular focus:border-gray-400 border-gray-300font-regular mt-2`}
                    onChange={(e) => setFImprove(e.target.value)}
                  />
                </div>
              </div>

              {success ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  Thank you for your feedback
                </div>
              ) : null}

              <div className="flex justify-between w-full px-6 pb-6 bottom-0">
                <Button
                  color="bg-graphite-100"
                  title={'Close'}
                  onClick={() => onInteraction('close')}
                />
                <Button
                  title={'Send'}
                  disabled={fStars == undefined || success}
                  onClick={() => sendCommentBasic()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Modal - Review Medium */}
          {modal == 'review_medium' ? (
            <div className="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full">
              <div className="px-6 pt-6 h-min">
                <div className="flex items-center">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                    <ClipboardIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                      Your feedback matters
                    </div>
                    <p className="text-sm text-gray-500">
                      We would love to hear about your experience using our
                      product.
                    </p>
                  </div>
                </div>

                <div className="dialog-content-container-grade overflow-auto pr-2">
                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      Could you kindly give us your rating?*
                    </label>
                  </div>

                  <div className="flex items-center w-full mt-2 mb-6">
                    <StarRating sendStars={setFStars} />
                  </div>

                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      Would you consider returning?*
                    </label>
                  </div>

                  <div
                    className="mt-2"
                    onChange={(e) => setFReturn(e.target.value)}
                  >
                    <input
                      className="ml-2"
                      type="radio"
                      value="true"
                      name="option"
                    />{' '}
                    Yes
                    <input
                      className="ml-6"
                      type="radio"
                      value="false"
                      name="option"
                    />{' '}
                    No
                  </div>

                  {fReturn == 'false' ? (
                    <div>
                      <div className="mt-5">
                        <label className="text-gray-600 font-medium text-md">
                          What would encourage you to return?*
                        </label>
                      </div>

                      <TextareaAutosize
                        minRows={2}
                        maxRows={10}
                        name={fImprove}
                        id={fImprove}
                        value={fImprove || ''}
                        placeholder="Give us your comment"
                        className={`outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4 min-w-full border border-gray-300 font-regular focus:border-gray-400 border-gray-300font-regular mt-2`}
                        onChange={(e) => setFImprove(e.target.value)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {success ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  That's fantastic, you're welcome back anytime
                </div>
              ) : null}

              <div className="flex justify-between w-full px-6 pb-6 bottom-0">
                <Button
                  color="bg-graphite-100"
                  title={'Close'}
                  onClick={() => onInteraction('close')}
                />
                <Button
                  title={'Send'}
                  disabled={
                    fStars == undefined ||
                    fReturn == undefined ||
                    success ||
                    (fReturn == 'false' &&
                      (fImprove == '' || fImprove == undefined))
                  }
                  onClick={() => sendCommentMedium()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  })
);

const StarRating = (props) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <div className="m-auto">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= ((rating && hover) || hover)
                ? 'text-yellow-400'
                : 'text-gray-300'
            }
            onClick={() => {
              setRating(index);
              props.sendStars(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="text-5xl">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default QualifyExperience;
