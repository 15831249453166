import { useEffect } from 'react';
// Mobx
import { observer, inject } from 'mobx-react';
// MUI
import { Button } from '@mui/material';
// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useThemeStore from 'Theme/store';
// Store
import { useCreateTemplateFolder } from './store';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
import { useChat } from 'Context/Chat/Chat.context';
// RHF
import { useForm } from 'react-hook-form';
// Toast
import { toast } from 'react-hot-toast';

const CreateTemplateFolder = ({ store }) => {
  const { open, setOpen } = useCreateTemplateFolder();
  const { setSelectedFolder, selectedFolder } = useTreeDNDTemplatesStore();
  const { templatesFolders, setTemplatesFolders } = useChat();

  const { handleSubmit, register, setValue, reset } = useForm();

  const { theme } = useThemeStore();

  const handleClose = () => {
    setOpen(false);
    setSelectedFolder(null);
    reset({
      name: '',
    });
  };

  useEffect(() => {
    if (selectedFolder) {
      setValue('name', selectedFolder.name);
    }
  }, [selectedFolder]);

  const editFolder = async (data) => {
    try {
      const response = await store.api.put(
        `/template/folders/${selectedFolder._id}`,
        data
      );
      const newFolder = response.data;
      setTemplatesFolders(
        templatesFolders.map((f) =>
          f._id === selectedFolder._id ? { ...f, ...newFolder } : f
        )
      );
      toast.success('Folder updated successfully');
    } catch (err) {
      toast.error(err?.data?.message);
    } finally {
      handleClose();
    }
  };

  const createFolder = async (data) => {
    try {
      const response = await store.api.post('/template/folders', {
        name: data.name,
        parentFolder: null,
      });
      const newFolder = response.data;
      setTemplatesFolders([newFolder, ...templatesFolders]);
      toast.success('Folder created successfully');
    } catch (error) {
      toast.error('Error creating folder, try again later');
    } finally {
      handleClose();
    }
  };

  const onSubmit = (data) => {
    if (data.name === '') {
      toast('Please enter a folder name', {
        icon: '🚨',
      });
      return;
    }
    if (selectedFolder) {
      editFolder(data);
    } else {
      createFolder(data);
    }
  };

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={open}
      handleClose={handleClose}
    >
      <p
        className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
          theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
        }`}
      >
        {`${selectedFolder ? 'Edit' : 'Create'} template folder   `}
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium font-lexend ${
              theme == 'dark' ? 'text-white' : 'text-graphite-900'
            }`}
          >
            Folder name *
          </label>
          <input
            name="name"
            className={`w-full border ${
              theme == 'dark'
                ? 'bg-graphite-900 text-white'
                : 'bg-white text-graphite-900'
            }  border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md`}
            placeholder="Name of your folder"
            {...register('name')}
          />
        </div>
      </div>
      <div className="w-full flex justify-between mt-6 gap-4">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleSubmit(onSubmit)}
        >
          <span className="font-lexend font-bold">
            {selectedFolder ? 'Edit' : 'Create'}
          </span>
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClose}
        >
          <span className="font-lexend font-bold">Cancel</span>
        </Button>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(CreateTemplateFolder));
