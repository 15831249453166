import * as React from 'react';

const ToolSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#422040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.125 5.625a3.75 3.75 0 0 1-4.07 3.737c-.897-.076-1.887.059-2.458.753l-5.96 7.237a2.124 2.124 0 1 1-2.989-2.989l7.237-5.96c.694-.571.83-1.561.753-2.458a3.75 3.75 0 0 1 5.28-3.738l-2.73 2.73a2.503 2.503 0 0 0 1.875 1.875l2.73-2.73c.214.47.332.993.332 1.543Z"
    />
    <path
      stroke="#422040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.056 15.938h.007v.006h-.007v-.007Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M14.5 13v5m2.5-2.5h-5"
    />
    <path
      stroke="#422040"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M14.5 13v5m2.5-2.5h-5"
    />
  </svg>
);
export default ToolSvg;
