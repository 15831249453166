import { ClipboardIcon } from '@heroicons/react/24/solid';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import toast from 'react-hot-toast';

function ShareInput({ shareLink }) {
  const { copy } = useClipboardComponent();
  //
  const handleCopy = () => {
    try {
      copy(shareLink);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <>
      {/* clipboard input component */}
      <h1 className="text-eggplant-900 dark:text-flax-900 uppercase font-barlow font-bold">
        Share this link:
      </h1>
      <div className="flex rounded ">
        <div className="w-full flex justify-between items-center gap-2">
          <div className="flex-grow truncate w-40">
            <input
              className="border border-snow-900 border-solid p-[8px] font-barlow rounded bg-transparent w-full text-graphite-900 dark:text-snow-100"
              value={shareLink}
              readOnly
            />
          </div>
          <button
            className="flex items-center justify-center w-8 h-8 rounded-full bg-crayola-600 hover:bg-crayola-700"
            onClick={handleCopy}
          >
            <ClipboardIcon className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
    </>
  );
}

export default ShareInput;
