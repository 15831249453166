import { Button } from '@mui/material';
// Icons
import {
  ArrowPathIcon,
  StopIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';

const ActionsChat = ({
  loading,
  onRegenerate,
  onStop,
  chat,
  continueResponse,
}) => {
  return (
    <div className="absolute -top-10 w-52 md:w-80">
      {(chat?.messages?.length ?? 0) > 0 && (
        <div className="flex justify-start md:pl-28 gap-4 items-center w-full">
          {!loading && (
            <>
              <Button
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                  minWidth: '190px',
                  flexGrow: 2,
                }}
                onClick={onRegenerate}
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<ArrowPathIcon className="w-5 h-5" />}
              >
                Regenerate response
              </Button>
              <Button
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'normal',
                  minWidth: '100px',
                  flexGrow: 1,
                }}
                onClick={continueResponse}
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<ArrowRightCircleIcon className="w-5 h-5" />}
              >
                Continue
              </Button>
            </>
          )}
          {loading && (
            <Button
              sx={{ textTransform: 'capitalize', fontWeight: 'normal' }}
              onClick={onStop}
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<StopIcon className="w-5 h-5 " />}
            >
              Stop generating
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsChat;
