import { Dialog } from '@mui/material';
import useThemeStore from 'Theme/store';

const CommonModal = ({ open, handleClose, children, ...props }) => {
  const { theme } = useThemeStore();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      {...props}
      PaperProps={{
        sx: {
          backgroundColor: theme == 'dark' ? '#141114' : '#F6F7F5',
          backgroundImage: 'none',
          // border: '1px solid #797779',
          borderRadius: '4px',
          minWidth: '400px',
          minHeight: '300px',
          ...props.sx,
        },
      }}
    >
      <hr className="border-snow-900 border-solid bg-vanilla-900 h-1" />
      <div className="flex justify-center flex-col px-6 py-4 dark:text-white">
        {children}
      </div>
    </Dialog>
  );
};

export default CommonModal;
