// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
// MUI
import { Button } from '@mui/material';
// Store
import useTemplateModalStore from '../../store';
// Constants
import { MESSAGE_BODY, MESSAGE_TITLE } from './constants';

const ErrorModal = ({ handleConfirm, sendDirect = false }) => {
  // Store
  const { errorModal, setErrorModal, errorModalReason, setErrorModalReason } =
    useTemplateModalStore();
  // Modal
  const handleClose = () => {
    setErrorModal(false);
    setErrorModalReason({
      emptyFields: false,
      emptyAttachments: false,
    });
  };

  const localHandleConfirm = () => {
    console.log('sendDirect', sendDirect);
    handleConfirm(sendDirect);
    setErrorModal(false);
    setErrorModalReason({
      emptyFields: false,
      emptyAttachments: false,
    });
  };

  const { emptyFields, emptyAttachments } = errorModalReason;

  return (
    <CommonModal open={errorModal} handleClose={handleClose}>
      <div className="h-full flex flex-col justify-center items-center gap-8">
        <div className="uppercase text-eggplant-700 dark:text-flax-700 text-lg font-bold mb-0 mt-8 font-lexend">
          {`${
            emptyFields && emptyAttachments
              ? MESSAGE_TITLE.both
              : emptyFields
              ? MESSAGE_TITLE.emptyFields
              : MESSAGE_TITLE.emptyAttachments
          }`}
        </div>
        <p className="font-barlow text-graphite-900 dark:text-snow-100">
          {emptyFields && emptyAttachments
            ? MESSAGE_BODY.both
            : emptyFields
            ? MESSAGE_BODY.emptyFields
            : MESSAGE_BODY.emptyAttachments}
        </p>
        <div className="flex w-full gap-4 left-0 items-center justify-center">
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleClose}
            fullWidth
          >
            <span className="font-lexend font-bold">Complete</span>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={localHandleConfirm}
            fullWidth
          >
            <span className="font-lexend font-bold">Continue</span>
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ErrorModal;
