import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'; // Assuming icons from heroicons
import TemplateFav from './TemplateFav';
import useThemeStore from 'Theme/store';

const ScrollableToolsSection = ({ title, tools, isSmallMobile, subTitle }) => {
  const scrollContainer = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScrollButtons = () => {
    const container = scrollContainer.current;
    if (!container) return;
    const isScrollable = container.scrollWidth > container.clientWidth;
    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(
      container.scrollLeft < container.scrollWidth - container.clientWidth &&
        isScrollable
    );
  };

  const scrollLeft = () => {
    scrollContainer.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainer.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = scrollContainer.current;
    container.addEventListener('scroll', checkScrollButtons);
    return () => container.removeEventListener('scroll', checkScrollButtons);
  }, []);

  useEffect(() => {
    checkScrollButtons(); // Run on component mount and whenever the `tools` array length changes
  }, [tools]);

  return (
    <>
      <h2 className="px-6 font-barlow text-xl font-semibold leading-9 text-graphite-500 dark:text-snow-100">
        {title}
      </h2>
      <p className="px-6 font-barlow text-base text-graphite-100 dark:text-snow-500 pb-2">
        {subTitle}
      </p>
      <div className="flex items-center justify-between">
        {showLeftArrow && (
          <button
            onClick={scrollLeft}
            className="z-20 flex justify-center items-center w-6 h-6 bg-white dark:bg-snow-100 hidden sm:flex"
            aria-label="Scroll Left"
          >
            <ChevronLeftIcon className="w-6 h-6 text-graphite-600" />
          </button>
        )}

        <div
          className="flex overflow-x-scroll scrollbar-hide w-full"
          ref={scrollContainer}
        >
          <section className="flex pl-2 pr-2 space-x-4">
            {tools.length > 0 ? (
              tools.map((tool, index) => (
                <TemplateFav
                  key={index}
                  tool={tool}
                  isSmallMobile={isSmallMobile}
                />
              ))
            ) : (
              <TemplateFavSkeleton />
            )}
          </section>
        </div>

        {showRightArrow && (
          <button
            onClick={scrollRight}
            className="z-20 flex justify-center items-center w-6 h-6 bg-white dark:bg-snow-100 hidden sm:flex"
            aria-label="Scroll Right"
          >
            <ChevronRightIcon className="w-6 h-6 text-graphite-600" />
          </button>
        )}
        <style>
          {`
          .scrollbar-hide::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
          }
        `}
        </style>
      </div>
    </>
  );
};

export default ScrollableToolsSection;

const TemplateFavSkeleton = () => {
  return (
    <div
      className={`
        animate-pulse /* Adds a pulsing effect */
        ${useThemeStore().theme === 'dark' ? 'bg-graphite-900' : 'bg-white'}
        rounded-lg px-4 py-5 min-w-[308px] border border-snow-900 dark:border-snow-100 m-4
      `}
    >
      <div className="flex items-center space-x-2">
        {/* Ensure dimensions and rounded-full are correctly applied for a circle */}
        {/* <div className="rounded-full bg-slate-300 h-6 w-6"></div> */}
        <div className="h-4 bg-snow-600 rounded w-3/4"></div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        {/* This is the circle div; ensure it has a square aspect ratio for a perfect circle shape */}
        <div className="w-8 h-8">
          <div className="rounded-full bg-snow-600 w-8 h-8"></div>
        </div>
        <div className="h-4 bg-snow-600 rounded w-full"></div>
      </div>
      <div className="mt-2">
        <div className="flex mt-2 space-x-2 align-middle items-center">
          <div className="flex space-x-1 items-center">
            {/* <div className="rounded-full bg-slate-300 h-4 w-4"></div> */}
            <div className="h-4 bg-snow-600 rounded w-32"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
