import { Link, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import {
  XCircleIcon,
  CheckIcon,
  DocumentTextIcon,
  ViewColumnsIcon,
  UserCircleIcon,
  ArrowUturnLeftIcon,
  ChevronLeftIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import MainBody from '../../Components/Body';
import { Helmet } from 'react-helmet';
import { observer, inject } from 'mobx-react';
import Header from '../../Components/Header';
import useLoadingLayoutMyProfile from './store';
import useThemeStore from 'Theme/store';
import ModalConfirmClose from 'Features/CreateTemplatesForm/Components/ModalConfirmClose';
import { useRef, useState } from 'react';

const LayoutMyProfile = ({ store }) => {
  const { loading } = useLoadingLayoutMyProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const fromColor = () => {
    if (
      store.profile.status === 'active' ||
      store.profile.status === 'trialing'
    ) {
      if (store.profile.cancel_at_period_end) {
        return 'yellow-400';
      }
      return 'green-400';
    }
    return 'red-300';
  };

  const onBack = () => {
    navigate(`/my-profile`);
  };

  return (
    <>
      <Header
        title={store.profile.email}
        desc={`${store.profile.fname} ${store.profile.lname}`}
        category={store.profile.plan_description}
        tag={
          store.profile.status != 'trialing' ? store.profile.status : 'trial'
        }
        fromColorCategory={store.profile.plan_color}
        fromColorTag={fromColor()}
        Icon={UserCircleIcon}
        ppublic={true}
        profile={true}
        avatar={store.profile.avatar.svg}
        options={
          location.pathname !== '/my-profile'
            ? [
                {
                  title: 'Back to Profile',
                  Icon: ChevronLeftIcon,
                  onClick: onBack,
                },
              ]
            : null
        }
      >
        {location.pathname === '/my-profile' && (
          <Helmet>
            <title>{`My Profile - Straico`}</title>
          </Helmet>
        )}
      </Header>

      {loading ? (
        <div className="w-full -mt-[10px]">
          <progress className="progress progress-accent -mt-8"></progress>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex h-[14px]"></div>
        </div>
      )}

      <MainBody className="px-4 py-2 md:px-28">
        <p className="text-right text-sm -mt-5 text-graphite-400 dark:text-snow-100 mr-4 font-barlow">
          Need assistance? Reach out to us at{' '}
          <span className="text-crayola-700">hello@straico.com</span>
        </p>
        {outlet}
      </MainBody>
    </>
  );
};

export const MyProfilePage = inject('store')(
  observer(({ store }) => {
    return (
      <>
        <HeaderSection
          categorie="Profile"
          textColor="eggplant-700"
          borderColor="snow-900"
        />
        <Grid>
          <Tool
            Icon={Cog8ToothIcon}
            title={'Settings'}
            desc={'Get control of your profile and personalize it'}
            to={'/user-settings'}
            fromColor="crayola-700"
            toColor="gray-400"
          />
          <Tool
            Icon={UserCircleIcon}
            title={'My Avatar'}
            desc={'Design your own avatar and have fun with it'}
            to={'/my-avatar'}
            fromColor="crayola-700"
            toColor="gray-400"
          />
        </Grid>
        <HeaderSection
          categorie="Membership"
          textColor="eggplant-700"
          borderColor="snow-900"
        />
        <Grid>
          <Tool
            Icon={ViewColumnsIcon}
            title={'Subscriptions'}
            api={store.api}
            desc={'Choose or update your plan and billing mode'}
            to={'/my-profile/pricing'}
            fromColor="celadon-700"
          />

          {store.profile.scope === 'general' ? (
            <ToolForm
              Icon={DocumentTextIcon}
              title={'Stripe Account'}
              api={store.api}
              desc={
                'Update card details, check invoices, or cancel the plan anytime'
              }
              to={store.baseURL + 'user/stripe/customer-portal'}
              fromColor={'celadon-700'}
              toColor={'celadon-700'}
            />
          ) : null}

          {store.profile.plan != 'free' &&
          (store.profile.status === 'active' ||
            store.profile.status === 'trialing') &&
          store.profile.scope === 'general' ? (
            <ToolForm
              Icon={XCircleIcon}
              title={'Cancel Plan'}
              api={store.api}
              desc={'Immediate cancellation of your subscription and payments'}
              to={store.baseURL + 'user/stripe/cancel'}
              fromColor={'celadon-700'}
              toColor={'celadon-700'}
              showConfirm={store.profile.status === 'trialing'}
            />
          ) : null}

          {store.profile.status === 'canceled' &&
          store.profile.scope === 'general' ? (
            <ToolForm
              Icon={CheckIcon}
              title={'Reactivate Plan'}
              api={store.api}
              desc={'Immediate activation of your subscription and payments'}
              to={store.baseURL + 'user/stripe/uncancel'}
              fromColor={'celadon-700'}
              toColor={'celadon-700'}
            />
          ) : null}
        </Grid>
        <HeaderSection
          categorie="Platform"
          textColor="eggplant-700"
          borderColor="snow-900"
        />
        <Grid>
          <ToolDiv
            Icon={ArrowUturnLeftIcon}
            title={'Log Out'}
            desc={'Sign out of your account'}
            onClick={store.handleLogout}
            fromColor="vanilla-700"
            toColor="vanilla-700"
          />
        </Grid>
      </>
    );
  })
);

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

const ToolDiv = ({ Icon, title, desc, group, fromColor, onClick }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <>
      <div className="flex relative " onClick={onClick}>
        <div
          className={`flex-1 ${
            themeValue == 'dark' ? 'bg-graphite-900' : 'bg-white'
          } rounded-md transition hover:shadow-md overflow-hidden md:max-w-1lg cursor-pointer  md:flex relative transform hover:scale-105  mr-4`}
        >
          {Icon && (
            <div
              className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${
                fromColor ? fromColor : 'green-500'
              }`}
            >
              <Icon className="h-16 w-16 mb-4 mt-4" />
            </div>
          )}
          <div className="p-4">
            <div
              className={`uppercase tracking-wide text-sm text-${
                fromColor ? fromColor : 'green-500'
              } font-semibold leading-none`}
            >
              {group || ''}
            </div>
            <div
              className={`block text-lg xl:text-xl 2xl:text-2xl leading-tight font-base text-graphite-400 dark:text-snow-100 font-lexend`}
            >
              {title}
            </div>
            <p className="mt-1 pr-1 text-base font-barlow text-graphite-400 dark:text-snow-100">
              {desc}{' '}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const ToolForm = ({
  Icon,
  title,
  desc,
  to,
  group,
  fromColor,
  api,
  showConfirm = false,
}) => {
  const { theme: themeValue } = useThemeStore();
  const [cancelTrialOpen, setCancelTrialOpen] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    setCancelTrialOpen(false);
    submitForm();
  };

  const handleSubmit = () => {
    if (showConfirm) {
      setCancelTrialOpen(true);
    } else {
      submitForm();
    }
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <>
      <form ref={formRef} action={to} method="POST" className="flex relative">
        <input
          type="hidden"
          name="token"
          value={api.defaults.headers.common['x-access-token']}
        />
        <button
          type="button"
          onClick={handleSubmit}
          className="flex-1 text-left"
        >
          <div
            className={`flex-1 ${
              themeValue == 'dark' ? 'bg-graphite-900' : 'bg-white'
            } rounded-md transition hover:shadow-md overflow-hidden md:max-w-1lg cursor-pointer md:flex relative transform hover:scale-105  mr-4`}
          >
            {Icon && (
              <div
                className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${
                  fromColor ? fromColor : 'green-500'
                }`}
              >
                <Icon className="h-16 w-16 mb-4 mt-4" />
              </div>
            )}
            <div className="p-4">
              <div
                className={`uppercase tracking-wide text-sm text-${
                  fromColor ? fromColor : 'green-500'
                } font-semibold leading-none`}
              >
                {group || ''}
              </div>
              <div
                className={`block text-lg xl:text-xl 2xl:text-2xl leading-tight font-base text-graphite-400 dark:text-snow-100 font-lexend`}
              >
                {title}
              </div>
              <p className="mt-1 pr-1 text-base font-barlow text-graphite-400 dark:text-snow-100">
                {desc}{' '}
              </p>
            </div>
          </div>
        </button>
      </form>
      <ModalConfirmClose
        open={cancelTrialOpen}
        onCancel={() => setCancelTrialOpen(false)}
        onClose={handleClose}
        title="Are you sure?"
        description="Be aware that by cancelling your trial, you will forfeit any unused coins and will automatically return to the free tier service."
        confirmButtonText="Accept"
        cancelButtonText="Cancel"
      />
    </>
  );
};

const Tool = ({ Icon, title, desc, to, group, fromColor }) => {
  const { theme: themeValue } = useThemeStore();
  return (
    <Link to={to} className="flex relative">
      <div className="flex-1 text-left">
        <div
          className={`flex-1 ${
            themeValue == 'dark' ? 'bg-graphite-900' : 'bg-white'
          } rounded-md transition hover:shadow-md overflow-hidden md:max-w-1lg cursor-pointer md:flex relative transform hover:scale-105  mr-4`}
        >
          {Icon && (
            <div
              className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${
                fromColor ? fromColor : 'green-500'
              }`}
            >
              <Icon className="h-16 w-16 mb-4 mt-4" />
            </div>
          )}
          <div className="p-4">
            <div
              className={`uppercase tracking-wide text-sm text-${
                fromColor ? fromColor : 'green-500'
              } font-semibold leading-none`}
            >
              {group || ''}
            </div>
            <div
              className={`block text-lg xl:text-xl 2xl:text-2xl leading-tight font-base text-graphite-400 dark:text-snow-100 font-lexend`}
            >
              {title}
            </div>
            <p
              className={`mt-1 pr-1 text-base font-barlow ${
                themeValue == 'dark'
                  ? 'dark:text-snow-100'
                  : 'text-graphite-400'
              }  `}
            >
              {desc}{' '}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const HeaderSection = ({ categorie, textColor, borderColor }) => (
  <div
    className={`flex justify-start items-center font-inter uppercase tracking-wide font-lexend text-base text-${textColor} font-semibold leading-none mt-2 border-b border-r border-${borderColor} dark:border-graphite-400 pb-2 mt-8 mb-5 mr-4 dark:text-celadon-900`}
  >
    {categorie}
  </div>
);

export default inject('store')(observer(LayoutMyProfile));
