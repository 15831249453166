import { useState } from 'react';
import {
  QuestionMarkCircleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

import HelpModal from '../../../Components/HelpModal';
import DiscordModal from 'Components/DiscordModal';
import usePublicLayoutStore from '../State';
import ToggleTheme from 'Components/Common/ToggleTheme';
import useThemeStore from 'Theme/store';

const SidebarComponent = ({ ...props }) => {
  const [openDialogHelp, setOpenDialogHelp] = useState(false);
  const [openDialogDiscord, setOpenDialogDiscord] = useState(false);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialogHelp(false);
      setOpenDialogDiscord(false);
    }
  };

  const { openRegisterModal } = usePublicLayoutStore();
  const { theme } = useThemeStore();

  return (
    <>
      <div className="bg-snow-100 dark:bg-graphite-900 sticky top-0 z-40 border-b border-graphite-100 h-12">
        <div className="flex mx-auto px-1 md:px-1 select-none p-1">
          <div className="items-center hidden md:block">
            <img
              src={theme == 'dark' ? '/straico-snow-100.svg' : '/straico.svg'}
              className={`cursor-pointer h-7 ml-3 mt-1`}
            />
          </div>
          <div className="flex items-center md:hidden">
            <img
              src="/logo-symbol-crayola-600.svg"
              className={`cursor-pointer w-8 lg:w-24 ml-3.5 mt-1`}
            />
          </div>

          <div className="flex mr-2 justify-end absolute right-0 h-10">
            <div className="flex mr-2">
              <ToggleTheme />
            </div>
            <div className="flex mr-2">
              <button
                onClick={() => {
                  setOpenDialogDiscord(true);
                }}
                className="flex items-center justify-center px-1 rounded-md hover:bg-vanilla-100 text-graphite-100 text-sm border border-vanilla-200 dark:border-graphite-900 dark:hover:bg-graphite-600"
              >
                <img
                  className="h-4 px-1 block"
                  src={
                    theme == 'dark'
                      ? '/discord-mark-white.svg'
                      : '/discord-mark-blue.svg'
                  }
                />
              </button>
            </div>

            <div className="flex mr-2">
              <a
                href="https://straico.com"
                target="_blank"
                rel="noreferrer"
                className="flex "
              >
                <button className="flex items-center justify-center px-1 rounded-md hover:bg-vanilla-100 text-graphite-100 text-sm border border-vanilla-200 dark:border-graphite-900 dark:hover:bg-graphite-600">
                  <QuestionMarkCircleIcon className="w-5 h-5 mx-1 text-graphite-400 dark:text-snow-100" />
                </button>
              </a>
            </div>
            <div
              onClick={openRegisterModal}
              className={`flex px-2 cursor-pointer bg-snow-800 hover:bg-vanilla-100 text-graphite-200 rounded-md transition items-center dark:border-graphite-900 dark:hover:bg-graphite-600 dark:bg-graphite-800`}
            >
              <UserCircleIcon className="w-7 h-7 transition text-graphite-400 dark:text-snow-100" />
            </div>
          </div>
        </div>
      </div>

      {openDialogHelp && <HelpModal onInteraction={onInteraction}></HelpModal>}

      {openDialogDiscord && (
        <DiscordModal onInteraction={onInteraction}></DiscordModal>
      )}
      {props.children}
    </>
  );
};

export default SidebarComponent;
