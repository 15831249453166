import { useOutlet } from 'react-router-dom';
// Components
import Header from './Components/Header';

const PublicLayout = () => {
  const outlet = useOutlet();
  return (
    <div>
      <Header />
      {outlet}
    </div>
  );
};

export default PublicLayout;
