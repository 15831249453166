/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownTrayIcon,
  MusicalNoteIcon,
} from '@heroicons/react/24/outline';
import Button from './Button';

import {
  SparklesIcon,
  StarIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import { CurrencyDollarIcon, XMarkIcon } from '@heroicons/react/24/solid';

import toast from 'react-hot-toast';
import { toastReward } from 'utils/toastCall';
import ReactAudioPlayer from 'react-audio-player';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useThemeStore from 'Theme/store';

const TextSpeechDialog = inject('store')(
  observer(({ store, onClose, script }) => {
    const concatenateTexts = (data) => {
      let concatenatedText = '';
      for (const item of data) {
        if (item.type === 'text') {
          concatenatedText += item.text + ' ';
        }
      }
      return concatenatedText.trim();
    };

    const [text, setText] = useState(
      typeof script === 'string' ? script : concatenateTexts(script)
    );
    const [voice, setVoice] = useState('');
    const [quality, setQuality] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [showAudio, setShowAudio] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const handleChangeVoice = (event) => {
      setVoice(event.target.value);
      setShowAudio(false);
    };

    const handleChangeQuality = (event) => {
      setQuality(event.target.value);
      setShowAudio(false);
    };

    const handleChangeText = (event) => {
      setText(event.target.value);
      setShowAudio(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
      setVoice('alloy');
      setQuality('tts-1');
    }, []);

    const [chunkProgress, setChunkProgress] = useState(0);
    const [isConcatenating, setIsConcatenating] = useState(false);

    const WORD_LIMIT = 670;

    const splitTextIntoChunks = (text, maxLength) => {
      const chunks = [];
      while (text.length > 0) {
        if (text.length <= maxLength) {
          chunks.push(text);
          break;
        }
        let chunk = text.slice(0, maxLength);
        const lastPeriodIndex = chunk.lastIndexOf('.');
        const lastSpaceIndex = chunk.lastIndexOf(' ');

        if (lastPeriodIndex > 0 && lastPeriodIndex < maxLength - 1) {
          chunk = text.slice(0, lastPeriodIndex + 1);
        } else if (lastSpaceIndex > 0 && lastSpaceIndex < maxLength) {
          chunk = text.slice(0, lastSpaceIndex);
        }

        chunks.push(chunk);
        text = text.slice(chunk.length).trim();
      }
      return chunks;
    };

    const fetchAudioForChunk = async (chunk, voice, quality) => {
      try {
        const response = await store.api.post(`/file/tts`, {
          text: chunk,
          voice: voice,
          quality: quality,
          coins: calculateCoins(chunk.split(' ').length),
        });

        if (response.data.success) {
          return response.data.url;
        } else {
          if (response.data.type == 'coins') {
            toastReward({
              title: 'Insufficient Coins 😭',
              message: 'Recharge your account for more coins',
              linkText: "It's quick and easy. Click here!",
              linkUrl: '/my-profile/pricing',
              image: '/growing.gif',
              time: 10000,
            });
          } else if (response.data.type == 'words') {
            toast('Maximum 670 words per audio generated', {
              icon: '⚠️',
            });
          }
          throw new Error(
            response.data.message || 'Error converting text to audio'
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    };

    const concatenateAudios = async (audioUrls) => {
      try {
        const response = await store.api.post(`file/concatenate-audio`, {
          audioUrls: audioUrls,
        });

        if (response.data.success) {
          return response.data.url;
        } else {
          throw new Error(
            response.data.message || 'Error concatenating audios'
          );
        }
      } catch (error) {
        console.error('Error concatenating audios:', error);
        throw error;
      }
    };

    const generateTextToAudio = async () => {
      setShowAudio(false);
      setShowLoading(true);
      setChunkProgress(0);
      setIsConcatenating(false);

      try {
        toast(
          'Please hold on, converting your text to speech may take a moment.',
          {
            icon: '⌛️',
          }
        );

        const chunks = splitTextIntoChunks(text, WORD_LIMIT);
        const totalChunks = chunks.length;
        const audioUrls = [];

        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i];
          const url = await fetchAudioForChunk(chunk, voice, quality);
          audioUrls.push(url);

          setChunkProgress(((i + 1) / totalChunks) * 100);
        }

        setIsConcatenating(true);

        const finalAudioUrl = await concatenateAudios(audioUrls);

        setShowAudio(true);
        setAudioUrl(finalAudioUrl);
        setShowLoading(false);
        setIsConcatenating(false);
      } catch (error) {
        console.error(error);
        setShowLoading(false);
        setShowAudio(false);
      }
    };

    const getInputWords = (text) => {
      let count = text.trim().split(/\s+/).filter(Boolean).length;
      return count;
    };

    const calculateCoins = (words) => {
      let base;
      if (quality == 'tts-1') {
        base = 4 / 100; // 4 coins for 100 words
      } else {
        base = 8 / 100;
      }
      return (words * base).toFixed(2);
    };

    const downloadAudio = (audioUrl) => {
      const anchor = document.createElement('a');
      anchor.href = audioUrl;
      const fileName = audioUrl.split('/').pop();
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    const { theme: themeValue } = useThemeStore();

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity" onClick={onClose}>
            <div className="absolute inset-0 bg-graphite-400 opacity-75"></div>
          </div>

          <div
            className={`relative ${
              themeValue == 'dark' ? 'dark:bg-graphite-900' : 'bg-white'
            } rounded-md overflow-y-scroll shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full`}
          >
            <div
              className={`px-8 pt-8 h-min ${
                store.profile.plan == 'freeTos' && 'opacity-20 blur-[1px]'
              }`}
            >
              {/* Title bar */}
              <div className="flex items-center">
                <div className="flex-shrink-0 inline-flex items-center justify-center h-6 w-6 rounded-md sm:mx-0 bg-vanilla-400">
                  <SpeakerWaveIcon
                    className="h-4 w-4 text-eggplant-700"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 text-eggplant-700 dark:text-flax-700 font-lexend text-lg font-bold uppercase">
                  Text to Speech
                </div>
              </div>
              <div className="mt-1 mb-1 text-sm italic text-graphite-400 dark:text-snow-100">
                Quickly proofread, pick a voice, and generate speech. It's that
                simple. Play or download your audio in seconds!
              </div>

              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute right-1 top-2 p-1 hover:bg-vanilla-100 text-graphite-100 text-sm rounded-md"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>

              {/* Form data */}
              <div className="mx-auto flex flex-col justify-between">
                <div className="mt-2 mb-1 text-eggplant-700 dark:text-graphite-100 font-lexend text-xs font-medium uppercase">
                  Text
                </div>

                <textarea
                  value={text}
                  onChange={handleChangeText}
                  name="description"
                  className={`w-full p-[8px] font-barlow rounded-md ${
                    themeValue == 'dark'
                      ? 'bg-graphite-900 text-snow-100'
                      : 'bg-white text-graphite-900'
                  } border border-snow-900 border-solid`}
                  style={{ resize: 'none' }}
                  placeholder="A description for your prompt"
                  rows={4}
                />

                <div className="mt-4 mb-1 text-eggplant-700 dark:text-graphite-100 font-lexend text-xs font-medium uppercase">
                  Voice
                </div>

                <Select
                  value={voice}
                  onChange={handleChangeVoice}
                  sx={{ fontFamily: 'Barlow' }}
                >
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'alloy'}>
                    Alloy - Young adult - Male
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'echo'}>
                    Echo - Young adult - Male
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'fable'}>
                    Fable - Young adult - Male
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'nova'}>
                    Nova - Young adult - Female
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'onyx'}>
                    Onyx - Senior - Male
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'shimmer'}>
                    Shimmer - Young adult - Female
                  </MenuItem>
                </Select>

                <div className="mt-4 mb-1 text-eggplant-700 dark:text-graphite-100 font-lexend text-xs font-medium uppercase">
                  Quality
                </div>

                <Select
                  value={quality}
                  onChange={handleChangeQuality}
                  sx={{ fontFamily: 'Barlow' }}
                >
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'tts-1'}>
                    Text to Speech - Standard{' '}
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'Barlow' }} value={'tts-1-hd'}>
                    Text to Speech - High Definition{' '}
                  </MenuItem>
                </Select>
              </div>

              {/* Audio block */}
              {showAudio ? (
                <>
                  <div className="mt-4 mb-1 text-eggplant-700 dark:text-graphite-100 font-lexend text-xs font-medium uppercase">
                    Audio
                  </div>
                  <div className="flex justify-center">
                    <ReactAudioPlayer src={audioUrl} controls />
                  </div>
                  <div
                    className={`flex justify-center w-full px-6 pb-6 bottom-0 mt-4`}
                  >
                    <Button
                      Icon={ArrowDownTrayIcon}
                      title={'Download'}
                      noMarginTop
                      onClick={() => downloadAudio(audioUrl)}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* Loading Bar */}
              {showLoading ? (
                <>
                  <div className="flex justify-center">
                    <div className="mt-4 mb-4 text-graphite-400 dark:text-snow-100 font-barlow text-sm font-normal">
                      Generating voice... please wait{' '}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <img src="/straico-loading.gif" className="h-10"></img>
                  </div>
                  <div className="flex justify-center">
                    <progress
                      value={chunkProgress}
                      max="100"
                      style={{
                        width: '100%',
                        marginTop: '0.5rem',
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        height: '1rem',
                        borderRadius: '0.375rem',
                        overflow: 'hidden',
                      }}
                      className="progress-bar"
                    ></progress>
                  </div>
                  {isConcatenating && (
                    <div className="flex justify-center">
                      <div className="mt-4 mb-4 text-graphite-400 dark:text-snow-100 font-barlow text-sm font-normal">
                        Concatenating audio chunks... please wait{' '}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className="mb-8"></div>
            </div>

            {/* Generator button */}
            <div
              className={`flex justify-center w-full px-6 pb-6 bottom-0 ${
                store.profile.plan == 'freeTos' && 'opacity-30 blur-[1px]'
              }`}
            >
              <Button
                Icon={MusicalNoteIcon}
                title={'Generate'}
                disabled={!text || showLoading}
                noMarginTop
                onClick={() => generateTextToAudio()}
              />
            </div>

            {/* Coins calculator */}
            <div className="flex gap-1 font-lexend text-xs items-center justify-end mb-2 mr-8">
              <div className="flex flex-row items-center px-1 bg-eggplant-500 dark:bg-graphite-300 rounded text-white font-semibold">
                <CurrencyDollarIcon className="w-5 h-5" />
                <span className="ml-1">
                  {calculateCoins(getInputWords(text))}
                </span>
              </div>
              <div className="text-graphite-400 dark:text-snow-100 font-light">
                · <span>{getInputWords(text).toLocaleString('en-US')}</span>{' '}
                words
              </div>
            </div>

            {/* Free plan disclaimer */}
            {store.profile.plan == 'freeTos' && (
              <div className=" absolute  justify-center top-0 h-full m-auto flex flex-col items-center  px-20 ">
                <SparklesIcon className="h-6 flex justify-center w-full mb-5" />
                <div className="text-center not-italic text-eggplant-700 dark:text-flax-800 font-lexend text-sm font-bold uppercase pb-3">
                  {store.profile.trial_end
                    ? 'Looking for premium benefits?'
                    : 'Ready for a power-up?'}
                </div>
                {store.profile.trial_end ? (
                  <div className="text-center not-italic text-graphite-800 dark:text-snow-100 font-barlow text-sm  font-medium pb-5">
                    <a
                      href="/my-profile/pricing"
                      className="text-crayola-600 font-barlow text-sm font-semibold underline"
                    >
                      Upgrade
                    </a>{' '}
                    to a paid subscription to text to speech and keep unlocking
                    your AI superpowers.
                  </div>
                ) : (
                  <div className="text-center not-italic text-graphite-800 dark:text-snow-100 font-barlow text-sm  font-medium pb-5">
                    Gain immediate access to text to speech and others with our{' '}
                    <a
                      href="/my-profile/pricing"
                      className="text-crayola-600 font-barlow text-sm font-semibold underline"
                    >
                      {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial.
                    </a>{' '}
                    for just $1.
                  </div>
                )}
                <div className="flex justify-center w-full">
                  <button
                    type="button"
                    className="bg-[#E57A44] shadow-md rounded py-1 px-3 text-snow-100 font-bold text-center text-sm font-lexend uppercase flex"
                    onClick={() => {
                      navigate('/my-profile/pricing');
                    }}
                  >
                    <StarIcon className="w-5 h-5  mr-2" />{' '}
                    {store.profile.trial_end
                      ? 'Upgrade now'
                      : `try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  })
);

export default TextSpeechDialog;
