/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import useChatStore from 'Components/Chat/store';

import { inject, observer } from 'mobx-react';
import { TranslateContext } from '../../Context/translate-context';
import config from '../../config';
// Forms
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';
import ReCAPTCHA from 'react-google-recaptcha';

const baseURL = config.baseURL;

const LoginLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Start - Straico</title>
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <header className="h-auto bg-eggplant-700  font-medium font-inter text-2xl py-3">
          <NavLink to="/login">
            <img className="h-7 pl-4 -mt-1" src="/logo-snow-100.svg" />
          </NavLink>
        </header>

        <div className="lg:grid lg:grid-cols-3 h-screen bg-snow-300">
          <div className="bg-graphite-900 flex flex-col justify-center items-center lg:col-span-2 h-44 md:h-96 lg:h-[calc(120vh-3em)]">
            <LazyLoadImage
              className="object-cover w-full h-full"
              height="100%"
              width="100%"
              src="/straico-wall-16.png"
              placeholderSrc="/straico-wall-16-blur.png"
              effect="blur"
            />
          </div>
          <div className="flex items-center justify-center bg-snow-300">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export const LoginSection = inject('store')(
  observer(({ store }) => {
    const [error, setError] = useState(null);
    const { translator } = useContext(TranslateContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [captchaValue, setCaptchaValue] = useState('');

    const onChange = (val, attr) => {
      if (attr === 'email') setEmail(val);
      if (attr === 'password') setPassword(val);
      setError('');
    };

    const handleCaptchaChange = (value) => {
      setCaptchaValue(value);
    };

    const onLogin = async (e) => {
      try {
        if (!captchaValue) {
          toast.error(
            "Please verify that you're human by completing the captcha"
          );
          return;
        }
        e.preventDefault();
        const response = await fetch(baseURL + 'auth/signin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        const data = await response.json();
        // Handle error
        if ([401, 400, 404].includes(response.status)) {
          if (data?.message) {
            toast.error(data?.message);
            return;
          }
        }

        store.loginWithDataTokenAndProfile(data);
        if (sessionStorage.getItem('redirectUrl')) {
          navigate(sessionStorage.getItem('redirectUrl'));
        } else {
          navigate('/chat');
        }
        // Navigate to chat page if user is verified
        // if (data?.profile?.verified) {
        //   store.loginWithDataTokenAndProfile(data);
        //   if (sessionStorage.getItem('redirectUrl')) {
        //     navigate(sessionStorage.getItem('redirectUrl'));
        //   } else {
        //     navigate('/chat');
        //   }
        // } else {
        //   toast(
        //     'Your account needs to be activated. Kindly check your email inbox and confirm.',
        //     {
        //       icon: '⚠️',
        //       duration: 10000,
        //     }
        //   );
        // }
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const signUpGoogle = async (event) => {
      if (store.fpr) {
        window.open(baseURL + 'auth/google/callback?fpr=' + store.fpr, '_self');
      } else {
        window.open(baseURL + 'auth/google/callback', '_self');
      }
    };

    const { cleanTreeData } = useTreeDNDChatStore();
    const { cleanChatList, cleanSelectedChat } = useChatStore();

    const handleSubmit = (e) => {
      e.preventDefault();
      cleanTreeData();
      cleanChatList();
      cleanSelectedChat();
      onLogin(e);
    };

    return (
      <LoginLayout>
        <form onSubmit={handleSubmit}>
          <div className="bg-snow-300 px-4 w-80 pt-4 lg:pt-0">
            <div className="font-inter text-3xl font-bold mb-4 text-center font-lexend uppercase text-eggplant-700">
              {translator('sectionLogIn.labelTitle')}
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionLogIn.fieldEmail')}
              </label>
              <input
                value={email}
                onChange={(e) => onChange(e.target.value, 'email')}
                type="email"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionLogIn.placeholderEmail')}
              />
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-4 text-left font-light tracking-wider">
                {translator('sectionLogIn.fieldPassword')}
              </label>
              <input
                value={password}
                onChange={(e) => onChange(e.target.value, 'password')}
                type="password"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionLogIn.placeholderPassword')}
              />
            </div>
            <div className="text-center mb-4">
              <NavLink to="/forgot">
                <p className="text-flax-900 text-sm font-barlow">
                  Forgot your password?
                </p>
              </NavLink>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={handleCaptchaChange}
              style={{
                transform: 'scale(0.77)',
                WebkitTransform: 'scale(0.77)',
                transformOrigin: 'center center',
                WebkitTransformOrigin: 'center center',
              }}
            />
            {error ? <div className="text-red-600">{error}</div> : null}
            <div className="flex flex-col mb-4 mt-4">
              <button
                type="submit"
                className="bg-[#E57A44] hover:bg-celadon-900 font-lexend text-snow-100 font-bold py-2 px-4 rounded-md uppercase"
              >
                {translator('sectionLogIn.buttonLogIn')}
              </button>
            </div>
            <div className="flex flex-col text-center mb-4">
              <p className="text-graphite-400 text-sm font-barlow">or</p>
              <div
                onClick={signUpGoogle}
                className="flex items-center hover:bg-snow-900 font-bold rounded-md px-4 py-2 bg-snow-700 text-graphite-400 mt-4 border border-snow-900 cursor-pointer justify-center font-lexend"
              >
                <span>
                  <img src="/logo-google-oauth.png" className="mr-2"></img>
                </span>
                <span className="uppercase text-sm">Sign in with Google</span>
              </div>
            </div>
            <div className="text-center mt-0">
              <p className="text-graphite-400 text-sm font-barlow">
                New on Straico?{' '}
                <NavLink
                  to="/signup"
                  className="text-flax-900 text-sm font-barlow"
                >
                  Sign up
                </NavLink>
              </p>
            </div>
            <div className="text-center mt-8">
              <p className="text-graphite-400 text-sm font-barlow">
                <a
                  href="https://www.straico.com/"
                  target="_blank"
                  className="text-flax-900 text-sm font-barlow"
                  rel="noreferrer"
                >
                  Learn more about us
                </a>
              </p>
            </div>
          </div>
        </form>
      </LoginLayout>
    );
  })
);

const schemaValidationSignUp = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  fname: yup
    .string()
    .required('First name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'First name should only contain letters and numbers'
    ),
  lname: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[A-Za-z0-9\s]+$/,
      'Last name should only contain letters and numbers'
    ),
});

export const SignupSection = inject('store')(
  observer(({ store }) => {
    const { translator } = useContext(TranslateContext);
    const [error, setError] = useState(null);

    const [captchaValue, setCaptchaValue] = useState('');

    const [fprValue, setFprValue] = useState(null);

    const [loadingSingUp, setLoadingSingUp] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const currentURL = location.search;
      const urlParams = new URLSearchParams(currentURL);
      // const fprValue = urlParams.get('fpr');
      // console.log(fprValue);
      setFprValue(urlParams.get('fpr'));
    }, [location.search]);

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: '',
        password: '',
        fname: '',
        lname: '',
      },
      resolver: yupResolver(schemaValidationSignUp),
    });

    const handleCaptchaChange = (value) => {
      setCaptchaValue(value);
    };

    const onSignup = async ({ email, password, fname, lname }) => {
      setLoadingSingUp(true);
      try {
        setError('');
        if (!captchaValue) {
          toast.error(
            "Please verify that you're human by completing the captcha"
          );
          setLoadingSingUp(false);
          return;
        }
        await fetch(baseURL + 'auth/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
            fname,
            lname,
            referral: store.referral,
            fpr: fprValue,
          }),
        }).then(async (response) => {
          console.log(response);
          const data = await response.json();
          console.log(baseURL, data);
          toast(data.message, {
            icon: '✉️',
            duration: 12000,
          });
          // Handle error
          if ([401, 400, 404].includes(response.status)) {
            if (data?.message) {
              toast.error(data?.message);
              setLoadingSingUp(false);
              return;
            }
          }
          // Navigate to chat page if user is verified

          store.loginWithDataTokenAndProfile(data);
          if (sessionStorage.getItem('redirectUrl')) {
            navigate(sessionStorage.getItem('redirectUrl'));
            setLoadingSingUp(false);
          } else {
            navigate('/chat');
            setLoadingSingUp(false);
          }
        });
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const signUpGoogle = async (event) => {
      if (fprValue) {
        window.open(baseURL + 'auth/google/callback?fpr=' + fprValue, '_self');
      } else {
        window.open(baseURL + 'auth/google/callback', '_self');
      }
    };

    const onSubmit = (data) => {
      store?.setFpr(fprValue);
      onSignup(data);
    };

    const ErrorMessage = ({ message }) => (
      <div className="pl-2 mt-2">
        <p className="text-red-600 text-xs italic">{message}</p>
      </div>
    );

    return (
      <LoginLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-snow-300 px-4 w-80 pt-8 lg:pt-0">
            <div className="font-inter text-3xl font-bold mb-4 text-center font-lexend uppercase text-eggplant-700">
              {translator('sectionSignUp.labelTitle')}
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldFirstName')}
              </label>
              <input
                {...register('fname')}
                type="text"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderFirstName')}
              />
              {errors.fname && <ErrorMessage message={errors.fname.message} />}
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldLastName')}
              </label>
              <input
                {...register('lname')}
                type="text"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderLastName')}
              />
              {errors.lname && <ErrorMessage message={errors.lname.message} />}
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldEmail')}
              </label>
              <input
                {...register('email')}
                type="email"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderEmail')}
              />
              {errors.email && <ErrorMessage message={errors.email.message} />}
            </div>
            <div className="mb-4">
              <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                {translator('sectionSignUp.fieldPassword')}
              </label>
              <input
                {...register('password')}
                type="password"
                className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                placeholder={translator('sectionSignUp.placeholderPassword')}
              />
              {errors.password && (
                <ErrorMessage message={errors.password.message} />
              )}
            </div>
            {error ? <div className="text-red-600">{error}</div> : null}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={handleCaptchaChange}
              style={{
                transform: 'scale(0.77)',
                WebkitTransform: 'scale(0.77)',
                transformOrigin: 'center center',
                WebkitTransformOrigin: 'center center',
              }}
            />
            <div className="flex flex-col mb-4 mt-4">
              <button
                type="submit"
                className="bg-[#E57A44] hover:bg-celadon-900 font-lexend text-snow-100 font-bold py-2 px-4 rounded-md uppercase"
              >
                {!loadingSingUp && translator('sectionSignUp.buttonSignUp')}{' '}
                {loadingSingUp && (
                  <div
                    className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                )}
              </button>
            </div>
            <div className="flex flex-col text-center mb-4">
              <p className="text-graphite-400 text-sm font-barlow">or</p>
              <div
                onClick={signUpGoogle}
                className="flex items-center hover:bg-snow-900 font-bold rounded-md px-4 py-2 bg-snow-700 text-graphite-400 mt-4 border border-snow-900 cursor-pointer justify-center font-lexend"
              >
                <span>
                  <img src="/logo-google-oauth.png" className="mr-2"></img>
                </span>
                <span className="uppercase text-sm">Sign in with Google</span>
              </div>
            </div>
            <div className="text-center mb-2">
              <p className="text-graphite-400 text-sm font-barlow">
                Already in Straico?{' '}
                <NavLink
                  to="/login"
                  className="text-flax-900 text-sm font-barlow"
                >
                  Log in
                </NavLink>
              </p>
            </div>
            <div className="text-center mt-4">
              <p className="text-graphite-400 text-sm font-barlow">
                <a
                  href="https://www.straico.com/"
                  target="_blank"
                  className="text-flax-900 text-sm font-barlow"
                  rel="noreferrer"
                >
                  Learn more about us
                </a>
              </p>
            </div>
          </div>
        </form>
      </LoginLayout>
    );
  })
);

export const ForgotSection = () => {
  const { translator } = useContext(TranslateContext);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');

  const onChange = (val, attr) => {
    if (attr === 'email') setEmail(val);
    setError('');
  };

  const onForgot = async (e) => {
    try {
      e.preventDefault();
      setError('');
      await fetch(baseURL + '/forgot/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      }).then(async (response) => {
        const data = await response.json();
        toast.success(data.message, { duration: 10000 });
      });
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };
  return (
    <LoginLayout>
      <form onSubmit={onForgot}>
        <div className="bg-snow-300 px-4 w-80 pt-8 lg:pt-0">
          <div className="mt-3 mb-6 text-center ">
            <div className="font-inter text-3xl font-bold  mb-4 text-center font-lexend uppercase text-eggplant-700">
              Reset password
            </div>
            <p className="text-sm text-graphite-400 font-barlow">
              Enter your email address below and submit. We will send you a mail
              containing a password reset link.
            </p>
          </div>
          <div className="mb-6">
            <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
              {translator('sectionSignUp.fieldEmail')}
            </label>
            <input
              value={email}
              onChange={(e) => onChange(e.target.value, 'email')}
              type="email"
              className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
              placeholder="kate@mail.com"
            />
          </div>
          <div className="flex flex-col mb-4">
            <button
              type="submit"
              className="bg-[#E57A44] hover:bg-celadon-900 font-lexend text-snow-100 font-bold py-2 px-4 rounded-md uppercase"
            >
              Send email
            </button>
          </div>
          <div className="text-center">
            <NavLink to="/login" className="text-flax-900 text-sm font-barlow">
              Back to login
            </NavLink>
          </div>
          <div className="text-center mt-8">
            <p className="text-graphite-400 text-sm font-barlow">
              <a
                href="https://www.straico.com/"
                target="_blank"
                className="text-flax-900 text-sm font-barlow"
                rel="noreferrer"
              >
                Learn more about us
              </a>
            </p>
          </div>
        </div>
      </form>
    </LoginLayout>
  );
};

const LanguageSelectorComponent = ({ changer }) => {
  return (
    <div className="container mx-auto text-right mt-2">
      <button
        className="hover:bg-green-500 bg-gray-200 font-medium rounded-md text-xs px-3 py-1 hover:text-white mt-2 inline-block"
        onClick={() => changer('en')}
      >
        EN
      </button>
      <button
        className="hover:bg-green-500 bg-gray-200 font-medium rounded-md text-xs px-3 py-1 hover:text-white mt-2 inline-block"
        onClick={() => changer('es')}
      >
        ES
      </button>
    </div>
  );
};
