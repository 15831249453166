import { TextField, InputAdornment } from '@mui/material';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import 'tailwindcss/tailwind.css';
import { useController } from 'react-hook-form';

const TextFieldControlled = ({
  name,
  control,
  label,
  textFieldProps = {},
  sx = null,
  showLockIcon = false,
  disabled = false,
  fullWidth = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <TextField
      sx={sx}
      name={name}
      disabled={disabled}
      fullWidth={fullWidth}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      label={label}
      error={!!error}
      helperText={error?.message}
      inputProps={{
        id: `${name}-input`,
      }}
      InputLabelProps={{
        id: `${name}-label`,
      }}
      FormHelperTextProps={{
        id: `${name}-helper-text`,
      }}
      InputProps={{
        endAdornment: showLockIcon && (
          <InputAdornment position="end">
            {' '}
            <LockClosedIcon className="h-5 w-5" />
          </InputAdornment>
        ),
        ...textFieldProps.InputProps,
      }}
      {...textFieldProps}
    />
  );
};

export default TextFieldControlled;
