import { cn } from 'utils/styles';

const Tab = ({ icon, text, onClick, value, currentValue, disabled }) => {
  const isActive = value === currentValue;
  const handleClick = () => {
    if (disabled) return;
    onClick && onClick(value);
  };
  return (
    <div
      className={cn(
        'flex flex-col gap-1 cursor-pointer transition-all ease-in-out items-center justify-center dark:bg-graphite-300 dark:text-snow-100 bg-snow-600 text-graphite-100 w-full h-full rounded px-2 py-2',
        {
          'bg-vanilla-400 dark:bg-vanilla-700': isActive,
          'text-graphite-800 font-semibold': isActive,
          'bg-gray-300 dark:bg-graphite-600 cursor-not-allowed': disabled,
        }
      )}
      onClick={handleClick}
    >
      {icon}
      <p className="text-center font-barlow text-sm">{text}</p>
    </div>
  );
};

export default Tab;
