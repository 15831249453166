import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Icons
import {
  ChevronDoubleRightIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
// Hooks
import useDebounce from 'Hooks/useDebounce.js';
import useWindowSize from 'Hooks/useWindowSize';
import useModal from '../../../Hooks/useModal';
// Context
import { useChat } from '../../../Context/Chat/Chat.context';
// Utils
import { getIndexV2 } from 'Constants/prompts';
import { toast } from 'react-hot-toast';
// Framer motion
import { motion } from 'framer-motion';
// Mobx
import { inject, observer } from 'mobx-react';
// Theme
import useThemeStore from 'Theme/store';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store/index.js';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store/index.js';
import { useCreateTemplateFolder } from '../CreateTemplateFolder/store';
import useTemplateModalStore from '../TemplateModal/store.js';
// Components
import Loader from 'Components/Loader.js';
import ShareTool from 'Features/Core/Components/ShareTool/ShareTool.js';
import FoldersTemplates from 'Features/FoldersTemplates';
import DeleteFolder from 'Features/FoldersTemplates/Components/DeleteFolder';
import CreateTemplateFolder from '../CreateTemplateFolder';

function reverseText(texto) {
  return texto.replace(/\$\{([^}]+)\}/g, '{{$1}}');
}

const PromptSide = ({ visible, store }) => {
  // Context
  const {
    getToolsAndFilterSidebar,
    setSelectedTool,
    toggleShowPromptModal,
    getPromptsByTool,
    setShowPromptList,
    sidebarTools,
    templatesFolders,
    showPromptSide: show,
    toggleShowPromptSide: toggleSide,
    closeShowPrompSide: closeSide,
    loadingSideTools,
    setShowMobilePrompts,
  } = useChat();

  // local tool
  const [localTool, setLocalTool] = useState(null);

  // Modals state
  const { setDeleteTemplateModal, setToDelete } = useTemplateModalStore();

  const { theme } = useThemeStore();

  const {
    isOpen: isOpenShareTool,
    toggleModal: toggleModalShareTool,
    closeModal: closeModalShareTool,
  } = useModal();
  // on search
  const [searchValue, setSearchValue] = useState('');
  const onSearch = (e) => setSearchValue(e.target.value);

  const debouncedValueText = useDebounce(searchValue, 500);

  useEffect(() => {
    getToolsAndFilterSidebar(debouncedValueText);
    setSearch(debouncedValueText ?? '');
  }, [debouncedValueText]);

  const [showSearch, setShowSearch] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 1024) {
      setShowSearch(true);
      setShowMobilePrompts(false);
    } else {
      setShowSearch(false);
      closeSide();
    }
  }, [width]);

  const navigate = useNavigate();
  const location = useLocation();

  // Build tree data
  const { setTreeData, setLoading, setSearch } = useTreeDNDTemplatesStore();
  useEffect(() => {
    if (sidebarTools.length > 0) {
      let tree = [];
      // add folders
      templatesFolders
        .sort((a, b) => b.name.localeCompare(a.name))
        .sort((a, b) => {
          const indexA = getIndexV2(a?.name);
          const indexB = getIndexV2(b?.name);
          // If index values are not available for both items, keep their order unchanged
          if (indexA === -1 && indexB === -1) {
            return -1;
          }
          // If index value is not available for one item, place it at the end
          if (indexA === -1) {
            return 1;
          }
          if (indexB === -1) {
            return -1;
          }
          // Otherwise, sort based on index value
          return indexA - indexB;
        })
        .sort((a, b) => {
          if (a.creator === null && b.creator === null) {
            return 0;
          }
          if (a.creator === null) {
            return 1;
          }
          if (b.creator === null) {
            return -1;
          }
          return a.creator.localeCompare(b.creator);
        })
        .forEach(({ name, _id, parent_folder, ...rest }) => {
          tree.push({
            id: _id,
            parent: parent_folder ?? 0,
            droppable: true,
            text: name,
            data: { name, _id, parent_folder, ...rest, type: 'folder' },
          });
        });
      // add tools
      sidebarTools.forEach((tool) => {
        tree.push({
          id: tool._id,
          parent: tool.parent_folder ?? 0,
          droppable: false,
          text: tool.title,
          data: { ...tool, type: 'template' },
        });
      });
      // Set the tree data
      setTreeData(tree);
    }
  }, [sidebarTools, templatesFolders]);

  // Update loading
  useEffect(() => {
    setLoading(loadingSideTools);
  }, [loadingSideTools]);

  // on Select prompt
  const handleSelectPrompt = (id) => {
    const selectedToolLocal = sidebarTools.filter(
      (tool) => tool?._id === id
    )[0];
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
    setShowPromptList(false);
  };
  // Methods templates
  const onClickTemplate = (tool) => {
    setShowMobilePrompts(false);
    handleSelectPrompt(tool?._id);
    // url params
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  const onEditTemplate = async (selectedTool) => {
    const optionsPrompt = await getPromptsByTool(selectedTool._id);
    // Generate data to edit the template
    const data = {
      id: selectedTool?._id,
      default_model: selectedTool?.default_model?.id,
      multi_default_models:
        selectedTool?.multi_default_models?.length > 0
          ? selectedTool?.multi_default_models.map((model) => model.id)
          : [],
      name: selectedTool?.title,
      description: selectedTool?.desc,
      location: selectedTool?.parent_folder ?? '',
      promptVariables: selectedTool?.prompts[0]
        ? selectedTool?.prompts[0]?.prompts
            ?.map((item) => {
              return {
                variable: item?.attr,
                name: item?.title,
                type: item?.type,
                options: item?.options?.map((option, index) => {
                  return {
                    id: index,
                    title: option?.label,
                    value: option?.value,
                  };
                }),
                placeholder: item?.placeholder,
                maxLength: item?.maxLength,
              };
            })
            .filter((item) => !['attachment', 'image'].includes(item?.type)) ??
          []
        : [],
      variables: selectedTool?.prompts[0]
        ? selectedTool?.prompts[0]?.prompts
            ?.map((item) => {
              return {
                variable: item?.attr,
                name: item?.title,
                type: item?.type,
                options: item?.options?.map((option, index) => {
                  return {
                    id: index,
                    title: option?.label,
                    value: option?.value,
                  };
                }),
                placeholder: item?.placeholder,
                maxLength: item?.maxLength,
              };
            })
            .filter((item) => ['attachment', 'image'].includes(item?.type))
        : [],
      prompt:
        optionsPrompt?.length > 0 ? reverseText(optionsPrompt[0]?.value) : '',
      files: selectedTool?.files ?? [],
    };
    // Function to set the data and open the modal
    setDefaultData(data);
    setEdit(true);
    setOpenModal(true);
  };

  const onDeleteTemplate = (tool) => {
    setToDelete(tool);
    setDeleteTemplateModal(true);
  };

  const onShareTemplate = (tool) => {
    toggleModalShareTool();
    setLocalTool(tool);
  };

  const onDuplicateTemplate = async (tool) => {
    if (tool._id == '65805947d92e370a3d5625c6') {
      toast.error(
        'You cannot duplicate image generation templates. Try duplicating a different template.'
      );
      return;
    }
    try {
      await store.api.post('/tool/clone/' + tool?._id, {
        customCategory: 'My Tools',
      });
      await getToolsAndFilterSidebar('');
      toast.success(
        'Template duplicated. Please check your "My Templates" section.'
      );
    } catch (error) {
      toast.error(
        'Error occurred while duplicating the template. Please try again later.'
      );
    }
  };

  const onOperationsTemplate = {
    onClickTemplate,
    onEditTemplate,
    onDeleteTemplate,
    onShareTemplate,
    onDuplicateTemplate,
  };

  // Methods Buttons
  const { setOpenModal, setEdit, setDefaultData } = useCreateTemplatesStore();
  const onCreateTemplate = () => {
    setOpenModal(true);
  };

  const toggleSearch = () => setShowSearch(!showSearch);

  const { setOpen: setOpenTemplateFolder } = useCreateTemplateFolder();
  const createTemplateFolder = () => setOpenTemplateFolder(true);

  return (
    <>
      {(show || visible) && (
        <motion.div
          initial={{ x: 400, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 400, opacity: 0 }}
          className={`z-10 lg:bg-snow-400 dark:lg:bg-graphite-800 lg:bg-none bg-gradient-to-b from-flax-500 dark:from-graphite-400 to-snow-100 dark:to-graphite-400 rounded-md lg:rounded-none  relative h-full
                    ${show || visible ? '' : ''}`}
        >
          <div className="w-full px-[16px] pt-[8px]">
            <div className="flex justify-between items-center">
              <p className="text-graphite-900 uppercase font-bold font-lexend dark:text-flax-800">
                AI Dashboard
              </p>
              <div
                className="hidden lg:flex w-[20px] z-50 mr-0 cursor-pointer h-[40px] bg-snow-900 dark:bg-graphite-100 rounded items-center justify-center"
                onClick={toggleSide}
              >
                <ChevronDoubleRightIcon
                  className={`h-6 w-6 ${
                    theme == 'dark' ? 'dark:text-flax-800' : 'text-graphite-800'
                  }`}
                />
              </div>
            </div>
            <div className="hidden justify-between items-center lg:flex mt-[12px] bg-vanilla-300 dark:bg-flax-800 px-[10px] py-[6px] rounded">
              <p className="font-lexend text-graphite-900 uppercase font-bold text-sm">
                Templates
              </p>
            </div>
            <div className="hidden lg:flex gap-2 items-center mt-[12px]">
              <div
                className="flex items-center justify-center w-[32px] h-[32px] bg-vanilla-300 hover:bg-vanilla-600 dark:bg-flax-800 hover:dark:bg-flax-600 cursor-pointer rounded-md"
                onClick={onCreateTemplate}
              >
                <PlusCircleIcon className={'h-6 w-6 text-graphite-800'} />
              </div>
              <div
                className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 dark:bg-graphite-100 hover:dark:bg-flax-100 cursor-pointer rounded-md"
                onClick={createTemplateFolder}
              >
                <FolderPlusIcon
                  className={'h-6 w-6 text-graphite-800 dark:text-flax-800'}
                />
              </div>
              <div
                onClick={toggleSearch}
                className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 dark:bg-graphite-100 hover:dark:bg-flax-100 cursor-pointer rounded-md"
              >
                <MagnifyingGlassIcon
                  className={'h-6 w-6 text-graphite-800 dark:text-flax-800'}
                />
              </div>
              {showSearch && (
                <div className="flex w-[19em]">
                  <input
                    value={searchValue}
                    onChange={onSearch}
                    type="search"
                    placeholder="Search templates"
                    className={`appearance-none ${
                      theme == 'dark'
                        ? 'dark:bg-graphite-900 dark:text-white dark:focus:bg-graphite-900 dark:focus:text-white'
                        : 'bg-white dark:text-graphite-900 focus:bg-white focus:text-eggplant-200'
                    }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-snow-900 font-barlow placeholder-graphite-100`}
                    autoComplete="off"
                  />
                </div>
              )}
            </div>
            <div
              className="hidden md:block h-[2px] bg-graphite-100 my-2"
              content=" "
            />
          </div>
          <div className="flex px-4 pt-2 gap-2 w-full">
            <div
              className="md:hidden flex items-center justify-center w-[32px] h-[32px] bg-flax-300 hover:bg-flax-600 cursor-pointer rounded-md"
              onClick={onCreateTemplate}
            >
              <PlusCircleIcon className={'h-6 w-6 text-graphite-800'} />
            </div>
            <div
              className="md:hidden flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md"
              onClick={createTemplateFolder}
            >
              <FolderPlusIcon className={'h-6 w-6 text-graphite-800'} />
            </div>
            <input
              value={searchValue}
              onChange={onSearch}
              type="search"
              placeholder="Search templates"
              className={`md:hidden appearance-none ${
                theme == 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white dark:focus:bg-graphite-900 dark:focus:text-white'
                  : 'bg-white dark:text-graphite-900 focus:bg-white focus:text-eggplant-200'
              }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-snow-900 font-barlow placeholder-graphite-100`}
              autoComplete="off"
            />
          </div>
          <div className="lg:pl-5 w-full grid col-span-12 overflow-y-auto overflow-auto  supports-[height:100cqh]:h-[calc(100cqh-20em)] supports-[height:100svh]:h-[calc(100cqh-20em)] lg:h-[calc(100vh-16em)]">
            {loadingSideTools ? (
              <div className="flex items-center justify-center mt-2">
                <Loader />
              </div>
            ) : (
              <FoldersTemplates
                onOperationsTemplate={onOperationsTemplate}
                enableDnd
              />
            )}
          </div>
          <ShareTool
            isOpen={isOpenShareTool}
            closeModal={closeModalShareTool}
            tool={localTool}
          />
          <DeleteFolder />
          <CreateTemplateFolder />
        </motion.div>
      )}
    </>
  );
};

export default inject('store')(observer(PromptSide));
