import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import config from 'config';
// State
import usePublicLayoutStore from '../State';
// Components
import Modal from 'Components/Common/Modal/Modal';
import useThemeStore from 'Theme/store';

const baseURL = config.baseURL;

const ModalRegister = ({
  open,
  onClose,
  setRedirectUrl,
  url,
  isLoggedIn,
  toolText,
}) => {
  const { continueConversation } = usePublicLayoutStore();
  const [fpr, setFpr] = React.useState(null);
  const location = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const fpr = searchParams.get('fpr');
    if (fpr) {
      setFpr(fpr);
    }
  }, [location.search]);

  const signUpGoogle = React.useCallback(async () => {
    if (fpr) {
      window.open(baseURL + 'auth/google/callback?fpr=' + fpr, '_self');
    } else {
      window.open(baseURL + 'auth/google/callback', '_self');
    }
  }, [fpr]);

  const urlSignin = React.useMemo(() => {
    if (fpr) {
      return `/login?fpr=${fpr}`;
    }
    return '/login';
  }, [fpr]);

  const urlSignUp = React.useMemo(() => {
    if (fpr) {
      return `/signup?fpr=${fpr}`;
    }
    return '/signup';
  }, [fpr]);

  const { theme } = useThemeStore();

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-full">
        <div className="max-w-[400px] mx-auto">
          <div className="mb-8">
            <h1 className="mb-4 text-3xl font-extrabold dark:text-flax-900 text-eggplant-800 text-center font-lexend">
              {isLoggedIn
                ? !toolText
                  ? 'EXTEND YOUR DIALOGUE'
                  : 'ACCESS SHARED TEMPLATE'
                : 'WELCOME TO STRAICO!'}
            </h1>
            <p
              className={`${
                theme == 'dark' ? 'dark:text-white' : 'text-graphite-400'
              } font-barlow`}
            >
              {isLoggedIn ? (
                toolText ? (
                  `You're about to access a new shared template. Once you enter, the template will be added to your collection, expanding your horizons for future projects.`
                ) : (
                  `Can't get enough of this conversation? Punch the button below to transfer this shared interaction into your chats`
                )
              ) : (
                <span>
                  Welcome aboard!{' '}
                  <a
                    href="https://straico.com/"
                    target="_blank"
                    className="text-crayola-700"
                    rel="noreferrer"
                  >
                    Straico invites
                  </a>{' '}
                  you to log in or sign up and unlock a plethora of
                  opportunities with our diverse LLM models, experience the
                  thrill of analyzing YouTube videos, PDFs, and web pages like
                  never before!
                </span>
              )}
            </p>
          </div>
          <div className="space-y-4 flex flex-col text-center">
            {continueConversation ? (
              <>
                {isLoggedIn ? (
                  <NavLink
                    target="_self"
                    to={url}
                    onClick={setRedirectUrl}
                    className="p-3 bg-crayola-600 rounded  text-white w-full font-semibold font-lexend"
                  >
                    {toolText ? 'Use template' : 'Continue conversation'}
                  </NavLink>
                ) : (
                  <>
                    <NavLink
                      target="_self"
                      onClick={setRedirectUrl}
                      to={urlSignin}
                      className="p-3 bg-crayola-600 rounded  text-white w-full font-semibold font-lexend"
                    >
                      Log in to proceed with this{' '}
                      {toolText ? 'template' : 'conversation'}
                    </NavLink>
                    <NavLink
                      target="_self"
                      onClick={setRedirectUrl}
                      to={urlSignUp}
                      className="p-3 bg-white border rounded w-full font-semibold font-lexend"
                    >
                      Create a Straico account
                    </NavLink>
                  </>
                )}
              </>
            ) : (
              <>
                {isLoggedIn ? (
                  <>
                    <NavLink
                      target="_self"
                      to={url}
                      onClick={setRedirectUrl}
                      className="p-3 bg-crayola-600 rounded  text-white w-full font-semibold font-lexend"
                    >
                      {toolText ? 'Use template' : 'Continue conversation'}
                    </NavLink>
                    {/* <NavLink
                      target="_self"
                      to="/chat"
                      className="p-3 bg-white border rounded w-full font-semibold font-lexend"
                    >
                      Go to home
                    </NavLink> */}
                  </>
                ) : (
                  <>
                    <NavLink
                      target="_self"
                      to={urlSignin}
                      className="p-3 bg-crayola-600 rounded  text-white w-full font-semibold font-lexend"
                    >
                      Log in to proceed with this{' '}
                      {toolText ? 'template' : 'conversation'}
                    </NavLink>
                    <NavLink
                      target="_self"
                      to={urlSignUp}
                      className="p-3 bg-white border rounded w-full font-semibold font-lexend"
                    >
                      Create a Straico account
                    </NavLink>
                  </>
                )}
              </>
            )}
            {!isLoggedIn && (
              <>
                <div className="flex flex-col text-center mb-4">
                  <p className="text-graphite-400 text-sm font-barlow">or</p>
                  <div
                    onClick={signUpGoogle}
                    className="flex items-center hover:bg-snow-900 font-bold rounded-md px-4 py-2 bg-snow-700 text-graphite-400 mt-4 border border-snow-900 cursor-pointer justify-center font-lexend"
                  >
                    <span>
                      <img src="/logo-google-oauth.png" className="mr-2"></img>
                    </span>
                    <span className="uppercase text-sm">
                      Sign in with Google
                    </span>
                  </div>
                </div>
                <a
                  href="https://straico.com/"
                  target="_blank"
                  className="text-eggplant-700 underline text-center text-sm"
                  rel="noreferrer"
                >
                  What is Straico?
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRegister;
