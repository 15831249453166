import { useEffect, useRef, useState } from 'react';
// State
import useThemeStore from 'Theme/store';
import useTextAreaFocus from './state';
// Styles
import { cn } from 'utils/styles';
// Components
import { Box } from '@mui/material';
import Editor from 'react-simple-code-editor';
// Icons
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import FileUpload, { onChangeFile } from '../../../Components/Forms/FileUpload';

const TextAreaHightlight = ({
  value,
  onChange,
  highlightWords,
  autoFocus,
  disabled,
  onKeyDown,
  maxRows,
  minRows,
  placeholder = `Chat here or type '/' for prompt templates`,
  classNameContainer,
  classNameMainContainer,
  children,
  withoutMaxHeight,
  warningAction,
  onInteraction,
  models,
  inputWarning = false,
}) => {
  const mainContainerRef = useRef(null);
  const containerRef = useRef(null);

  function escapeHTML(str) {
    const map = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      "'": '&apos;',
    };

    // eslint-disable-next-line no-useless-escape
    return str.replace(/[\<\>\&\"\']/g, (match) => map[match]);
  }

  // !!IMPORTANT Be careful with this function, it affect the styles of prompt templates
  const highlight = (code) => {
    let highlightedCode = code;

    highlightedCode = highlightedCode.replace(/<[^>]+>/g, (match) =>
      escapeHTML(match)
    );

    highlightWords.forEach((highlightConfig) => {
      const { highlight, className, prefix, suffix } = highlightConfig;
      if (highlight && className) {
        highlightedCode = highlightedCode.replace(
          highlight,
          `<span class="${className}">${prefix ?? ''}$1${suffix ?? ''}</span>`
        );
      }
    });

    return highlightedCode;
  };

  // Focus management
  const { focusRequest, blur } = useTextAreaFocus();
  function focusEditor() {
    containerRef.current._input.focus();
    blur();
  }

  useEffect(() => {
    if (autoFocus || focusRequest) focusEditor();
  }, [autoFocus, focusRequest]);

  // Adjust container height
  useEffect(() => {
    adjustContainerHeight();
  }, [value, highlightWords, minRows, maxRows]);

  const handleChange = (e) => {
    onChange(e);
    adjustContainerHeight();
  };

  const adjustContainerHeight = () => {
    const textareaRef = containerRef.current._input;
    const textareaContainerRef = mainContainerRef.current;
    if (textareaRef) {
      const textareaHeight = textareaRef.scrollHeight + 30;
      const minContainerHeight = minRows
        ? minRows * parseInt(window.getComputedStyle(textareaRef).lineHeight)
        : null;
      const maxContainerHeight = maxRows
        ? maxRows * parseInt(window.getComputedStyle(textareaRef).lineHeight)
        : null;

      textareaContainerRef.style.height = 'auto';
      textareaContainerRef.style.height = `${Math.min(
        Math.max(textareaHeight, minContainerHeight),
        maxContainerHeight
      )}px`;
      if (textareaHeight > maxContainerHeight) {
        textareaContainerRef.style.overflowY = 'scroll';
      } else {
        textareaContainerRef.style.overflowY = 'hidden';
      }
    }
  };

  // Set spellcheck and other attributes on each input instance
  useEffect(() => {
    const inputElements = mainContainerRef?.current?.querySelectorAll(
      '.npm__react-simple-code-editor__textarea'
    );

    if (inputElements) {
      for (const inputElement of inputElements) {
        inputElement.autocomplete = 'on';
        inputElement.autocorrect = 'on';
        inputElement.spellcheck = true;
      }
    }
  }, []);

  const { theme: themeValue } = useThemeStore();

  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    const path = e.currentTarget.getAttribute('href');
    navigate(path);

    setTimeout(() => {
      const targetSection = document.querySelector('#wordcapLimitsSection');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
        targetSection.focus();
      }
    }, 300);
  };

  const noVision = () => {
    return models?.every((model) => model.type != 'vision');
  };

  const [fileSelected, setFileSelected] = useState([]);

  async function handlePaste(e) {
    const items = e.clipboardData.items;
    const files = [];
    const textItems = [];

    // Primero procesa los archivos de imagen
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        const fileName = `image_${new Date()
          .toISOString()
          .replace(/[:.]/g, '-')}.png`;
        const fileWithMetadata = new File([blob], fileName, {
          type: 'image', // Asegúrate de especificar el tipo correcto
        });
        files.push(fileWithMetadata);
      }
    }

    if (files.length > 0) {
      e.preventDefault(); // Solo previene el comportamiento por defecto si hay archivos de imagen
      if (noVision()) {
        toast(
          'You must select a model that supports images as input, such as GPT-4o',
          {
            icon: '😱',
          }
        );
        return;
      }

      const simulatedEvent = {
        target: {
          files: files,
        },
      };
      await onChangeFile(simulatedEvent, true);
      setFileSelected((prevFiles) => [...prevFiles, ...files]);
    } else {
      // Procesa el texto si no hay archivos de imagen
      for (let j = 0; j < items.length; j++) {
        if (items[j].kind === 'string') {
          textItems.push(
            new Promise((resolve) => {
              items[j].getAsString((str) => {
                // Filtrar elementos no deseados como imágenes base64
                const filteredStr = str
                  .replace(
                    /<img[^>]*src=["']data:image\/[^'"]*["'][^>]*>/gi,
                    ''
                  )
                  .replace(/<\/?[^>]+(>|$)/g, ''); // Esta línea elimina todas las etiquetas HTML
                resolve(filteredStr);
              });
            })
          );
        }
      }

      // Aquí puedes manejar la entrada de texto si lo necesitas
      const texts = await Promise.all(textItems);
      console.log(texts); // Logging texts if needed
    }
  }

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const allowedFileTypes = [
    'audio/mpeg',
    'video/mp4',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/html',
    'text/csv',
    'application/json',
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/gif',
  ];

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = e.dataTransfer.items;
    const files = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        const file = items[i].getAsFile();
        if (file) {
          // Verificación específica de tipo de archivo
          if (file.type.startsWith('image/')) {
            const fileName = `image_${new Date()
              .toISOString()
              .replace(/[:.]/g, '-')}.${file.type.split('/')[1]}`;
            const fileWithMetadata = new File([file], fileName, {
              type: 'image',
            });

            console.log('Image File dropped:', fileWithMetadata); // Registro de depuración para archivos de imagen
            files.push(fileWithMetadata);
          } else if (allowedFileTypes.includes(file.type)) {
            // Si no es imagen, verificar si está en los tipos permitidos
            console.error('Non-image File dropped:', file); // Registro de depuración para archivos no imagen
            files.push(file);
          }
        }
      }
    }

    if (files.length > 0) {
      if (files.some((file) => file.type.startsWith('image')) && noVision()) {
        toast(
          'You must select a model that supports images as input, such as GPT-4o',
          {
            icon: '😱',
          }
        );
        return;
      }

      const simulatedEvent = {
        target: {
          files: files,
        },
      };
      await onChangeFile(simulatedEvent, true);
      setFileSelected((prevFiles) => [...prevFiles, ...files]);
    } else {
      toast('Unsupported file type', {
        icon: '⚠️',
      });
    }
  };

  useEffect(() => {
    const handleWindowDragEnd = (e) => {
      setDragActive(false);
    };

    window.addEventListener('dragend', handleWindowDragEnd);
    window.addEventListener('drop', handleWindowDragEnd);

    return () => {
      window.removeEventListener('dragend', handleWindowDragEnd);
      window.removeEventListener('drop', handleWindowDragEnd);
    };
  }, []);

  return (
    <Box
      ref={mainContainerRef}
      className={cn(
        `relative rounded !h-[inherit] notranslate p-[10px] ${
          themeValue == 'dark' ? 'dark:bg-graphite-900' : 'bg-white'
        }   dark:text-white border mt-2 border-snow-900 focus:border-flax-800 dark:border-snow-100 transition-all duration-300 ease-in-out`,
        {
          'max-h-[10.5rem]': !withoutMaxHeight,
          'transform scale-105 opacity-75': dragActive,
        },
        classNameMainContainer
      )}
      onDrop={handleDrop}
      onDragOver={handleDrag}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
    >
      {dragActive ? (
        <div className="flex items-center justify-center h-full p-4">
          <span className="text-graphite-100 dark:text-snow-100 font-lexend text-m font-medium uppercase opacity-75">
            {noVision()
              ? 'Drag and drop your files here (images not supported)'
              : 'Drag and drop your files here to insert it'}
          </span>
        </div>
      ) : (
        <>
          <div style={{ display: 'none' }}>
            <FileUpload
              type=".png, .jpeg, .jpg, .webp, .gif, .mp3, .mp4, .pdf, .docx, .txt, .pptx, .xlsx, .html, .csv, .json"
              isHidden={true}
              onUploaded={(uploadedFiles) => {
                setFileSelected(uploadedFiles);
                onInteraction(uploadedFiles);
              }}
            />
          </div>
          {children}
          <Editor
            ref={containerRef}
            value={value}
            onValueChange={handleChange}
            padding={10}
            onPaste={handlePaste}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            highlight={highlight}
            className={cn(
              `flex font-barlow notranslate text-sm shadow-sm px-4 py-3 rounded min-w-full font-regular  ${
                themeValue == 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white'
                  : 'bg-white text-graphite-900'
              }`,
              classNameContainer
            )}
            textareaClassName="bg-transparent w-full h-full !flex !p-0"
            preClassName="!block !p-0"
            autoCorrect="on"
            autoComplete="on"
            spellCheck="true"
          />
          {inputWarning && (
            <div className="sticky mt-2 bottom-2 left-1 flex px-2 py-1 bg-[#474447] text-snow-100 text-xs font-medium gap-2 rounded w-fit">
              <ExclamationTriangleIcon className="w-4 h-4" />
              <div className="select-none">
                Attention: You've reached the word limit for this request. You
                can start a{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={warningAction}
                >
                  new chat
                </span>{' '}
                or{' '}
                <a
                  className="underline"
                  href="/user-settings"
                  onClick={handleNavigation}
                >
                  adjust wordcap limits
                </a>{' '}
                in your settings.
              </div>
            </div>
          )}
        </>
      )}
    </Box>
  );
};
export default TextAreaHightlight;
