import React, { useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';
import Button from './Button';
import Select from 'react-select';
import { observer, inject } from 'mobx-react';

const FeedbackForCoins = inject('store')(
  observer(({ store, modal, onInteraction }) => {
    const [success, setSuccess] = useState(false);

    const [fStars, setFStars] = useState(undefined);
    const [fOptions, setFOptions] = useState(undefined);
    const [fImprove, setFImprove] = useState(undefined);

    const sendFeedback = async () => {
      try {
        let data = await store.api
          .post('/user/freecoins', {
            stars: fStars,
            improve: fImprove,
            options: fOptions ? fOptions.map((option) => option.value) : [],
            modal: 'free_coins',
          })
          .then(async ({ data }) => {
            setSuccess(true);
            await sleep(2000);
            onInteraction('close');
          });
      } catch (err) {
        console.log(err);
      }
    };

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* Modal - Tool suggestions*/}
          {modal == 'tool_suggestions' ? (
            <div className="relative bg-white rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col">
              <div className="px-6 pt-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                    <CurrencyDollarIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-gray-900">
                      Help us improve Straico
                    </div>
                    <p className="text-sm text-gray-500">
                      Earn free coins letting us know how we can tailor our AI
                      capabilities to better suit your needs
                    </p>
                  </div>
                </div>

                <div className="pr-2">
                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      How satisfied are you with Straico's overall user
                      experience?
                    </label>
                  </div>

                  <div className="flex items-center w-full mt-2 mb-6">
                    <StarRating sendStars={setFStars} />
                  </div>

                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      Which AI tools would you be most interested in seeing
                      integrated into Straico? Select all that apply (optional):
                    </label>
                  </div>
                  <Select
                    isMulti
                    options={[
                      {
                        value: 'Speech-to-Text',
                        label: 'Speech-to-Text (Whisper)',
                      },
                      {
                        value: 'Image-to-Text',
                        label:
                          'Image-to-Text (Answer questions about an Image)',
                      },
                      {
                        value: 'Text-to-Presentation',
                        label:
                          'Text-to-Presentation (Write your own AI assisted slides)',
                      },
                      {
                        value: 'Text-to-video',
                        label:
                          'Text-to-Video (Professional videos for lead conversion)',
                      },
                      { value: 'Text-to-Music', label: 'Text-to-Music' },
                    ]}
                    name="fOptions"
                    id="fOptions"
                    className="mt-2"
                    onChange={(fOptions) => {
                      setFOptions(fOptions);
                    }}
                  />
                  <div className="mt-4">
                    <label className="text-gray-600 font-medium text-md">
                      What improvements could we make to Straico that would make
                      it a go-to platform for you?
                    </label>
                  </div>

                  <TextareaAutosize
                    minRows={4}
                    maxRows={10}
                    name={fImprove}
                    id={fImprove}
                    value={fImprove || ''}
                    placeholder="Help us make Straico the place where you work smarter, not harder. Do you have another AI tool you like and you would love to see here?"
                    className={`outline-none focus:outline-none text-lg bg-white rounded-md px-4 py-4 min-w-full border border-gray-300 font-regular focus:border-gray-400 border-gray-300font-regular mt-2`}
                    onChange={(e) => setFImprove(e.target.value)}
                  />
                </div>
              </div>

              {success ? (
                <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
                  Thank you, now you have 4.000 additional coins. To view your
                  updated coins, simply refresh the page.
                </div>
              ) : null}

              <div className="flex justify-between w-full px-6 pb-6 bottom-0">
                <Button
                  title={'Close'}
                  onClick={() => onInteraction('close')}
                />
                <Button
                  title={'Send'}
                  disabled={
                    fStars == undefined || fImprove == undefined || success
                  }
                  onClick={() => sendFeedback()}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  })
);

const StarRating = (props) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <div className="m-auto">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={
              index <= ((rating && hover) || hover)
                ? 'text-yellow-400'
                : 'text-gray-300'
            }
            onClick={() => {
              setRating(index);
              props.sendStars(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="text-5xl">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default FeedbackForCoins;
