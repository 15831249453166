import {
  PencilIcon,
  TrashIcon,
  CurrencyDollarIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';

function Header({
  title,
  category,
  tag,
  desc,
  Icon,
  options,
  currentOption,
  fromColorCategory,
  fromColorTag,
  children,
  ppublic,
  openModalEditTool,
  openModalDeleteTool,
  profile,
  avatar,
  coins,
  runs,
}) {
  const formatRuns = (runs) => {
    if (runs == 1) {
      return '1 run';
    } else if (runs < 1000) {
      return runs + ' runs';
    } else if (runs < 1000000) {
      const thousands = Math.floor(runs / 1000);
      const remainder = runs % 1000;
      const formatted = remainder ? `.${(remainder / 100).toFixed(0)}` : '';
      return `${thousands}${formatted}K runs`;
    } else {
      const millions = Math.floor(runs / 1000000);
      const remainder = Math.floor((runs % 1000000) / 100000);
      const formatted = remainder ? `.${remainder}` : '';
      return `${millions}${formatted}M runs`;
    }
  };

  return (
    <div className="bg-snow-300 dark:bg-graphite-900 md:px-8 pt-4 shadow-lg mb-px border-b border-gray-300 dark:border-graphite-900">
      <div className="container mx-auto px-4 md:px-28 flex items-center">
        {Icon ? (
          <div
            className={`mr-4 hidden md:inline-block text-${
              fromColorCategory ? fromColorCategory : 'green-500'
            }`}
          >
            {profile ? (
              avatar ? (
                <div className="avatar">
                  <div className="w-24 rounded">
                    <div dangerouslySetInnerHTML={{ __html: avatar }} />
                  </div>
                </div>
              ) : (
                <Icon className="h-16 w-16 mb-4 mt-4 text-graphite-100" />
              )
            ) : (
              <Icon className="h-16 w-16 mb-4 mt-4" />
            )}
          </div>
        ) : null}
        <div className="w-full">
          <div className="flex">
            <div className="w-7/12">
              <div
                className={`flex justify-start items-center font-lexend uppercase tracking-wide text-sm text-graphite-100 dark:text-snow-100 font-medium leading-none mt-2`}
              >
                {category}
                {tag ? (
                  <span
                    className={`flex justify-start items-center font-inter uppercase tracking-wide text-[10px] text-white bg-${
                      fromColorTag ? fromColorTag : 'green-500'
                    } leading-none ml-2 px-2 py-1 rounded-lg dark:text-graphite-900`}
                  >
                    {tag}
                  </span>
                ) : (
                  <></>
                )}{' '}
              </div>
            </div>
            <div className="w-4/12">
              {runs ? (
                <div className="flex justify-end text-graphite-100 text-sm font-normal uppercase font-lexend tracking-wide mt-0.5 mr-1">
                  <span className="-mt-[1px]">{formatRuns(runs)}</span>
                  <RocketLaunchIcon className="w-6 h-6 -mt-1 ml-0.5" />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="w-1/12">
              {coins ? (
                <div
                  className={`flex justify-end text-graphite-100 text-sm font-normal uppercase font-lexend tracking-wide mt-0.5`}
                >
                  <span className="-mt-[1px]">{coins}</span>
                  <CurrencyDollarIcon className="w-6 h-6 text-flax-900 -mt-1 ml-0.5" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="flex">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl text-eggplant-700 dark:text-flax-900 font-lexend font-bold flex align-center -mb-1 uppercase">
              {title}
            </h2>
            <span onClick={openModalEditTool} className="cursor-pointer">
              {!ppublic ? (
                <PencilIcon className="text-2xl sm:text-3xl lg:text-4xl h-7 w-7 mt-1.5 ml-3 text-gray-500 hover:text-black" />
              ) : (
                <></>
              )}
            </span>
          </div>

          <p className="text-graphite-100 dark:text-snow-100 text-sm md:text-md lg:text-lg md:mt-2 lg:mx-0 font-barlow font-normal">
            {desc}
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 md:px-28 pt-4 select-none">
        {options && (
          <div className="align-bottom bg-snow-100 text-left transform transition-all sm:align-middle transition inline-flex">
            {options.map((option, index) => (
              <Option key={index} currentOption={currentOption} {...option} />
            ))}
          </div>
        )}
        {!ppublic ? (
          <div
            className="align-bottom bg-snow-100 text-right transform transition-all sm:align-middle transition inline-flex float-right cursor-pointer"
            onClick={openModalDeleteTool}
          >
            <TrashIcon className="h-7 w-7 mt-2 lg:mt-3 text-gray-500 hover:text-black" />
          </div>
        ) : (
          <></>
        )}

        {children}
      </div>
    </div>
  );
}

export function Option({ title, Icon, onClick, currentOption, color }) {
  let active = currentOption === title;
  return (
    <div
      className={`lg:py-4 lg:px-8 py-3 px-6 flex transition text-${
        active ? `${color ? color : 'green'}-800` : 'gray-500'
      } font-medium font-barlow border-b -mb-px rounded-t-lg border-${
        active ? `${color ? color : 'green'}-500` : 'gray-400'
      } bg-${active ? `${color ? color : 'green'}-100` : 'snow-100'} hover:bg-${
        active ? `${color ? color : 'green'}-200` : 'gray-100'
      } cursor-pointer `}
      onClick={() => onClick(title)}
    >
      <div
        className={`md:mr-4  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
          active ? `${color ? color : 'green'}` : 'gray'
        }-300 text-${active ? `${color ? color : 'green'}` : 'gray'}-600`}
      >
        <Icon
          className={`h-4 w-4 text-${
            active ? `${color ? color : 'green'}` : 'gray'
          }-600`}
          aria-hidden="true"
        />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default Header;
