import { create } from 'zustand';

const useChatStore = create((set) => ({
  // States
  chatList: [],
  selectedChat: {
    index: null,
    chat: {
      _id: null,
      messages: [],
    },
  },
  loadingChats: false,
  totalPages: 1,
  currentPage: 1,
  totalChats: 0,
  chatWords: 0,
  refFile: undefined,

  // Functions
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }));
  },
  setTotalChats: (totalChats) => {
    set(() => ({
      totalChats,
    }));
  },
  setCurrentPage: (currentPage) => {
    set(() => ({
      currentPage,
    }));
  },
  selectChat: (chatId) => {
    set((state) => ({
      selectedChat: {
        index: (state.chatList.length ? state.chatList : []).findIndex(
          (chat) => chat._id === chatId
        ),
        chat: (state.chatList.length ? state.chatList : []).find(
          (chat) => chat._id === chatId
        ),
      },
    }));
  },
  setSelectedChat: (chat) => {
    set((state) => ({
      selectedChat: {
        ...state.selectedChat,
        chat,
      },
    }));
  },
  cleanSelectedChat: () => {
    set(() => ({
      selectedChat: {
        index: null,
        chat: {
          _id: null,
          messages: [],
        },
      },
    }));
  },
  setChatsList: (newChatData) => {
    set(() => ({
      chatList: newChatData,
    }));
  },
  setLoadingChats: (loading) => {
    set(() => ({
      loadingChats: loading,
    }));
  },
  cleanChatList: () => {
    set(() => ({
      chatList: [],
    }));
  },
  setChatWords: (chatWords) => {
    set(() => ({
      chatWords,
    }));
  },
  setRefFile: (refFile) => {
    set(() => ({
      refFile,
    }));
  },
  updateRefFile: (updateFunc) => {
    set((state) => ({
      refFile: updateFunc(state.refFile),
    }));
  },
}));

export default useChatStore;
