import { XMarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { Dialog, IconButton } from '@mui/material';

const Modal = ({
  onClose,
  open,
  children,
  maxWidth = 'md',
  minWidth,
  title,
  noIconTitle = false,
  iconTitle,
  onConfirm,
  onConfirmBtnIcon,
  onConfirmBtnText,
  buttonConfirmProps,
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      PaperProps={{
        className: 'bg-[#F6F7F5] dark:text-snow-200',
        sx: {
          borderRadius: '4px',
          p: '32px',
          minWidth: minWidth ? minWidth : { xs: '350px', md: '550px' },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <div className="h-full w-full relative text-graphite-400 dark:text-snow-200 sm:max-w-lg">
        <div className="absolute -top-6 -right-6">
          <IconButton onClick={onClose}>
            <XMarkIcon className="h-5 w-5 cursor-pointer text-graphite-400 dark:text-snow-200 dark:hover:text-snow-400 hover:text-graphite-500" />
          </IconButton>
        </div>
        {title && (
          <div className="flex gap-2 items-center">
            {!noIconTitle && (
              <div className="flex items-center justify-center w-6 h-6 flex-shrink-0 rounded-md bg-vanilla-400">
                {!iconTitle ? (
                  <WrenchIcon className="w-5 h-5 text-eggplant-700 dark:text-flax-700 p-[1px] font-bold" />
                ) : (
                  <>{iconTitle}</>
                )}
              </div>
            )}
            <div className="flex flex-col">
              <div className="uppercase text-eggplant-700  dark:text-flax-700 text-lg font-bold font-lexend">
                {title}
              </div>
            </div>
          </div>
        )}
        <div>{children}</div>
        {onConfirm && (
          <div className="flex w-full items-center justify-center">
            <button
              {...buttonConfirmProps}
              className="flex items-center justify-center uppercase w-[200px] self-center mt-6 text-[18px] font-bold rounded-[4px] px-4 py-1.5 text-white shadow  focus:outline-none bg-crayola-600 hover:bg-crayola-700 font-lexend"
              onClick={onConfirm}
            >
              {onConfirmBtnIcon ? (
                <>{onConfirmBtnIcon}</>
              ) : (
                <BookmarkIcon className="w-5 h-5 text-white mr-1" />
              )}{' '}
              {onConfirmBtnText ?? 'Save'}
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
