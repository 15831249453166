/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import Button from './Button';
import { observer, inject } from 'mobx-react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Box, Checkbox, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  SparklesIcon,
  StarIcon,
  ChatBubbleBottomCenterTextIcon,
  PhotoIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  CpuChipIcon,
  ServerStackIcon,
} from '@heroicons/react/24/outline';
import LLMIcon from '../Theme/icons/LLMIcon';
import CapabilitiesIcon from '../Theme/icons/CapabilitiesIcon';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { styled as custom } from '@mui/material/styles';

import * as outlineIcons from '@heroicons/react/24/outline';
import { get } from 'lodash';
import useThemeStore from 'Theme/store';

const MAX_MODELS_SELECTION = 4;

const ModelDialog = inject('store')(
  observer(
    ({
      store,
      selectedModels,
      onInteraction,
      onClose,
      onCapabilities,
      selectedCapabilities,
    }) => {
      const [modelsSelected, setModelsSelected] = useState([]);
      const [models, setModels] = useState([]);

      const [freePlanModels, setFreePlanModels] = useState([]);

      const [tabSelected, setTabSelected] = useState('0');

      const [capabilitiesSelected, setCapabilitiesSelected] = useState([]);
      const [capabilities, setCapabilities] = useState([]);

      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('md'));
      const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'));

      const navigate = useNavigate();

      useEffect(() => {
        (async () => {
          await getModels();
          await getCapabilities();
        })();
      }, []);

      const getModels = async () => {
        try {
          const response = await store.api.get(`/model`);
          let availableModels = response.data.models;
          if (store.profile.plan == 'freeTos') {
            const res = await store.api.get('/user/constant', {
              params: {
                type: 'princing_plans',
              },
            });
            let freePlanModels = []; // Array to store models allowed for free users

            freePlanModels = res.data.rules[0].models;

            const modelsInFreePlan = []; // Array to store models available for free users
            const otherModels = []; // Array to store models not available for free users

            availableModels.forEach((model) => {
              if (freePlanModels.includes(model.model)) {
                modelsInFreePlan.push(model);
              } else {
                otherModels.push(model);
              }
            });
            // setModels(setDefaultModel(otherModels));
            setModels(otherModels);
            setFreePlanModels(setDefaultModel(modelsInFreePlan));
          } else {
            setModels(setDefaultModel(response.data.models));
          }
        } catch (error) {
          console.error(error);
        }
      };

      const getCapabilities = async () => {
        if (selectedCapabilities) {
          setCapabilitiesSelected(selectedCapabilities);
        }
        try {
          const response = await store.api.get(`/capability`);
          setCapabilities(response.data.capabilities);
        } catch (error) {
          console.error(error);
        }
      };

      const setDefaultModel = (models) => {
        setModelsSelected(selectedModels);
        return models;
      };

      const handleChange = (model) => {
        if (isSelected(model)) {
          setModelsSelected((prev) =>
            prev.filter((item) => item._id !== model._id)
          );
        } else {
          if (modelsSelected.length < MAX_MODELS_SELECTION) {
            setModelsSelected((prev) => [...prev, model]);
          } else {
            toast('You only can select up to 4 LLMs simultaneously', {
              icon: '⚠️',
            });
          }
        }
      };

      const handleCapabilities = (capability) => {
        if (isActive(capability)) {
          setCapabilitiesSelected((prev) =>
            prev.filter((item) => item._id !== capability._id)
          );
        } else {
          setCapabilitiesSelected((prev) => [...prev, capability]);
        }
      };

      const isSelected = (model) => {
        return Boolean(modelsSelected.find((item) => item._id === model._id));
      };

      const isActive = (capability) => {
        return Boolean(
          capabilitiesSelected.find((item) => item._id === capability._id)
        );
      };

      const getIcon = (icon) => {
        const Icon = get(outlineIcons, icon);
        return Icon ? <Icon className={'h-6 w-6'} /> : null;
      };

      const getCapabilityIcon = (model) => {
        if (model.function_calls.length) {
          // find capability
          let modelCapabilities = capabilities.filter((capability) =>
            model.function_calls
              .map((func) => func.capability)
              .includes(capability._id)
          );
          return (
            <Shortcut>
              <CapabilitiesIcon className="w-4 h-4" />
              <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-2/3 -top-full">
                <div className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  {modelCapabilities.map((capability, index) => (
                    <div key={index}>{capability.name}</div>
                  ))}
                </div>
              </Tooltip>
            </Shortcut>
          );
        } else {
          return '-';
        }
      };

      const getCompability = (capability) => {
        const comModels = modelsSelected.filter(
          (model) =>
            model.function_calls &&
            model.function_calls.length > 0 &&
            model.function_calls.some(
              (func) => func.capability == capability._id
            )
        );
        return <>{comModels.map((model) => model.abbreviation).join(',')}</>;
      };

      const getUncompability = (capability) => {
        const uncomModels = modelsSelected.filter(
          (model) =>
            !model.function_calls ||
            model.function_calls.length == 0 ||
            !model.function_calls.some(
              (func) => func.capability == capability._id
            )
        );
        return <>{uncomModels.map((model) => model.abbreviation).join(',')}</>;
      };

      const onSelect = () => {
        onInteraction(modelsSelected);
        onCapabilities(capabilitiesSelected);
      };

      const { theme: themeValue } = useThemeStore();

      return (
        <>
          <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 transition-opacity" onClick={onClose}>
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              className={`relative ${
                themeValue == 'dark'
                  ? 'bg-graphite-900 dark:text-white'
                  : 'bg-white text-graphite-800'
              }    rounded overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full max-h-full`}
            >
              <div className={`px-4 pt-4 h-min`}>
                {/* Close button */}
                <button
                  onClick={onClose}
                  className="absolute right-1 top-2 p-1 hover:bg-vanilla-100 dark:hover:bg-graphite-300 text-sm rounded-md"
                >
                  <XMarkIcon
                    className={`w-5 h-5 ${
                      theme == 'dark'
                        ? 'dark:text-snow-200'
                        : 'text-graphite-600'
                    }`}
                  />
                </button>

                <div
                  className={`mb-0 ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-900'
                  } font-lexend text-sm font-bold uppercase mb-2`}
                >
                  Select prompt feature
                </div>

                <Box sx={{ width: '100%' }}>
                  <TabContext value={tabSelected}>
                    {/* Tabs */}
                    <TabList
                      onChange={(_, newValue) => setTabSelected(newValue)}
                      orientation="horizontal"
                      variant="scrollable"
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        '& .MuiTabs-indicator': { display: 'none' },
                      }}
                    >
                      <StyledTab
                        value="0"
                        label="LLM"
                        sx={{
                          fontFamily: 'Lexend Deca',
                          fontSize: '11px',
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          minHeight: '54px',
                        }}
                        icon={<LLMIcon className="mb-2" />}
                      />
                      <StyledTab
                        value="1"
                        label="Capabilities"
                        sx={{
                          fontFamily: 'Lexend Deca',
                          fontSize: '11px',
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          minHeight: '54px',
                        }}
                        icon={<CapabilitiesIcon className="mb-2" />}
                      />
                    </TabList>

                    {/* Panel 0 */}
                    <TabPanel value="0" sx={{ padding: '12px 8px' }}>
                      <div
                        className={`py-2 pb-1 ${
                          themeValue == 'dark'
                            ? 'dark:text-white'
                            : 'text-graphite-900'
                        } font-barlow text-xs font-normal`}
                      >
                        <ul className="list-disc">
                          <li className="ml-5">
                            <span className="-ml-1">
                              Select your default LLM for all your chats{' '}
                              <a
                                className="font-semibold text-crayola-600 underline"
                                href="https://platform.straico.com/user-settings"
                                target="_blank"
                                rel="noreferrer"
                              >
                                here
                              </a>{' '}
                              or delve deeper into our LLM options{' '}
                              <a
                                className="font-semibold text-crayola-600 underline"
                                href="https://straico.com/multimodel/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                here
                              </a>
                            </span>
                          </li>
                          <li className="ml-5 mt-1">
                            <span className="-ml-1">
                              You can select up to 4 LLMs simultaneously
                            </span>
                          </li>
                        </ul>
                      </div>
                      {/* This is the models for the free plan */}
                      {freePlanModels.length > 0 && (
                        <div>
                          <div
                            id="straico-llm-list"
                            className="max-h-96 overflow-y-hidden"
                          >
                            {freePlanModels.map((model, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  handleChange(model);
                                  setFreePlanModels((prevModels) => {
                                    const modified = prevModels.map((item) => {
                                      item.selected = item._id === model._id;
                                      return item;
                                    });
                                    return modified;
                                  });
                                }}
                              >
                                <div></div>
                                {index == 0 ? (
                                  <div
                                    className={`grid grid-cols-[${
                                      isMobile
                                        ? '2.5fr_repeat(3,0.5fr)'
                                        : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                    }] gap-x-2 items-center justify-between w-full  p-1 rounded cursor-pointer select-none`}
                                  >
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Model
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Max words
                                      </div>
                                    )}
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Moderation
                                      </div>
                                    )}
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Features
                                    </div>
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Capabilities
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Editor's choice
                                      </div>
                                    )}
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Coins x 100 words
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div
                                  className={`grid grid-cols-[${
                                    isMobile
                                      ? '2.5fr_repeat(3,0.5fr)'
                                      : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                  }] gap-x-2 items-center justify-between w-full ${
                                    isSelected(model)
                                      ? 'bg-snow-400 font-medium'
                                      : 'font-normal'
                                  } p-1 rounded cursor-pointer select-none`}
                                >
                                  <div
                                    className={`flex  items-center w-full text-sm font-barlow font-semibold ${
                                      themeValue == 'dark'
                                        ? 'dark:text-white'
                                        : 'text-graphite-900'
                                    }`}
                                  >
                                    <Checkbox
                                      checked={isSelected(model)}
                                      size="small"
                                      disableRipple
                                      sx={{
                                        padding: 0,
                                        borderRadius: '5px',
                                      }}
                                    />
                                    <div
                                      className={` whitespace-nowrap truncate ${
                                        isSmallMobile ? 'max-w-[180px]' : ''
                                      }`}
                                    >
                                      {model.name}
                                    </div>
                                  </div>
                                  {isMobile ? (
                                    ''
                                  ) : (
                                    <div
                                      className={` w-full  text-xs font-barlow ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      <div className="text-xs font-barlow text-graphite-800 dark:text-snow-200 font-normal">
                                        ~{' '}
                                        {model.words_text.toLocaleString(
                                          'en-US'
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {isMobile ? (
                                    ''
                                  ) : (
                                    <div
                                      className={`text-xs font-barlow ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      } font-normal`}
                                    >
                                      <div>{model.moderation}</div>
                                    </div>
                                  )}
                                  <div
                                    className={`w-full  text-xs font-barlow t${
                                      themeValue == 'dark'
                                        ? 'dark:text-white'
                                        : 'text-graphite-900'
                                    }`}
                                  >
                                    <div
                                      className={`relative flex gap-x-1 ${
                                        isSelected(model)
                                          ? 'text-vanilla-900 '
                                          : ' text-gray-400 '
                                      }`}
                                    >
                                      <Shortcut>
                                        <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                        <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                          <span
                                            className={`relative z-50 p-2 text-sm leading-none ${
                                              themeValue == 'dark'
                                                ? 'dark:text-white'
                                                : 'text-graphite-900'
                                            } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                          >
                                            Text input/output enabled
                                          </span>
                                        </Tooltip>
                                      </Shortcut>
                                      {model.type == 'vision' && (
                                        <Shortcut>
                                          <PhotoIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span
                                              className={`relative z-50 p-2 text-sm leading-none ${
                                                themeValue == 'dark'
                                                  ? 'dark:text-white'
                                                  : 'text-graphite-900'
                                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                            >
                                              Accepts image input
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                      )}
                                      {model.type == 'web' && (
                                        <Shortcut>
                                          <GlobeAltIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span
                                              className={`relative z-50 p-2 text-sm leading-none ${
                                                themeValue == 'dark'
                                                  ? 'dark:text-white'
                                                  : 'text-graphite-900'
                                              } bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md`}
                                            >
                                              Web search capable
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`w-full  text-xs font-barlow ${
                                      themeValue == 'dark'
                                        ? 'dark:text-white'
                                        : 'text-graphite-900'
                                    }`}
                                  >
                                    <div
                                      className={`relative flex justify-center ${
                                        isSelected(model)
                                          ? 'text-vanilla-900 '
                                          : ' text-gray-400 '
                                      }`}
                                    >
                                      {getCapabilityIcon(model)}
                                    </div>
                                  </div>
                                  {isMobile ? (
                                    ''
                                  ) : !model.editors_link ? (
                                    <div className="text-center">
                                      <p>-</p>
                                    </div>
                                  ) : (
                                    <div
                                      className={`text-xs font-barlow ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      } font-normal text-center`}
                                    >
                                      <a
                                        href={model.editors_link}
                                        className="text-sm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <p>👑</p>
                                      </a>
                                    </div>
                                  )}
                                  <div
                                    className={`w-full  text-xs font-barlow text-graphite-800`}
                                  >
                                    <div
                                      className={`font-barlow text-xs font-bold ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      } flex gap-1`}
                                    >
                                      <CurrencyDollarIcon className="w-4 h-4 text-flax-900" />
                                      <div>{model.pricing.coins}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div
                              className={`flex justify-center w-full p-2 bottom-0`}
                            >
                              <Button
                                title={'Select'}
                                disabled={!modelsSelected.length}
                                noMarginTop
                                onClick={onSelect}
                              />
                            </div>
                          </div>
                          <div className="relative max-h-96 min-h-80">
                            {models.length > 0 && (
                              <div
                                id="straico-llm-list"
                                className="max-h-96 overflow-y-hidden"
                              >
                                {store.profile.plan == 'freeTos' && (
                                  <div className="absolute inset-0 z-0 justify-center h-auto m-auto flex flex-col items-center px-20">
                                    <SparklesIcon className="h-6 flex justify-center w-full mb-5" />
                                    <div
                                      className={`text-center not-italic ${
                                        themeValue == 'dark'
                                          ? 'dark:text-flax-900'
                                          : 'text-eggplant-700'
                                      }   font-lexend text-sm font-bold uppercase pb-3`}
                                    >
                                      {store.profile.trial_end
                                        ? 'Want to unlock the full potential?'
                                        : 'Want access to all our LLMs (ai models)?'}
                                    </div>{' '}
                                    {store.profile.trial_end ? (
                                      <div
                                        className={`text-center not-italic ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        } font-barlow text-sm  font-medium pb-5`}
                                      >
                                        <a
                                          href="/my-profile/pricing"
                                          className="text-crayola-600 font-barlow text-sm font-semibold underline"
                                        >
                                          Upgrade now
                                        </a>{' '}
                                        to access multi-model feature and{' '}
                                        {models.length} different filtered and
                                        unfiltered LLMs to query.
                                      </div>
                                    ) : (
                                      <div
                                        className={`text-center not-italic ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        } font-barlow text-sm  font-medium pb-5`}
                                      >
                                        Interact with {models.length} different
                                        LLMs on our platform. Start your{' '}
                                        <a
                                          href="/my-profile/pricing"
                                          className="text-crayola-600 font-barlow text-sm font-semibold underline"
                                        >
                                          {
                                            process.env
                                              .REACT_APP_TRIAL_PERIOD_DAYS
                                          }
                                          -day trial{' '}
                                        </a>{' '}
                                        for just $1 now!
                                      </div>
                                    )}
                                    <div className="flex justify-center w-full">
                                      <button
                                        type="button"
                                        className="bg-[#E57A44] shadow-md rounded py-1 px-3 text-snow-100 font-bold text-center text-sm font-lexend uppercase flex"
                                        onClick={() => {
                                          navigate('/my-profile/pricing');
                                        }}
                                      >
                                        <StarIcon className="w-5 h-5  mr-2" />
                                        {store.profile.trial_end
                                          ? 'Upgrade Today'
                                          : `try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`}
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className=" absolute inset-0 z-[-1] opacity-20 blur-[1px] pt-2">
                                  {models.map((model, index) => (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleChange(model);
                                        setModels((prevModels) => {
                                          const modified = prevModels.map(
                                            (item) => {
                                              item.selected =
                                                item._id === model._id;
                                              return item;
                                            }
                                          );
                                          return modified;
                                        });
                                      }}
                                    >
                                      <div
                                        className={`grid grid-cols-[${
                                          isMobile
                                            ? '2.5fr_repeat(2,0.5fr)'
                                            : '2.5fr_0.5fr_0.75fr_repeat(3,0.5fr)'
                                        }] gap-x-2 items-center justify-between w-full ${
                                          isSelected(model)
                                            ? themeValue == 'dark'
                                              ? 'dark:bg-graphite-300 font-medium'
                                              : 'bg-snow-400 font-medium'
                                            : 'font-normal'
                                        } p-1 rounded cursor-pointer select-none`}
                                      >
                                        <div
                                          className={`flex  items-center w-full text-sm font-barlow font-semibold ${
                                            themeValue == 'dark'
                                              ? 'dark:text-white'
                                              : 'text-graphite-900'
                                          }`}
                                        >
                                          <Checkbox
                                            checked={isSelected(model)}
                                            size="small"
                                            disableRipple
                                            sx={{
                                              padding: 0,
                                              borderRadius: '5px',
                                            }}
                                          />
                                          <div
                                            className={` whitespace-nowrap truncate ${
                                              isSmallMobile
                                                ? 'max-w-[180px]'
                                                : ''
                                            }`}
                                          >
                                            {model.name}
                                          </div>
                                        </div>
                                        {isMobile ? (
                                          ''
                                        ) : (
                                          <div
                                            className={` w-full  text-xs font-barlow ${
                                              themeValue == 'dark'
                                                ? 'dark:text-white'
                                                : 'text-graphite-900'
                                            }`}
                                          >
                                            <div
                                              className={`text-xs font-barlow ${
                                                themeValue == 'dark'
                                                  ? 'dark:text-white'
                                                  : 'text-graphite-900'
                                              } font-normal`}
                                            >
                                              ~{' '}
                                              {model.words_text.toLocaleString(
                                                'en-US'
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        {isMobile ? (
                                          ''
                                        ) : (
                                          <div
                                            className={`text-xs font-barlow ${
                                              themeValue == 'dark'
                                                ? 'dark:text-white'
                                                : 'text-graphite-900'
                                            } font-normal`}
                                          >
                                            <div>{model.moderation}</div>
                                          </div>
                                        )}
                                        <div
                                          className={`w-full  text-xs font-barlow text-graphite-800 dark:text-snow-200`}
                                        >
                                          <div
                                            className={`relative flex gap-x-1 ${
                                              isSelected(model)
                                                ? 'text-vanilla-900 '
                                                : ' text-gray-400 '
                                            }`}
                                          >
                                            <Shortcut>
                                              <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                              <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                  Text input/output enabled
                                                </span>
                                              </Tooltip>
                                            </Shortcut>
                                            {model.type == 'vision' && (
                                              <Shortcut>
                                                <PhotoIcon className="w-4 h-4" />
                                                <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                  <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                    Accepts image input
                                                  </span>
                                                </Tooltip>
                                              </Shortcut>
                                            )}
                                            {model.type == 'web' && (
                                              <Shortcut>
                                                <GlobeAltIcon className="w-4 h-4" />
                                                <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                                  <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                    Web search capable
                                                  </span>
                                                </Tooltip>
                                              </Shortcut>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className={`w-full  text-xs font-barlow text-graphite-800 dark:text-snow-200`}
                                        >
                                          <div className="font-barlow text-xs font-bold text-graphite-800 dark:text-snow-200 flex gap-1">
                                            <CurrencyDollarIcon className="w-4 h-4 text-flax-900" />
                                            <div>{model.pricing.coins}</div>
                                          </div>
                                        </div>
                                        {isMobile ? (
                                          ''
                                        ) : !model.editors_link ? (
                                          <div className="text-center">
                                            <p>-</p>
                                          </div>
                                        ) : (
                                          <div className="text-xs font-barlow text-graphite-800 dark:text-snow-200 font-normal text-center">
                                            <a
                                              href={model.editors_link}
                                              className="text-sm"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <p>👑</p>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {models.length > 0 &&
                        store.profile.plan !== 'freeTos' && (
                          <>
                            <div
                              id="straico-llm-list"
                              className="max-h-96 overflow-y-scroll"
                            >
                              {models.map((model, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    handleChange(model);
                                    setModels((prevModels) => {
                                      const modified = prevModels.map(
                                        (item) => {
                                          item.selected =
                                            item._id === model._id;
                                          return item;
                                        }
                                      );
                                      return modified;
                                    });
                                  }}
                                >
                                  {index == 0 ? (
                                    <div
                                      className={`grid grid-cols-[${
                                        isMobile
                                          ? '2.5fr_repeat(3,0.5fr)'
                                          : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                      }] gap-x-2 items-center justify-between w-full  p-1 rounded cursor-pointer select-none`}
                                    >
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Model
                                      </div>
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-lexend font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'dark:text-white'
                                              : 'text-graphite-900'
                                          }`}
                                        >
                                          Max words
                                        </div>
                                      )}
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-lexend font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'dark:text-white'
                                              : 'text-graphite-900'
                                          }`}
                                        >
                                          Moderation
                                        </div>
                                      )}
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Features
                                      </div>
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Capabilities
                                      </div>
                                      {isMobile ? (
                                        ''
                                      ) : (
                                        <div
                                          className={`font-lexend font-bold text-xs ${
                                            themeValue == 'dark'
                                              ? 'dark:text-white'
                                              : 'text-graphite-900'
                                          }`}
                                        >
                                          Editor's choice
                                        </div>
                                      )}
                                      <div
                                        className={`font-lexend font-bold text-xs ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        Coins x 100 words
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div
                                    className={`grid grid-cols-[${
                                      isMobile
                                        ? '2.5fr_repeat(3,0.5fr)'
                                        : '2.5fr_0.5fr_0.75fr_repeat(4,0.5fr)'
                                    }] gap-x-2 items-center justify-between w-full ${
                                      isSelected(model)
                                        ? themeValue == 'dark'
                                          ? 'dark:bg-graphite-300 font-medium'
                                          : 'bg-snow-400 font-medium'
                                        : 'font-normal'
                                    } p-1 rounded cursor-pointer select-none`}
                                  >
                                    <div
                                      className={`flex  items-center w-full text-sm font-barlow font-semibold ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      <Checkbox
                                        checked={isSelected(model)}
                                        size="small"
                                        disableRipple
                                        sx={{
                                          padding: 0,
                                          borderRadius: '5px',
                                        }}
                                      />
                                      <div
                                        className={` whitespace-nowrap truncate ${
                                          isSmallMobile ? 'max-w-[180px]' : ''
                                        }`}
                                      >
                                        {model.name}
                                      </div>
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={` w-full  text-xs font-barlow ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        }`}
                                      >
                                        <div
                                          className={`text-xs font-barlow ${
                                            themeValue == 'dark'
                                              ? 'dark:text-white'
                                              : 'text-graphite-900'
                                          } font-normal`}
                                        >
                                          ~{' '}
                                          {model.words_text.toLocaleString(
                                            'en-US'
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {isMobile ? (
                                      ''
                                    ) : (
                                      <div
                                        className={`text-xs font-barlow ${
                                          themeValue == 'dark'
                                            ? 'dark:text-white'
                                            : 'text-graphite-900'
                                        } font-normal`}
                                      >
                                        <div>{model.moderation}</div>
                                      </div>
                                    )}
                                    <div
                                      className={`w-full  text-xs font-barlow ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      <div
                                        className={`relative flex gap-x-1 ${
                                          isSelected(model)
                                            ? 'text-vanilla-900 '
                                            : ' text-gray-400 '
                                        }`}
                                      >
                                        <Shortcut>
                                          <ChatBubbleBottomCenterTextIcon className="w-4 h-4" />
                                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                            <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                              Text input/output enabled
                                            </span>
                                          </Tooltip>
                                        </Shortcut>
                                        {model.type == 'vision' && (
                                          <Shortcut>
                                            <PhotoIcon className="w-4 h-4" />
                                            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                              <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                Accepts image input
                                              </span>
                                            </Tooltip>
                                          </Shortcut>
                                        )}
                                        {model.type == 'web' && (
                                          <Shortcut>
                                            <GlobeAltIcon className="w-4 h-4" />
                                            <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                                              <span className="relative z-50 p-2 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                                Web search capable
                                              </span>
                                            </Tooltip>
                                          </Shortcut>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={`w-full  text-xs font-barlow text-graphite-800 dark:text-snow-200`}
                                    >
                                      <div
                                        className={`relative flex justify-center ${
                                          isSelected(model)
                                            ? 'text-vanilla-900 '
                                            : ' text-gray-400 '
                                        }`}
                                      >
                                        {getCapabilityIcon(model)}
                                      </div>
                                    </div>
                                    {isMobile ? (
                                      ''
                                    ) : !model.editors_link ? (
                                      <div className="text-center">
                                        <p>-</p>
                                      </div>
                                    ) : (
                                      <div className="text-xs font-barlow text-graphite-800 dark:text-snow-200 font-normal text-center">
                                        <a
                                          href={model.editors_link}
                                          className="text-sm"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p>👑</p>
                                        </a>
                                      </div>
                                    )}
                                    <div
                                      className={`w-full  text-xs font-barlow text-graphite-800 dark:text-snow-200`}
                                    >
                                      <div className="font-barlow text-xs font-bold text-graphite-800 dark:text-snow-200 flex gap-1">
                                        <CurrencyDollarIcon className="w-4 h-4 text-flax-900" />
                                        <div>{model.pricing.coins}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div
                              className={`flex justify-center w-full p-2 bottom-0`}
                            >
                              <Button
                                title={'Select'}
                                disabled={!modelsSelected.length}
                                noMarginTop
                                onClick={onSelect}
                              />
                            </div>
                          </>
                        )}
                    </TabPanel>

                    {/* Panel 1 */}
                    <TabPanel value="1" sx={{ padding: '12px 8px' }}>
                      <div className="py-2 pb-1 text-graphite-800 dark:text-snow-100 font-barlow text-xs font-normal">
                        Select a capability for enhancing your LLM power.
                      </div>
                      <div>
                        {capabilities.map((capability, index) => (
                          <div
                            key={index}
                            className="font-barlow text-graphite-800"
                          >
                            {index == 0 && (
                              <div
                                className={`grid ${
                                  isMobile
                                    ? 'grid-cols-[repeat(4,1fr)]'
                                    : 'grid-cols-[repeat(6,1fr)]'
                                } gap-x-2 items-center justify-between w-full  p-1 rounded select-none`}
                              >
                                <div
                                  className={`font-lexend font-bold text-xs ${
                                    themeValue == 'dark'
                                      ? 'dark:text-white'
                                      : 'text-graphite-900'
                                  }  col-span-3`}
                                >
                                  Capability
                                </div>
                                {!isMobile && (
                                  <>
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Compatible with selected
                                    </div>
                                    <div
                                      className={`font-lexend font-bold text-xs ${
                                        themeValue == 'dark'
                                          ? 'dark:text-white'
                                          : 'text-graphite-900'
                                      }`}
                                    >
                                      Incompatible with selected
                                    </div>
                                  </>
                                )}
                                <div
                                  className={`font-lexend font-bold text-xs ${
                                    themeValue == 'dark'
                                      ? 'dark:text-white'
                                      : 'text-graphite-900'
                                  }`}
                                >
                                  Coins per prompt
                                </div>
                              </div>
                            )}
                            <div
                              className={`grid ${
                                isMobile
                                  ? 'grid-cols-[repeat(4,1fr)]'
                                  : 'grid-cols-[repeat(6,1fr)]'
                              } gap-x-2 items-center justify-between w-full  p-1 rounded select-none`}
                            >
                              <div
                                className={`col-span-3 flex gap-2 font-semibold text-sm ${
                                  isActive(capability)
                                    ? 'bg-flax-100 border border-black'
                                    : 'bg-snow-500'
                                } rounded p-2 cursor-pointer`}
                                onClick={() => handleCapabilities(capability)}
                              >
                                {getIcon(capability.icon)}
                                <div className="flex-1">
                                  <div>{capability.name}</div>
                                  <div className="font-normal text-xs">
                                    {capability.description}
                                  </div>
                                </div>
                              </div>
                              {!isMobile && (
                                <>
                                  <div className="h-full text-xs dark:text-snow-100">
                                    {getCompability(capability)}
                                  </div>
                                  <div className="h-full text-xs text-crayola-900">
                                    {getUncompability(capability)}
                                  </div>
                                </>
                              )}
                              <div
                                className={`w-full  text-xs font-barlow text-graphite-800`}
                              >
                                <div
                                  className={`font-barlow text-xs font-bold ${
                                    themeValue == 'dark'
                                      ? 'dark:text-white'
                                      : 'text-graphite-900'
                                  } flex gap-1`}
                                >
                                  <CurrencyDollarIcon className="w-4 h-4 text-flax-900" />
                                  <div>{capability.coins}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div
                          className={`flex justify-center w-full p-2 bottom-0`}
                        >
                          <Button
                            title={'Select'}
                            disabled={!modelsSelected.length}
                            noMarginTop
                            onClick={onSelect}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </div>
        </>
      );
    }
  )
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

const StyledTab = custom((props) => <Tab {...props} />)(({ theme }) => ({
  width: '102px',
  height: '58px',
  borderRadius: '4px',
  backgroundColor: '#E7E9E4',
  marginRight: '4px',
  color: '#797779',
  '&.Mui-selected': {
    color: '#211E21',
    backgroundColor: '#BCD8C1',
  },
}));

export default ModelDialog;
