const ImageDialog = ({ onClose, children }) => {
  return (
    <>
      <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="relative bg-white rounded-md shadow-xl transform transition-all max-h-[calc(100vh_-_2rem)]">
          {children}
        </div>
      </div>
    </>
  );
};

export default ImageDialog;
