/* eslint-disable no-constant-condition */
import { observer, inject } from 'mobx-react';
import { useEffect } from 'react';
import { withRouter, useNavigate } from 'react-router-dom';
// Logo
import OrangeLogo from 'Theme/icons/orangeLogo';
//RHF
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//Yup
import * as yup from 'yup';
// Components
import Button from '../../Components/Button';
import SelectControlled from '../../Components/Forms/SelectControlled/SelectControlled';
import CheckboxControlled from '../../Components/Forms/CheckboxControlled/CheckboxControlled';

const validationSchemaWelcome = yup.object().shape({
  agree_mailing_list: yup.boolean(),
  age_checked: yup.boolean().oneOf([true], 'You must confirm your age.'),
  agree_terms_and_conditions: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions.'),
  primarily_use_pr: yup
    .string()
    .required('Please select how you primarily use Straico.'),
  kind_of_work: yup.string().required('Please select your kind of work.'),
});

const Welcome = inject('store')(
  observer(({ store }) => {
    let navigate = useNavigate();
    // Form Welcome logic with RHF
    const { control, handleSubmit, setValue } = useForm({
      defaultValues: {
        state: '',
        agree_mailing_list: true,
        age_checked: true,
        agree_terms_and_conditions: true,
        primarily_use_pr: '',
        kind_of_work: '',
      },
      resolver: yupResolver(validationSchemaWelcome),
    });

    const nextState = (state) => {
      switch (state) {
        case 'modal_beta':
          return 'beta';
        case 'modal_join_list':
          return 'join_list';
        default:
          return state;
      }
    };

    useEffect(() => {
      setValue('state', nextState(store.profile.continue));
    }, []);

    const onSubmit = async (data) => {
      try {
        await store.api.post('/user/beta', {
          ...data,
          state: 'beta',
        });
        if (store.profile.scope == 'appsumo') {
          navigate('/my-profile/pricing');
        }
        store.refreshTokenAndProfile();
        window.userGuiding.previewGuide(108975);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    return (
      <div className="fixed bottom-0 top-[50px] md:top-['none'] inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="welcome-wrapper bg-white rounded-lg max-w-3xl p-9 h-[840px] shadow-xl transform transition-all overflow-auto">
          <div className="mb-[16px]">
            <OrangeLogo />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-[16px]"
          >
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                Welcome, {store.profile.fname + ' ' + store.profile.lname}!{' '}
                <br />
                Get ready to unlock the full potential of generative AI.
              </p>
            </div>
            <div>
              <p className="text-graphite-400 text-base font-barlow font-normal leading-5">
                Say goodbye to the overwhelm of juggling multiple tools.
                <strong className="font-bold text-graphite-300">
                  With Straico, everything you need is in one place.
                </strong>
                <br />
                <br />
                Explore our powerful AI-driven tools and take your productivity
                to new heights. Stay ahead of the competition and unleash your
                creativity with ease. Let's dive into{' '}
                <strong className="font-bold text-graphite-300">
                  a world of limitless possibilities
                </strong>{' '}
                together! 🚀
              </p>
            </div>
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                Now, we'd love to hear a bit about you.
              </p>
            </div>
            <div className="flex flex-col gap-[16px]">
              <div className="flex flex-col gap-[10px]">
                <p className="uppercase text-graphite-400 font-semibold font-lexend text-sm">
                  What do you do for a living? *
                </p>
                <SelectControlled
                  control={control}
                  name="kind_of_work"
                  options={[
                    {
                      id: 'marketer_client_side',
                      title: '📈 Marketer (client side)',
                    },
                    {
                      id: 'marketer_agency_freelancer',
                      title: '🚀 Marketer (agency, freelancer)',
                    },
                    {
                      id: 'sales_professional',
                      title: '💼 Sales professional',
                    },
                    {
                      id: 'product_manager',
                      title: '🧩 Product manager',
                    },
                    {
                      id: 'filmmaker_video_editor',
                      title: '🎬 Filmmaker, video editor',
                    },
                    {
                      id: 'hr_trainer',
                      title: '📚 HR, trainer',
                    },
                    {
                      id: 'customer_service_support',
                      title: '🗣️ Customer service or support',
                    },
                    {
                      id: 'teacher_educator',
                      title: '🍎 Teacher, educator',
                    },
                    {
                      id: 'designer',
                      title: '✏️ Designer',
                    },
                    {
                      id: 'hobbyist_personal_user',
                      title: '🌟 Hobbyist, personal user',
                    },
                    {
                      id: 'influencer_creator',
                      title: '📸 Influencer, creator',
                    },
                    {
                      id: 'developer',
                      title: '👨‍💻 Developer',
                    },
                    {
                      id: 'business_owner_general_management',
                      title: '🏢 Business owner, general management',
                    },
                    {
                      id: 'other',
                      title: '🔘 Other',
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <p className="uppercase text-graphite-400 font-semibold font-lexend text-sm">
                  How will you use Straico's awesome features? *
                </p>
                <SelectControlled
                  control={control}
                  name="primarily_use_pr"
                  options={[
                    {
                      id: 'work',
                      title: 'For work',
                    },
                    {
                      id: 'personal_use',
                      title: 'For personal use',
                    },
                    {
                      id: 'personal_business',
                      title: 'For my personal business/startup',
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                {/* <CheckboxControlled
                  name="agree_mailing_list"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label="Join our mailing list"
                /> */}
                <CheckboxControlled
                  name="age_checked"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label="Confirm that you’re older than 14"
                />
                <CheckboxControlled
                  name="agree_terms_and_conditions"
                  control={control}
                  formControlLabelProps={{
                    className: 'text-graphite-500 font-barlow',
                  }}
                  label={
                    <p>
                      Accept Straico’s{' '}
                      <a
                        href="https://straico.com/terms-and-conditions/"
                        target="__blank"
                        className="text-flax-900 underline"
                      >
                        terms and conditions
                      </a>
                    </p>
                  }
                />
              </div>
            </div>
            <div>
              <p className="text-eggplant-700 text-lg font-lexend font-medium leading-7">
                Thanks for joining the Straico community!
              </p>
            </div>
            <div className="flex justify-center md:justify-start items-center md:items-start">
              <Button
                type="submit"
                title={'Get Started Now!'}
                CustomIcon={
                  <img
                    src="/logo-symbol-snow-100.svg"
                    className="cursor-pointer duration-500 undefined h-6 w-6 ml-2.5 mt-2"
                    alt="logo symbol"
                  />
                }
              />
            </div>
          </form>
          {/* store.profile.continue === 'modal_beta' */}
          {/* {store.profile.continue === 'modal_beta' ? (
            <></>
          ) : (
            <>
              {store.profile.continue === 'modal_join_list' ? (
                <div className="content-welcome">
                  <div className="mb-24">
                    <div className="sm:flex">
                      <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-gray-300">
                        <RocketLaunchIcon
                          className="h-6 w-6 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-6">
                        <p>
                          Hello{' '}
                          <span className="font-bold">
                            {store.profile.fname + ' ' + store.profile.lname}
                          </span>
                        </p>
                        <p className="mb-4">
                          Thank you for joining us! We are excited to announce
                          that we will be going live on{' '}
                          <span className="font-bold">April 12th!</span>
                        </p>
                      </div>
                    </div>

                    <p className="mb-4">
                      Please note that our beta testing is now closed, but if
                      you join our waitlist, you will receive{' '}
                      <span className="font-bold">
                        {' '}
                        three months of our standard plan for free and a 20%
                        discount on any plan for one year
                      </span>{' '}
                      🙂
                    </p>

                    <p className="mb-4">
                      Thank you for your interest in our tool!
                    </p>
                  </div>

                  <div>
                    <div className="flex mb-4">
                      <div className="flex items-center h-5">
                        <input
                          id="agree_mailing_list"
                          type="checkbox"
                          value="agree_mailing_list"
                          name="agree_mailing_list"
                          className="w-4 h-4"
                        />
                      </div>
                      <div className="ml-2 text-sm">
                        <label htmlFor="agree_mailing_list">
                          I agree to join Straico’s mailing list
                        </label>
                      </div>
                    </div>
                    <Button title={'JOIN THE WAITLIST'} onClick={onJoinList} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="mb-24">
                    <p className="">
                      Hello{' '}
                      <span className="font-bold">
                        {store.profile.fname + ' ' + store.profile.lname}
                      </span>
                    </p>

                    <p className="mb-4">
                      Please note that our beta testing is now closed, you are
                      currently on our waitlist.
                    </p>

                    <p className="mb-4">
                      Thank you for your interest in our tool!
                    </p>
                  </div>
                  <Button
                    title={'LOG OUT'}
                    onClick={() => store.handleLogout()}
                  />
                </div>
              )}
            </>
          )} */}
        </div>
      </div>
    );
  })
);

export default Welcome;
