/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  XMarkIcon,
  GiftIcon,
  CurrencyDollarIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';

import { Button, IconButton } from '@mui/material';
import { TranslateContext } from '../../../Context/translate-context';
import HelpModal from '../../../Components/HelpModal';
import DiscordModal from 'Components/DiscordModal';
import ShortModal from 'Components/ShortModal';
import ToggleTheme from 'Components/Common/ToggleTheme';
import useThemeStore from 'Theme/store';

const SidebarComponent = ({ store, ...props }) => {
  const context = useContext(TranslateContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [openDialogHelp, setOpenDialogHelp] = useState(false);
  const [openDialogDiscord, setOpenDialogDiscord] = useState(false);
  const [openDialogShort, setOpenDialogShort] = useState(false);
  const [showDeal, setShowDeal] = useState(true);
  const [initialCoins, setInitialCoins] = useState(store.profile.credits);

  const [coinsMark, setCoinsMark] = useState({
    box: 'bg-vanilla-100 text-graphite-900 border border-vanilla-100 dark:bg-graphite-300 dark:text-snow-100 dark:border-graphite-300',
    icon: CurrencyDollarIcon,
    iconColor: 'text-flax-900',
  });

  const inputHintList = [
    { index: 0, value: undefined },
    { index: 1, value: undefined },
  ];

  const paramsToObject = (entries) => {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  const getImageProfile = () => store.profile.avatar.svg;

  const getPlaceholder = () => {
    let placeholder;
    if (location.pathname === '/history') {
      placeholder = context.translator(
        'sectionToolBar.placeholderHistorySearch'
      );
      store.labelSection = context.translator('sectionToolBar.labelHistory');
    } else {
      placeholder = context.translator('sectionToolBar.placeholderSearch');
      store.labelSection = context.translator('sectionToolBar.labelDashboard');
    }
    return placeholder;
  };

  const updateSearchParam = (search, paramName, paramValue) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(paramName, paramValue);
    return '?' + searchParams.toString();
  };

  const shortcutHandler = (e) => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      document.getElementById('q').focus();
      document.getElementById('q').select();
    }
  };

  const animateCoinsDown = async () => {
    setCoinsMark({
      box: 'bg-crayola-100 text-crayola-800 border border-crayola-500',
      icon: ArrowDownIcon,
      iconColor: 'text-crayola-500',
    });

    await sleep(800);

    setCoinsMark({
      box: 'bg-vanilla-100 text-graphite-900 border border-vanilla-100 dark:bg-graphite-300 dark:text-snow-100 dark:border-graphite-300',
      icon: CurrencyDollarIcon,
      iconColor: 'text-flax-900',
    });
  };

  const animateCoinsUp = async () => {
    setCoinsMark({
      box: 'bg-celadon-100 text-celadon-800 border border-celadon-500',
      icon: ArrowUpIcon,
      iconColor: 'text-celadon-500',
    });

    await sleep(800);

    setCoinsMark({
      box: 'bg-vanilla-100 text-graphite-900 border border-vanilla-100 dark:bg-graphite-300 dark:text-snow-100 dark:border-graphite-300',
      icon: CurrencyDollarIcon,
      iconColor: 'text-flax-900',
    });
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const ceroCoins = () => {
    setCoinsMark({
      box: 'bg-red-100 text-red-500 border border-red-100',
      icon: CurrencyDollarIcon,
      iconColor: 'text-red-500',
    });
  };

  const handleFilters = (value) => {
    const keys = ['category', 'title', 'tag', 'after', 'before', 'prompt'];
    const pattern = new RegExp(
      `(${keys.join('|')}):((\\d{4}-\\d{2}-\\d{2})|(\\w+))`,
      'g'
    );
    const result = {};
    let match;

    while ((match = pattern.exec(value)) !== null) {
      result[match[1]] = match[2];
    }

    result.keyword = value.replace(pattern, '').trim();
    navigate({
      pathname: location.pathname,
      search: '?' + new URLSearchParams(result).toString(),
    });
  };

  const onChangeToolsKeyword = (e) => {
    if (location.pathname === '/history') {
      store.toolsKeyword = e.target.value;
      handleFilters(e.target.value);
    } else {
      store.onChangeToolsKeyword(e);
    }
  };

  const onKeyUp = (e) => {
    if (location.pathname !== '/search' && location.pathname !== '/history') {
      navigate('/search');
    }
    if (e.keyCode === 8) {
      if (store.toolsKeyword === '' && location.pathname !== '/history') {
        navigate('/dashboard');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', shortcutHandler);

    return () => {
      document.removeEventListener('keydown', shortcutHandler);
    };
  }, []);

  useEffect(() => {
    if (initialCoins !== store.profile.credits) {
      let difference = store.profile.creditsPrevious - store.profile.credits;
      if (difference > 0) {
        animateCoinsDown();
      } else if (difference < 0) {
        animateCoinsUp();
      }
      setInitialCoins(store.profile.credits);
    }

    if (store.profile.credits <= 0) {
      ceroCoins();
    }
  }, [store.profile.credits]);

  useEffect(() => {
    if (location.pathname === '/chat') {
      store.labelSection = context.translator('sectionToolBar.labelChat');
    }
  }, [location.pathname, store.labelSection, context]);

  const fromColor = useMemo(() => {
    if (store.profile.credits <= 0) {
      return 'bg-red-200 text-red-600';
    }
    if (store.profile.status === 'trialing') {
      return '';
    }
    if (store.profile.status === 'active') {
      return '';
    }
    if (store.profile.status === 'incomplete') {
      return '';
    }
    return 'bg-red-200 text-red-600';
  }, [store.profile.credits, store.profile.status]);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialogHelp(false);
      setOpenDialogDiscord(false);
      setOpenDialogShort(false);
    }
  };
  const { theme: themeValue, isSidebarOpen } = useThemeStore();
  return (
    <>
      <Textarea
        readOnly
        name="copy-textarea"
        id="copy-textarea"
        value={store.copyToClipboardText}
      />

      <div className="bg-snow-100 dark:bg-graphite-800 sticky top-0 z-40 border-b border-graphite-100 dark:border-graphite-100 h-12">
        <div className="flex mx-auto px-1 md:px-1 select-none p-1">
          {!openDialogHelp && !isSidebarOpen && (
            <>
              <div className="items-center hidden md:block">
                <img
                  src={
                    themeValue == 'dark'
                      ? '/straico-snow-100.svg'
                      : '/straico.svg'
                  }
                  className={`cursor-pointer h-7 ml-3 mt-1`}
                />
              </div>
              <div
                onClick={() => {
                  setOpenDialogHelp(true);
                }}
                className="flex items-center md:hidden"
              >
                <img
                  src="/logo-symbol-crayola-600.svg"
                  className={`cursor-pointer w-8 lg:w-24 ml-3.5 mt-1`}
                />
              </div>
            </>
          )}

          <div className="flex mr-2 justify-end absolute right-0">
            <button
              className="flex md:hidden items-center justify-center px-1 rounded-md hover:bg-vanilla-100 text-graphite-100 text-sm"
              onClick={() => setOpenDialogShort(true)}
            >
              <VideoCameraIcon
                className={`w-5 h-5 mx-1 ${
                  themeValue == 'dark'
                    ? 'dark:text-snow-100'
                    : 'text-graphite-100'
                }  `}
              />
            </button>
            <div className="flex mr-2">
              <div className="hidden md:flex">
                <ToggleTheme />
                <Button
                  onClick={() => setOpenDialogShort(true)}
                  variant="text"
                  startIcon={
                    <VideoCameraIcon
                      className={`w-5 h-5 mx-1 ${
                        themeValue == 'dark'
                          ? 'dark:text-white'
                          : 'text-eggplant-700'
                      }`}
                    />
                  }
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <span
                    className={`${
                      themeValue == 'dark'
                        ? 'dark:text-white'
                        : 'text-eggplant-700'
                    } font-lexend`}
                  >
                    Watch Demo
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex mr-2">
              <button
                onClick={() => {
                  setOpenDialogDiscord(true);
                }}
                className="flex items-center justify-center px-1 rounded-md hover:bg-vanilla-100 dark:hover:bg-graphite-600 text-graphite-100 text-sm"
              >
                <img
                  className="h-4 px-1 block"
                  src={
                    themeValue == 'dark'
                      ? '/discord-mark-white.svg'
                      : '/discord-mark-blue.svg'
                  }
                />
              </button>
            </div>

            <div className="flex mr-2">
              <a
                href="https://straico.com/faq/"
                target="_blank"
                rel="noreferrer"
                className="flex "
              >
                <button
                  onClick={() => {
                    // setOpenDialogHelp(true);
                  }}
                  className={`flex items-center justify-center px-1 rounded-md hover:bg-vanilla-100 ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-100'
                  }  text-sm dark:hover:bg-graphite-600`}
                >
                  <QuestionMarkCircleIcon className="w-5 h-5 mx-1" />
                </button>
              </a>
            </div>

            <div className="flex py-1">
              <div
                className={`items-center flex ${coinsMark.box} font-barlow font-semibold text-base rounded-md px-1 mr-[2px] lg:mr-2 tracking-wide `}
              >
                <coinsMark.icon
                  className={`w-6 h-6 ${coinsMark.iconColor} mr-0.5`}
                />
                {store.profile.credits.toLocaleString('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </div>

            <NavLink
              to="/my-profile"
              exact
              tabIndex="-1"
              activeClassName="bg-vanilla-200 hover:bg-vanilla-100 text-white transition"
              className={`flex px-1 lg:px-2 cursor-pointer ${fromColor} hover:bg-vanilla-100 text-graphite-200 rounded-md transition items-center dark:hover:bg-graphite-600`}
            >
              {store.profile.avatar.svg ? (
                <div className="avatar">
                  <div className="w-10 rounded">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getImageProfile(),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <UserCircleIcon
                  className={`w-7 h-7 transition ${
                    themeValue == 'dark'
                      ? 'dark:text-graphite-200'
                      : 'text-graphite-400'
                  }`}
                />
              )}
            </NavLink>
          </div>
        </div>
      </div>

      {openDialogHelp ? (
        <HelpModal onInteraction={onInteraction}></HelpModal>
      ) : (
        <></>
      )}

      {openDialogDiscord ? (
        <DiscordModal onInteraction={onInteraction}></DiscordModal>
      ) : (
        <></>
      )}

      {openDialogShort && <ShortModal onInteraction={onInteraction} />}

      {props.children}

      {showDeal &&
      store.profile.sale5000 &&
      store.profile.scope == 'general' ? (
        <div
          className="w-80 absolute bottom-0 right-0 cursor-pointer mb-6 mr-4 z-40 p-3 bg-snow-100 hover:bg-flax-100 rounded-md hover:shadow-md text-graphite-500 border border-graphite-100 hover:text-graphite-400 shadow-md font-barlow text-center"
          onClick={() => {
            navigate('/my-profile/pricing');
          }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowDeal(false);
            }}
            className="absolute right-1 top-2 p-1 hover:bg-flax-500 text-graphite-100 text-sm rounded-md z-50"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
          <div className="mt-4 mb-4 text-graphite-400 flex">
            <div className="m-auto">
              <GiftIcon className="w-8 h-8 md:w-16 md:h-16" />
            </div>
          </div>
          <div className="text-crayola-700 block text-lg leading-tight font-bold mb-2 font-lexend uppercase mt-2">
            Exclusive Limited-Time Offer!
          </div>
          <div className="text-4xl text-graphite-400 font-bold">
            5,000 coins
          </div>
          <div className="text-base text-graphite-400 uppercase font-bold">
            for $5.00{' '}
            <span className="bg-flax-700 text-eggplant-700 px-1.5 rounded-sm">
              54% off
            </span>
          </div>
          <p className="my-2">Grab this exclusive deal now!</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

export default inject('store')(observer(SidebarComponent));
