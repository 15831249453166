import React, { useEffect, useState } from 'react';
import * as HIcons from '@heroicons/react/24/outline';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import ShortModal from './ShortModal';
import useThemeStore from 'Theme/store';

const Subheader = ({ title, description, loading, icon }) => {
  const { ...icons } = HIcons;
  const TheIcon = icons[icon];

  const [openDialog, setOpenDialog] = useState(false);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialog(false);
    }
  };

  const { theme: themeValue } = useThemeStore();

  return (
    <>
      <div className="bg-snow-300 dark:bg-graphite-800 pt-1 pb-1 border-b border-graphite-100 relative">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-1">
          {/* <button
            onClick={() => {
              setOpenDialog(true);
            }}
            className="flex items-center justify-center px-1 rounded-md bg-crayola-700 hover:bg-crayola-200 text-white text-sm pt-1.5 pb-1"
          >
            <VideoCameraIcon className="w-5 h-5 mx-1" />
            <span className="text-sm font-barlow font-normal mr-1 hidden lg:block">
              Tutorial
            </span>
          </button> */}
        </div>

        <div className="container px-4 flex items-center">
          <div className="flex">
            <div className="mr-2 mt-1">
              {icon ? (
                <TheIcon className="h-5 w-5 px-[1px] py-[2px] text-eggplant-700 bg-vanilla-400 dark:bg-flax-700 rounded-md"></TheIcon>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className="flex">
                <h2 className="font-lexend font-bold text-lg flex align-center uppercase text-eggplant-700 dark:text-celadon-700">
                  {title}
                </h2>
              </div>
              {description ? (
                <p
                  className={`text-base ${
                    themeValue == 'dark'
                      ? 'dark:text-white'
                      : 'text-graphite-400'
                  }   font-barlow md:text-md lg:text-lg mt-1 lg:mx-0`}
                >
                  {description}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="w-full -mt-[10px] -mb-[0.42rem]">
          <progress className="progress progress-accent -mt-8"></progress>
        </div>
      ) : (
        <div className="w-full -mb-[0.42rem]">
          <div className="flex h-[14px]"></div>
        </div>
      )}

      {openDialog ? (
        <ShortModal onInteraction={onInteraction}></ShortModal>
      ) : (
        <></>
      )}
    </>
  );
};

export default Subheader;
