import { useState } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import { useFoldersChatStore } from 'Components/Chat/DragAndDropFolders/store';
import useChatStore from 'Components/Chat/store';
import Modal from 'Components/Common/Modal/Modal';
import useThemeStore from 'Theme/store';
// Icons
import { PencilIcon } from '@heroicons/react/24/outline';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';

const EditTitle = inject('store')(
  observer(({ store, chat, onInteraction }) => {
    const [success, setSuccess] = useState(false);

    const [fTitle, setFTitle] = useState(chat.title);

    const { theme } = useThemeStore();

    // Chat manage STATE
    const { selectedChat } = useChatStore();
    const { treeData, setTreeData } = useTreeDNDChatStore();

    const edit = async () => {
      try {
        await store.api
          .post('/chat/edit', {
            hash: chat._id,
            title: fTitle,
          })
          .then(async () => {
            setSuccess(true);
            const chat = selectedChat.chat;

            // Update tree data
            const oldData = treeData.filter((item) => item.id !== chat._id);
            const selectedChatTree = treeData.find(
              (item) => item.id === chat._id
            );
            setTreeData([...oldData, { ...selectedChatTree, text: fTitle }]);

            await sleep(2000);
            onInteraction({ title: fTitle });
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    return (
      <Modal
        open={true}
        title="EDIT TITLE"
        iconTitle={
          <PencilIcon
            className="h-5 w-5 text-eggplant-700"
            aria-hidden="true"
          />
        }
        onClose={() => onInteraction('close')}
        onConfirm={() => edit()}
        buttonConfirmProps={{
          disabled: fTitle == undefined || fTitle == '' || success,
        }}
        onConfirmBtnText="Update"
      >
        <div className="my-4">
          <div className="flex flex-col flex-1 mt-2">
            <input
              value={fTitle}
              key="1"
              onChange={(e) => setFTitle(e.target.value)}
              type="text"
              className={`w-full border border-snow-900 ${
                theme == 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white'
                  : 'bg-white text-graphite-900'
              }  border-solid p-[8px] font-barlow rounded-md `}
              placeholder="Title"
            />
          </div>

          {success ? (
            <div className="text-green-600 bg-green-50 rounded-md p-1 text-center mt-4">
              Information updated
            </div>
          ) : null}
        </div>
      </Modal>
    );
  })
);
export default EditTitle;
