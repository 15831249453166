import React, { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/Modal';
// Store
import { PencilIcon } from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import { useUpdateFolderChatModal } from './store';

const UpdateModalFolderChat = inject('store')(
  observer(({ store }) => {
    const [editFolderName, setEditFolderName] = React.useState('');

    const { treeData, setTreeData } = useTreeDNDChatStore();

    const onEditModalFolderChat = async () => {
      try {
        const res = await store.api.put(
          `/folder/chatFolders/${selectedFolder.id}`,
          {
            name: editFolderName,
          }
        );
        if (res.status === 200) {
          const data = treeData.map((item) => {
            if (item.id === selectedFolder.id) {
              item.text = editFolderName;
            }
            return item;
          });
          setTreeData(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        onCloseModal();
      }
    };

    const { isModalOpen, setModalOpen, selectedFolder } =
      useUpdateFolderChatModal();
    const onCloseModal = () => setModalOpen(false);

    useEffect(() => {
      if (selectedFolder) {
        setEditFolderName(selectedFolder?.text ?? '');
      }
    }, [isModalOpen]);

    const { theme } = useThemeStore();

    return (
      <Modal
        onClose={onCloseModal}
        open={isModalOpen}
        title="EDIT CHAT FOLDER"
        iconTitle={
          <PencilIcon
            className="h-5 w-5 text-eggplant-700"
            aria-hidden="true"
          />
        }
        onConfirm={onEditModalFolderChat}
        buttonConfirmProps={{
          disabled: editFolderName === selectedFolder?.text,
        }}
        onConfirmBtnText="Update"
      >
        <>
          <div className="flex flex-col gap-2.5 my-4">
            <div className="flex flex-col gap-2">
              <label className="uppercase text-[12px] font-medium">Name</label>
              <input
                type="text"
                className={cn(
                  'w-full border border-snow-900 border-solid p-[8px] font-barlow rounded-md bg-white',
                  theme === 'dark' && 'bg-graphite-900 text-white',
                  theme === 'light' && ' text-graphite-900'
                )}
                placeholder="Edit the folder name"
                value={editFolderName}
                onChange={(e) => setEditFolderName(e.target.value)}
              />
            </div>
          </div>
        </>
      </Modal>
    );
  })
);

export default UpdateModalFolderChat;
