import { observer, inject } from 'mobx-react';
import { UserCircleIcon, BellAlertIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileProgress = inject('store')(
  observer(({ store, open, onOpen }) => {
    const {
      profile: { avatar },
    } = store;
    //
    const caracteristicsCompleted = useMemo(() => {
      return (
        Object.values(avatar?.features)?.filter((f) => f !== null)?.length | 0
      );
    }, [avatar]);
    // eslint-disable-next-line no-unused-vars
    const caracteristicsDontCompleted = useMemo(() => {
      return (
        Object.values(avatar?.features)?.filter((f) => f === null)?.length | 0
      );
    }, [avatar]);
    const getPercentCompleted = useMemo(() => {
      return (
        (caracteristicsCompleted / Object.values(avatar?.features)?.length) *
        100
      );
    }, [avatar, caracteristicsCompleted]);
    // Redirect function
    const navigate = useNavigate();
    const onComplete = () => navigate('/my-avatar');
    const onClickBell = () => onOpen(!open);

    return (
      <>
        {Object.values(avatar?.features).filter((i) => i === null).length >
          0 && (
          <>
            {open ? (
              <div className="card bg-snow-100 dark:bg-graphite-900 shadow-xl mt-4 mx-4">
                <div className="stat">
                  <div className="stat-figure text-secondary">
                    <div className="avatar">
                      <div className="rounded-full">
                        {avatar?.svg ? (
                          <div className="avatar">
                            <div className="w-12 rounded">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: avatar?.svg,
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <UserCircleIcon
                            className={`w-8 h-8 transition text-celadon-900`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="stat-value font-barlow text-graphite-400 dark:text-snow-100">{`${getPercentCompleted.toFixed(
                    0
                  )}%`}</div>
                  <div className="font-lexend uppercase text-eggplant-700 dark:text-eggplant-300 font-bold">
                    Avatar Ace
                  </div>
                  <div className="font-barlow text-sm text-celadon-900">
                    1 task remaining
                  </div>
                </div>
                <div className="divider divide-snow-900 -my-4 mx-4" />
                <div className="stat">
                  <div className="text-sm text-center text-graphite-400 dark:text-snow-100 font-barlow">
                    Earn 90 coins and personalize your avatar to reflect your
                    unique style.
                  </div>
                </div>
                <button
                  onClick={onComplete}
                  className="btn btn-sm self-center mb-3 bg-crayola-600 hover:bg-celadon-900 border-none font-lexend uppercase font-bold text-white"
                >
                  complete
                </button>
              </div>
            ) : (
              <div
                onClick={onClickBell}
                className="mt-4 w-full cursor-pointer flex items-center justify-center animate-bounce"
              >
                <BellAlertIcon className="w-6 h-6 transition self-center text-crayola-500 ml-0.5" />
              </div>
            )}
          </>
        )}
      </>
    );
  })
);

export default ProfileProgress;
