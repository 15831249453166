import { useMemo, useState } from 'react';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// Icons
import {
  DocumentTextIcon,
  EyeIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
// RHF
import { useFormContext } from 'react-hook-form';
// Components
import FileContext from 'Components/FileContext';
import useThemeStore from 'Theme/store';
import useTemplateModalStore from '../../store';

const AttachmentsSection = () => {
  // Context
  const {
    selectedTool, // Template base info
    selectedPrompt, // Template variables and prompt
  } = useChat();

  const { setValue, watch } = useFormContext();
  const { models } = useTemplateModalStore();

  const selectedModel = useMemo(
    () =>
      models.filter((model) => model._id === selectedTool?.default_model?.id),
    [selectedTool?.default_model, models]
  );
  const [open, setOpen] = useState(false);
  const [textAttr, setTextAttr] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  const handleBadInteraction = () => {};
  const handleInteraction = (e) => {
    const file = e[0];

    let content = {};
    // validate if file is a object
    if (typeof file !== 'object') {
      let type = '';
      const youtubeUrlPattern =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;
      if (youtubeUrlPattern.test(e)) {
        type = 'youtube';
      } else {
        type = 'webpage';
      }

      content = {
        name: file,
        url: file,
        type,
      };
    } else {
      content = file;
    }

    setValue('files', {
      ...watch('files'),
      [textAttr]: content,
    });
    toggleOpen();
  };

  const handleOpen = (attr) => () => {
    setOpen(true);
    setTextAttr(attr);
  };

  const handleRemove = (attr) => () => {
    //delete the file to undefined
    setValue('files', {
      ...watch('files'),
      [attr]: undefined,
    });
  };

  const handleSeeFile = (file) => () => {
    window.open(file?.url ?? file, '_blank');
  };

  const { theme } = useThemeStore();

  return (
    <div>
      <div className="my-2">
        {selectedTool?.files?.length > 0 && (
          <h1 className="uppercase font-barlow mb-2 text-sm font-bold text-graphite-200 dark:text-snow-800 dark:text-whitedark:text-white">
            Fixed Attachments
          </h1>
        )}
        {selectedTool?.files?.map((file) => (
          <div
            key={file?._id}
            className="w-fit border border-[#D4D6D0] rounded flex items-center gap-2 justify-between p-[4px] font-barlow"
          >
            <DocumentTextIcon className="w-5 h-5 text-graphite-100" />
            <p
              className={`truncate w-full mt-0 ${
                theme == 'dark' ? 'dark:text-white' : 'text-graphite-100'
              }`}
            >
              {file?.name}
            </p>
            <div className="flex gap-2 items-center">
              {file?.url && (
                <EyeIcon
                  className={`w-4 h-4 cursor-pointer ${
                    theme == 'dark' ? 'dark:text-white' : 'text-graphite-100'
                  }`}
                  onClick={handleSeeFile(file)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {selectedPrompt?.prompts?.filter((i) =>
        ['image', 'attachment'].includes(i.type)
      ).length > 0 && (
        <h1 className="uppercase font-barlow mb-2 text-sm font-bold text-graphite-200 dark:text-snow-800">
          variable Attachments
        </h1>
      )}
      {selectedPrompt?.prompts
        ?.filter((i) => ['image', 'attachment'].includes(i.type))
        ?.map((prompt, index) => {
          return (
            <div className="mb-4" key={index}>
              <div
                className={`uppercase font-barlow mb-2 text-sm font-bold ${
                  theme == 'dark' ? 'dark:text-white' : 'text-graphite-300'
                }`}
              >
                {prompt?.title}
              </div>
              {(watch('files') ?? {})[prompt.attr] ? (
                <>
                  <div className="w-fit border border-[#D4D6D0] rounded flex items-center gap-2 justify-between p-[4px] font-barlow">
                    <DocumentTextIcon className="w-5 h-5 text-graphite-100" />
                    <p
                      className={`truncate w-full mt-0 ${
                        theme == 'dark'
                          ? 'dark:text-white'
                          : 'text-graphite-100'
                      }`}
                    >
                      {(watch('files') ?? {})[prompt.attr]?.name ??
                        (watch('files') ?? {})[prompt.attr]}
                    </p>
                    <div className="flex gap-2 items-center">
                      {(watch('files') ?? {})[prompt.attr]?.url && (
                        <EyeIcon
                          className={`w-4 h-4 cursor-pointer ${
                            theme == 'dark'
                              ? 'dark:text-white'
                              : 'text-graphite-100'
                          }`}
                          onClick={handleSeeFile(
                            (watch('files') ?? {})[prompt.attr]
                          )}
                        />
                      )}
                      <XMarkIcon
                        className={`w-4 h-4 cursor-pointer ${
                          theme == 'dark'
                            ? 'dark:text-white'
                            : 'text-graphite-100'
                        }`}
                        onClick={handleRemove(prompt.attr)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  onClick={handleOpen(prompt.attr)}
                  className={`cursor-pointer hover:bg-snow-700  dark:hover:bg-graphite-800 flex items-center gap-2 w-full border border-dashed border-snow-900 p-[8px] font-barlow rounded-md  dark:text-whitedark:text-whitefocus:border-flax-800  ${
                    theme == 'dark'
                      ? 'dark:bg-graphite-700 dark:text-white'
                      : 'bg-snow-500 text-graphite-200'
                  }`}
                >
                  <PlusIcon
                    className={`w-4 h-4 cursor-pointer ${
                      theme == 'dark' ? 'dark:text-white' : 'text-graphite-100'
                    }`}
                  />
                  <p>Add {prompt.type} here</p>
                </div>
              )}
            </div>
          );
        })}
      {open && (
        <FileContext
          models={selectedModel}
          onInteraction={handleInteraction}
          onClose={toggleOpen}
          onBadInteraction={handleBadInteraction}
        />
      )}
    </div>
  );
};

export default AttachmentsSection;
