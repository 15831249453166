import { createTheme } from '@mui/material';

export const themeOptions = (mode = 'light') => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#422040',
          },
          secondary: {
            main: '#E57A44',
          },
          toogle: {
            main: '#B5BA6E',
          },
          text: {
            primary: '#211E21',
            secondary: '#A1A1A0',
            disabled: '#A1A1A0',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#DDD279',
          },
          secondary: {
            main: '#E57A44',
          },
          toogle: {
            main: '#B5BA6E',
          },
          background: {
            default: '#121212',
            paper: '#141114',
          },
          text: {
            primary: '#fff',
            secondary: '#A1A1A0',
            disabled: '#A1A1A0',
          },
        }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          fontFamily: '"Barlow"',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: 'lexend, sans-serif',

          color:
            ownerState.variant === 'contained'
              ? ownerState.color === 'inherit'
                ? '#A1A1A0 '
                : '#fff'
              : ownerState.color,
          textTransform: 'uppercase',
          fontWeight: 'bold',
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: 'rgb(222 105 52 / 80%)',
          },
        }),
      },
    },
    typography: {
      fontFamily: ['Barlow', 'sans-serif'].join(','),
    },
  },
});

export const customTheme = createTheme(themeOptions);
