import { useMemo, useEffect } from 'react';
// Styled
import { ThemeProvider } from 'styled-components';
// Mobx
import { Provider } from 'mobx-react';
// Store
import AppStore from './store';
// Tailwind
import colors from 'tailwindcss/colors';
// Router
import { RouterProvider } from 'react-router-dom';
// Mui
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { themeOptions } from './Theme/mui';
import { createTheme } from '@mui/material';
// Styles
import './Theme/global.css';
// Router config
import { router } from 'routes.config';
// Error boundary
import TreeDNDChatProvider from 'Components/Chat/TreeDNDChat/Components/TreeDNDChatProvider';
// Theme store
import useThemeStore from 'Theme/store';
// Mobx store init
if (!window.store) {
  window.store = new AppStore();
}

const MUITheme = ({ children }) => {
  const { theme } = useThemeStore();
  const customTheme = useMemo(() => createTheme(themeOptions(theme)), [theme]);

  return <MuiThemeProvider theme={customTheme}>{children}</MuiThemeProvider>;
};

const App = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      const toastDivs = document.querySelectorAll(
        '.toast.toast-top.toast-end.mt-14.items-end'
      );
      toastDivs.forEach((div) => {
        div.style.pointerEvents = 'none';
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <TreeDNDChatProvider>
      <Provider store={window.store}>
        <MUITheme>
          <ThemeProvider theme={colors}>
            <div className="bg-snow-100 dark:bg-graphite-800">
              <a
                href="https://straico-uqhq.updates.userguiding.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="absolute bottom-0 right-2 text-xs text-graphite-900 dark:text-snow-100 font-barlow z-50 underline">
                  v1.42.2
                </p>
              </a>
              <RouterProvider router={router} />
            </div>
          </ThemeProvider>
        </MUITheme>
      </Provider>
    </TreeDNDChatProvider>
  );
};

export default App;
