import { useEffect, useState } from 'react';
// react-router-dom
import { useParams } from 'react-router-dom';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import usePublicLayoutStore from 'Layouts/PublicLayout/State';
// MUI
import { useMediaQuery } from '@mui/material';
// Icons
import ToolSvg from 'Theme/icons/Tool.svg';
// SEO
import { Helmet } from 'react-helmet';
// Components
import Loader from 'Components/Loader';
import Subheader from 'Components/Subheader';
import PromptSide from 'Layouts/PublicLayout/Components/PromptSide';
import ModalRegister from 'Layouts/PublicLayout/Components/ModalRegister';
import usePublicPromptStore from 'Layouts/PublicLayout/State/prompts';
import TemplateModalShare from 'Layouts/PublicLayout/Components/TemplateModalShare';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const ShareToolPage = inject('store')(
  observer(({ store }) => {
    const { id } = useParams();
    // State
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    //  Global state management
    const { setTools, setDefaultValues } = usePublicPromptStore();

    // Get share chat
    const onGetShareTool = async (id) => {
      setLoading(true);
      try {
        const res = await store.api.get(`/shareTools/${id}`);
        const data = await res.data;
        setData(data);
        setTools([data?.tool_id]);
        setDefaultValues(data?.values);
      } catch (err) {
        setError(true);
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (id) {
        onGetShareTool(id);
      }
    }, [id]);

    // Global state management
    const {
      registerModal,
      closeRegisterModal,
      showPromptSidebar,
      togglePromptSidebar,
    } = usePublicLayoutStore();

    // media querys
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Continue this conversation
    const setRedirectUrl = () => {
      sessionStorage.setItem(
        'toolClone',
        JSON.stringify({
          toolClone: data?.tool_id?._id,
          toolIsPublic: data?.tool_id?.public,
        })
      );
    };

    const { theme } = useThemeStore();

    // Error handling screen
    if (error)
      return (
        <div className="h-screen w-full dark:bg-graphite-900 flex justify-center items-center">
          <div className="text-center">
            <h1 className="text-eggplant-600 dark:text-flax-600 text-3xl font-barlow font-bold">
              Oops, Trouble Accessing Shared Content!
            </h1>
            <p className="text-eggplant-600 dark:text-flax-600 text-xl font-barlow">
              This shared tool seems to be currently unavailable. For the best
              experience, we recommend reaching out to the original creator to
              request a new share link.
            </p>
          </div>
        </div>
      );

    return (
      <>
        {/* SEO */}
        <Helmet>
          <title>{`Shared template - ${data?.tool_id?.title}`}</title>
        </Helmet>
        {/* Screen content */}
        {loading ? (
          <div className="h-screen w-full flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <Subheader
              title={`Shared template • ${data?.tool_id?.title}`}
              icon="ChatBubbleLeftRightIcon"
            />
            <div className="relative h-[84.5dvh] md:h-[90.5dvh] flex flex-col">
              <div className="flex h-[100%] md:h-[100%]">
                <div
                  className={`flex flex-col h-full w-[${
                    showPromptSidebar ? (isMobile ? 100 : 70) : 100
                  }%] relative justify-center items-center`}
                >
                  <div
                    className={cn(
                      'flex max-w-[600px] mx-auto p-4  border rounded-sm',
                      {
                        'bg-[#F6F7F5] border-eggplant-900': theme == 'light',
                        'bg-[#141114] border-white': theme == 'dark',
                      }
                    )}
                  >
                    <TemplateModalShare />
                  </div>
                </div>
                {!showPromptSidebar && (
                  <div
                    className="z-10 fixed top-[40px] md:top-20 right-0 flex flex-col "
                    onClick={togglePromptSidebar}
                  >
                    <div className="mr-2.5 mt-3 mb-2 px-2 py-2 transition rounded-md transform text-white cursor-pointer bg-flax-700">
                      <div className="flex">
                        <div className="flex items-center justify-center m-auto">
                          <ToolSvg className="h-6 w-6 cursor-pointer text-eggplant-700" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    showPromptSidebar ? 'flex' : 'hidden'
                  } flex-col md:w-[${showPromptSidebar ? 30 : 0}%]`}
                >
                  <div
                    className={`flex flex-col h-full ${
                      theme == 'light' ? 'bg-[#F0F1EE]' : 'bg-transparent'
                    }`}
                  >
                    <PromptSide />
                  </div>
                </div>
              </div>
            </div>
            <ModalRegister
              toolText
              isLoggedIn={store.isLoggedIn}
              open={registerModal}
              onClose={closeRegisterModal}
              setRedirectUrl={setRedirectUrl}
              url={`/chat`}
            />
          </>
        )}
      </>
    );
  })
);

export default ShareToolPage;
