export function groupByCategory(data) {
  return data?.reduce((result, item) => {
    const { category } = item;

    if (!result[category]) {
      result[category] = [];
    }

    result[category].push(item);
    return result;
  }, {});
}
