import toast from 'react-hot-toast';

export const toastCall = (message, options) => {
  toast.custom((t) => message(t), {
    ...options,
  });
};

const CustomRewardAlert = ({ t, title, message, linkText, linkUrl, image }) => {
  return (
    <div
      className={`${t.visible ? 'animate-enter' : 'animate-leave'} ${
        !t.visible && 'hidden'
      } max-w-md w-full bg-snow-100 shadow-md rounded-md pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <img className="h-10 w-10 rounded-full" src={image} alt="dog gif" />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-base font-bold font-lexend uppercase text-eggplant-700">
              {title}
            </p>
            <p className="mt-1 text-sm font-barlow text-graphite-400">
              {message}
            </p>
            <a
              href={linkUrl}
              className="text-flax-900 text-sm font-barlow cursor-pointer underline"
            >
              {linkText}
            </a>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => {
            toast.dismiss(t.id);
          }}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-crayola-600 hover:text-celadon-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 font-barlow uppercase"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const toastReward = ({
  title,
  message,
  linkText,
  linkUrl,
  image,
  time,
}) => {
  toastCall(
    (t) => (
      <CustomRewardAlert
        t={t}
        title={title}
        message={message}
        linkText={linkText}
        linkUrl={linkUrl}
        image={image}
      />
    ),
    {
      duration: time,
      position: 'top-right',
    }
  );
};
