/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { memo } from 'react';
// Plugin's
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
// Components
import useThemeStore from 'Theme/store';
import toast from 'react-hot-toast';
import { HighlightedText } from './HighlightedText';
import { MemoizedReactMarkdown } from './Markdown';
import { MarkdownComponents } from './Markdown.components';
// Icons
import { ArrowDownTrayIcon, HeartIcon } from '@heroicons/react/24/solid';
import useChatStore from 'Components/Chat/store';
import styled from 'styled-components';

const ContentMessage = memo(
  ({ message, userType, onFavoriteFile, onOpenFull }) => {
    const selectedChat = useChatStore((state) => state.selectedChat);

    const { theme } = useThemeStore();

    const getMessage = (message) => {
      if (typeof message == 'string') {
        return processing(message);
      }

      if (message.length === 1 && message[0].type === 'text') {
        return processing(message[0].text);
      }

      const parseMessage = message.map((frg, index) => {
        switch (frg.type) {
          case 'image_url':
            return (
              <div
                key={index}
                className={`max-h-80 w-full flex justify-center items-center`}
              >
                <div
                  className={`max-h-80 h-fit cursor-pointer relative ${
                    !frg.loading ? 'w-fit' : 'w-full'
                  }`}
                  onClick={() => onOpenFull(getImgTag(frg, true))}
                >
                  {frg.loading ? (
                    <div
                      className={`rounded w-full h-80 flex justify-center items-center  ${
                        theme == 'dark' ? 'bg-graphite-500' : 'bg-celadon-100'
                      }`}
                    >
                      <img
                        className="h-9"
                        key={Math.random()}
                        src="/straico-loading.gif"
                        alt="img ref"
                      />
                    </div>
                  ) : (
                    getImgTag(frg)
                  )}
                  {frg.no_actions || userType == 'user' ? (
                    ''
                  ) : (
                    <div className="absolute p-2 bottom-0 right-0 rounded-b flex gap-2 w-full justify-end bg-gradient-to-t from-graphite-900 to-transparent">
                      <Shortcut className="relative">
                        <ArrowDownTrayIcon
                          onClick={(e) => handleDownload(e, frg.image_url.url)}
                          className="w-5 h-5 font-bold text-white cursor-pointer"
                        />
                        <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                          <span className="relative z-50 p-2 text-base leading-none text-graphite-900 bg-white bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                            Download image
                          </span>
                        </Tooltip>
                      </Shortcut>
                      {onFavoriteFile && (
                        <Shortcut className="relative">
                          <HeartIcon
                            id={'favorite' + frg._id}
                            onClick={(e) => handleFavorite(e, frg)}
                            className={`w-5 h-5 font-bold ${
                              frg.favorite ? 'text-crayola-900' : 'text-white'
                            } cursor-pointer`}
                          />
                          <Tooltip className="absolute font-medium flex flex-col items-center group-hover:flex right-full -top-full">
                            <span className="relative z-50 p-2 text-base leading-none text-graphite-900 bg-white bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Add image to your attachments
                            </span>
                          </Tooltip>
                        </Shortcut>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          default:
            return processing(frg.text);
        }
      });
      return parseMessage;
    };

    const getImgTag = (frg, maxScreen = false) => {
      return (
        <img
          className={`max-h-${
            maxScreen ? '[calc(100vh_-_2rem)]' : '80'
          } rounded`}
          key={Math.random()}
          src={frg.image_url.url}
          alt="img ref"
        />
      );
    };

    const handleDownload = (e, url) => {
      e.stopPropagation();
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg';
      link.target = '_blanck';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleFavorite = (e, item) => {
      e.stopPropagation();
      if (!item.favorite) {
        let url = item.image_url.url;
        onFavoriteFile({
          url: url,
          name: url.substring(url.lastIndexOf('/') + 1),
          size: null,
          enabled: true,
          type: 'image',
          imageId: item._id,
          chatId: selectedChat.chat._id,
        });

        const heartIcon = document.getElementById('favorite' + item._id);
        if (heartIcon) {
          heartIcon.classList.remove('text-white');
          heartIcon.classList.add('text-crayola-900');
          item.favorite = !item.favorite;
        }
      } else {
        toast.error('The file is currently in your attachments.');
      }
    };

    const customStylesRegex = /""'\s*([^']*)\s*'""/g;

    // Regex to clean the old structure of template message
    const regex_1 = /```"'(.*?)'"```/g;
    const regex_2 = /```""'(.*?)'""```/g;

    const processing = (text) => {
      let formattedMessage = text
        .replace(regex_1, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        })
        .replace(regex_2, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        });

      if (userType === 'user') {
        formattedMessage = formattedMessage.replaceAll(
          customStylesRegex,
          (match) => {
            return `<span id='template'>${match
              .replaceAll('"', '')
              .replaceAll("'", '')}</span>`;
          }
        );
      }
      return formattedMessage;
    };

    const renderMessageContent = (message) => {
      if (!message) {
        return;
      }
      const messageContent = getMessage(message);

      // Chequear si el contenido es un string
      if (typeof messageContent === 'string') {
        return (
          <MemoizedReactMarkdown
            className="prose break-words dark:prose-invert prose-p:leading-relaxed markdown prose-pre:p-0 text-base"
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeRaw]}
            components={MarkdownComponents}
          >
            {messageContent}
          </MemoizedReactMarkdown>
        );
      } else {
        // Si no es un string, asumir que es JSX y retornarlo directamente
        return (
          <div
            className={`py-2 grid gap-2 justify-items-center ${
              messageContent.length > 1 && 'grid-cols-2'
            }`}
          >
            {messageContent}
          </div>
        );
      }
    };

    return (
      <>
        {userType === 'user' ? (
          <HighlightedText text={getMessage(message)} />
        ) : (
          renderMessageContent(message)
        )}
      </>
    );
  }
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

export default ContentMessage;
