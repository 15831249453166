import { observer, inject } from 'mobx-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  UserCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChatBubbleLeftRightIcon,
  CreditCardIcon,
  PhotoIcon,
  ArrowsRightLeftIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import Welcome from '../../Login/Welcome';
import Toast from '../../../Components/Toast';
import { useState, useEffect } from 'react';
import ProfileProgress from '../../../Components/ProfileProgress';
import ModalMobileUG from 'Components/ModalMobileUG';
import useThemeStore from 'Theme/store';
import ToggleTheme from 'Components/Common/ToggleTheme';

const Sidebar = inject('store')(
  observer((props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setSidebarOpen } = useThemeStore();

    // eslint-disable-next-line no-unused-vars
    const [width, setWidth] = useState(window.innerWidth);
    // eslint-disable-next-line no-unused-vars
    const [visibleStableStudio, setVisibleStableStudio] = useState(true);

    const { theme: themeValue } = useThemeStore();

    useEffect(() => {
      setSidebarOpen(props.open);
    }, [props.open]);
    // Toast manager
    const [random, setRandom] = useState(null);
    const [toastColorBox, setToastColorBox] = useState('green-100');
    const [toastColorText, setToastColorText] = useState('green-900');
    const [queue, setQueue] = useState([]);

    const [showModalTutos, setShowModalTutos] = useState(false);

    // Connect to general room for a user - Email is the room id
    useEffect(() => {
      const local = localStorage.getItem('profile');
      const profile = JSON.parse(local);
      if (profile) props.store.subscribeRoom(profile.email);
      if (profile) {
        // userGuiding credentials
        window.userGuiding.identify(profile.customerId, {
          email: profile.email,
          name: profile.fname + ' ' + profile.lname,
          plan: profile.plan,
          scope: profile.scope,
          grandscope: profile.grandscope,
          created_at: formatDate(profile.created),
          count_chats: profile.count_chats,
          count_templates: profile.count_templates,
          count_attachments: profile.count_attachments,
          verified: profile.verified,
          coins: profile.credits,
        });
      }

      // Modal to use UG in desktop
      const showModal = localStorage.getItem('modalMobileUG');
      if (profile) {
        if (profile.beta.kind_of_work && width <= 1024 && !showModal) {
          setShowModalTutos(true);
          localStorage.setItem('modalMobileUG', 'true');
        }
      }
    }, []);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = date.getUTCDate().toString().padStart(2, '0');
      const hours = date.getUTCHours().toString().padStart(2, '0');
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      const seconds = date.getUTCSeconds().toString().padStart(2, '0');

      return `${year}/${month}/${day}`;
    };

    // Redirect a Sumo-ling for first time to payment section
    useEffect(() => {
      if (
        props.store.profile.scope == 'appsumo' &&
        props.store.profile.beta.state == 'to_check'
      ) {
        navigate('/my-profile/pricing');
      }
    }, []);

    useEffect(() => {
      props.store.listenEvent('process_complete', (event) => {
        let processing = localStorage.getItem(event.process);
        if (location.pathname == event.to && processing != null) {
          navigate({
            pathname: location.pathname,
            search: `?prompt=${processing}`,
          });

          localStorage.removeItem(event.process);
        } else {
          let type =
            event.history.image && event.history.image != ''
              ? 'image'
              : event.history.video && event.history.video != ''
              ? 'video'
              : null;

          setQueue((prevState) => {
            const index = prevState.findIndex(
              (process) => process.id == event.process
            );
            let data = {};
            if (index !== -1) {
              prevState[index].percent = null;
              prevState[index].source = { url: event.history.image, type };
              prevState[index].value =
                'The generation of your result was finished.';
            } else {
              data = {
                id: event.process,
                hash: event.history._id,
                source: { url: event.history.image, type },
                percent: null,
                value: 'The generation of your result was finished.',
                to: event.to,
              };
            }
            return index !== -1 ? prevState : [...prevState, data];
          });
          setToastColorBox('green-100');
          setToastColorText('green-900');
          setRandom(Math.floor(Math.random() * 50000));
          localStorage.removeItem(event.process);
        }
        props.store.unsubscribeRoom(event.process);
        // props.store.refreshTokenAndProfile()
        props.store.updateCredits(event);
      });

      props.store.listenEvent('process_failed', (event) => {
        if (location.pathname != event.to) {
          setToastColorBox('yellow-100');
          setToastColorText('yellow-900');

          let processing = localStorage.getItem(event.process);
          if (location.pathname == event.to && processing != null) {
            navigate({
              pathname: location.pathname,
              search: `?prompt=${processing}`,
            });
            localStorage.removeItem(event.process);
          } else {
            setQueue((prevState) => {
              const index = prevState.findIndex(
                (process) => process.id == event.process
              );
              let data = {};
              if (index !== -1) {
                prevState[index].percent = null;
                prevState[index].source = null;
                prevState[index].value =
                  'Image generation failed, check the details.';
              } else {
                data = {
                  id: event.process,
                  hash: event.history._id,
                  source: null,
                  percent: null,
                  value: 'Image generation failed, check the details.',
                  to: event.to,
                };
              }
              return index !== -1 ? prevState : [...prevState, data];
            });
            localStorage.removeItem(event.process);
          }
          setRandom(Math.floor(Math.random() * 50000));
          props.store.unsubscribeRoom(event.process);
        }
      });

      props.store.listenEvent('processing', (event) => {
        if (location.pathname != event.to) {
          setToastColorBox('green-100');
          setToastColorText('green-900');

          setQueue((prevState) => {
            const index = prevState.findIndex(
              (process) => process.id == event.process
            );
            let data = {};
            if (index !== -1) {
              prevState[index].percent = event.percent;
            } else {
              data = {
                id: event.process,
                hash: event.history._id,
                source: null,
                percent: event.percent,
                value: event.model + ' generation progress.',
                to: event.to,
              };
            }
            return index !== -1 ? prevState : [...prevState, data];
          });
          setRandom(Math.floor(Math.random() * 50000));
        }
      });

      props.store.listenEvent('update_coins', (event) => {
        props.store.updateCredits(event);
      });

      props.store.listenEvent('close_session', () => {
        props.store.handleLogout();
      });

      props.store.listenEvent('reload_page', () => {
        window.location.reload(false);
      });
    }, []);

    const removeDialog = (hash) => {
      const arrCopy = Array.from(queue);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj.hash === hash);
      if (objWithIdIndex != -1) {
        arrCopy.splice(objWithIdIndex, 1);
        setQueue(arrCopy);
      }
    };

    const goToDetail = (message) => {
      removeDialog(message.hash);
      let route = `${message.to}?prompt=${message.hash}`;
      navigate(route);
    };

    const [hovered, setHovered] = useState(0);

    const handleMouseEnter = (value) => {
      setHovered(value);
    };

    const handleMouseLeave = () => {
      setHovered(0);
    };

    return (
      <>
        <div className="flex relative">
          <div className="absolute z-50 md:hidden">
            <div
              className={`w-0.5 bg-eggplant-700 dark:bg-celadon-900 h-screen`}
            ></div>
            <div
              onClick={() => props.onOpen(!props.open)}
              className={`absolute cursor-pointer -right-[16px] top-20 rounded-full bg-eggplant-700 dark:bg-graphite-800 border border-eggplant-700 dark:border-celadon-900`}
            >
              <ChevronRightIcon
                className={`w-8 h-8 pr-0.5 pl-3 py-1 text-white`}
                onClick={() => props.onOpen(!props.open)}
              />
            </div>
          </div>

          <div
            className={`max-md:absolute max-md:top-0 ${
              props.open ? 'max-md:left-0' : 'max-md:-left-20'
            } z-50`}
          >
            <div
              className={`${
                props.open ? 'w-60' : 'w-12'
              } bg-eggplant-700 dark:bg-graphite-800 h-screen pt-0.5 relative duration-300 dark:border-r dark:border-graphite-100`}
            >
              <div
                onClick={() => props.onOpen(!props.open)}
                className={`${
                  !props.open && 'hidden md:block'
                } absolute cursor-pointer -right-4 top-[11.5rem] rounded-full bg-eggplant-700 dark:bg-graphite-100 `}
              >
                <ChevronLeftIcon
                  className={`w-8 h-8 p-1 transition ${
                    !props.open && 'rotate-180'
                  } text-white dark:text-celadon-900`}
                  onClick={() => props.onOpen(!props.open)}
                />
              </div>

              <div
                className="flex gap-x-4 items-center"
                onClick={() => props.onOpen(!props.open)}
              >
                <img
                  src={
                    themeValue == 'dark'
                      ? '/logo-symbol-crayola-600.svg'
                      : '/logo-symbol-snow-100.svg'
                  }
                  // src="/logo-symbol-snow-100.svg"
                  className={`cursor-pointer duration-500 ${
                    props.open && 'rotate-[360deg]'
                  } h-7 w-7 ml-2.5 mt-2`}
                  alt=""
                />
                <img
                  src={
                    themeValue == 'dark' ? '/straico.svg' : '/logo-snow-100.svg'
                  }
                  className={`mt-0.5 -ml-5 ${
                    !props.open ? 'hidden' : 'block'
                  } h-7 pl-4`}
                  // src="/logo-snow-100.svg"
                />
              </div>

              <ul className="mr-0.5 -mt-0.5">
                <NavLink
                  to="/chat"
                  onClick={() => props.onOpen(false)}
                  className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center mt-3 ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <ChatBubbleLeftRightIcon
                      to="/chat"
                      className={`w-6 h-6 transition`}
                      onClick={() => props.onOpen(false)}
                      onMouseEnter={() => handleMouseEnter(1)}
                      onMouseLeave={() => handleMouseLeave(0)}
                    />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Chat
                    </span>
                    {hovered == 1 && !props.open && width > 920 ? (
                      <MyToast label="Chat" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink>

                {/* <NavLink
                  to="/dashboard"
                  
                  onClick={() => props.onOpen(false)}
className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(2)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <WrenchIcon className={`w-6 h-6 transition`} />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Tools
                    </span>
                    {hovered == 2 && !props.open && width > 920 ? (
                      <MyToast label="Tools" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink> */}

                {visibleStableStudio ? (
                  <NavLink
                    to="/images"
                    onClick={() => props.onOpen(false)}
                    className={({ isActive }) =>
                      `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                        isActive
                          ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                          : 'hover:bg-crayola-500/40'
                      }`
                    }
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={() => handleMouseLeave(0)}
                  >
                    <li className="flex items-center justify-center">
                      <PhotoIcon
                        to="/images"
                        className={`w-6 h-6 transition`}
                        onClick={() => props.onOpen(false)}
                        onMouseEnter={() => handleMouseEnter(3)}
                        onMouseLeave={() => handleMouseLeave(0)}
                      />
                      <span
                        className={`${
                          !props.open && 'hidden'
                        } origin-left duration-200 ml-4 font-barlow text-base`}
                      >
                        Images
                      </span>
                      {hovered == 3 && !props.open && width > 920 ? (
                        <MyToast label="Images" />
                      ) : (
                        <></>
                      )}
                    </li>
                  </NavLink>
                ) : (
                  <></>
                )}

                {/* <NavLink
                  to="/history"
                  
                  onClick={() => props.onOpen(false)}
className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(4)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <BookmarkIcon className={`w-6 h-6 transition`} />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Saved
                    </span>
                    {hovered == 4 && !props.open && width > 920 ? (
                      <MyToast label="Saved" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink> */}

                <hr className="mt-2 text-white mx-3"></hr>

                <NavLink
                  to="/my-profile"
                  onClick={() => props.onOpen(false)}
                  className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(5)}
                  onMouseLeave={() => handleMouseLeave(0)}
                  end
                >
                  <li className="flex items-center justify-center">
                    {props.store.profile.avatar.svg ? (
                      <div className="avatar">
                        <div className="w-9 -ml-2 -mt-0.5 rounded">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: props.store.profile.avatar.svg,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <UserCircleIcon
                        className={`w-6 h-6 transition`}
                        to="/my-profile"
                        onClick={() => props.onOpen(false)}
                        onMouseEnter={() => handleMouseEnter(5)}
                        onMouseLeave={() => handleMouseLeave(0)}
                      />
                    )}
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-2 font-barlow text-base`}
                    >
                      My profile
                    </span>
                    {hovered == 5 && !props.open && width > 920 ? (
                      <MyToast label="My profile" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink>

                <NavLink
                  to="/transactions"
                  onClick={() => props.onOpen(false)}
                  className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(6)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <ArrowsRightLeftIcon
                      className={`w-6 h-6 transition`}
                      to="/transactions"
                      onClick={() => props.onOpen(false)}
                      onMouseEnter={() => handleMouseEnter(6)}
                      onMouseLeave={() => handleMouseLeave(0)}
                    />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Transactions
                    </span>
                    {hovered == 6 && !props.open && width > 920 ? (
                      <MyToast label="Transactions" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink>

                <NavLink
                  to="/my-profile/pricing"
                  end
                  onClick={() => props.onOpen(false)}
                  className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(7)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <CreditCardIcon
                      to="/my-profile/pricing"
                      end
                      onClick={() => props.onOpen(false)}
                      className={`w-6 h-6 transition`}
                      onMouseEnter={() => handleMouseEnter(7)}
                      onMouseLeave={() => handleMouseLeave(0)}
                    />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Billing and plans
                    </span>
                    {hovered == 7 && !props.open && width > 920 ? (
                      <MyToast label="Billing and plans" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink>

                <NavLink
                  to="/rewards"
                  onClick={() => props.onOpen(false)}
                  className={({ isActive }) =>
                    `h-12 pl-3 flex rounded-r-lg cursor-pointer  text-white text-sm items-center ${
                      isActive
                        ? 'bg-crayola-600 hover:bg-crayola-300 transition'
                        : 'hover:bg-crayola-500/40'
                    }`
                  }
                  onMouseEnter={() => handleMouseEnter(8)}
                  onMouseLeave={() => handleMouseLeave(0)}
                >
                  <li className="flex items-center justify-center">
                    <TrophyIcon
                      className={`w-6 h-6 transition`}
                      to="/rewards"
                      onClick={() => props.onOpen(false)}
                      onMouseEnter={() => handleMouseEnter(8)}
                      onMouseLeave={() => handleMouseLeave(0)}
                    />
                    <span
                      className={`${
                        !props.open && 'hidden'
                      } origin-left duration-200 ml-4 font-barlow text-base`}
                    >
                      Rewards
                    </span>
                    {hovered == 8 && !props.open && width > 920 ? (
                      <MyToast label="Rewards" />
                    ) : (
                      <></>
                    )}
                  </li>
                </NavLink>

                <ProfileProgress open={props?.open} onOpen={props?.onOpen} />
              </ul>
              {/* <div
                onClick={() => navigate('/whisper')}
                className="absolute bottom-0 h-14 w-12 bg-eggplant-700 hover:bg-crayola-500/40 cursor-pointer"
              ></div> */}
              <div
                className={`${
                  props.open ? '' : 'hidden'
                } absolute bottom-10 left-20 md:hidden`}
              >
                <ToggleTheme classNameContainer="h-full" />
              </div>
            </div>
          </div>
          <div
            id="content-wrapper"
            className="h-screen flex-1 overflow-auto overflow-x-hidden"
          >
            {props.children}
          </div>
        </div>

        {props.store.profile.continue !== 'beta' ? <Welcome /> : <></>}

        {showModalTutos && (
          <ModalMobileUG
            onContinue={() => setShowModalTutos(false)}
            onClose={() => setShowModalTutos(false)}
          />
        )}

        <Toast
          message={queue}
          colorBox={toastColorBox}
          colorText={toastColorText}
          onClick={goToDetail}
          fixed
          minWidth="40%"
          position="end"
          random={random}
        />
      </>
    );
  })
);

const MyToast = ({ label }) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowToast(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={`bg-graphite-700 text-snow-100 px-2 py-0.5 rounded-md ml-4 font-barlow 
      font-light text-center m-auto transition-opacity ${
        showToast ? 'opacity-100' : 'opacity-0'
      } whitespace-nowrap`}
    >
      <span>{label}</span>
    </div>
  );
};

export default Sidebar;
