// Mobx
import SelectMultipleControlled from 'Components/Forms/SelectMultipleControlled';
import { observer, inject } from 'mobx-react';
// RHF
import { useFormContext } from 'react-hook-form';
// Components
import useThemeStore from 'Theme/store';

const SectionGeneral = ({ models }) => {
  // Form
  const { register, control } = useFormContext();

  const { theme } = useThemeStore();

  /* TODO make it multi model */

  return (
    <div className="flex flex-col">
      <p
        className={`${
          theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
        } text-sm font-lexend font-bold`}
      >
        Step 1: General adjustments
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label className="uppercase text-[12px] font-medium font-lexend">
            Template name *
          </label>
          <input
            name="name"
            className={`w-full border ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            } border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md `}
            placeholder="Name of your prompt"
            {...register('name')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-[12px] font-medium font-lexend">
            Description *
          </label>
          <textarea
            className={`w-full border ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            } border-snow-900 border-solid p-[8px] text-sm font-barlow rounded-md `}
            style={{ resize: 'none' }}
            placeholder="A description for your prompt"
            rows={3}
            {...register('description')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="uppercase text-[12px] font-medium font-lexend">
            Default models *
          </label>
          <p className="text-xs font-barlow py-2 text-pretty">
            Need assistance selecting LLMs for your template?{' '}
            <a
              className="text-crayola-600 font-bold"
              target="_blank"
              href="https://straico.com/multimodel"
              rel="noreferrer"
            >
              Click here{' '}
            </a>{' '}
            to view related documentation.
          </p>
          {/* <select
            className={`w-full ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            } border border-snow-900 text-sm border-solid p-[8px] font-barlow rounded-md `}
            {...register('default_model')}
          >
            <option value="">-- Select --</option>

            {models &&
              models.map((model, index) => {
                return (
                  <option
                    key={index}
                    value={model.id?.trim()}
                    className={`border-snow-900 border-solid p-[8px] font-barlow rounded-md ${
                      theme == 'dark'
                        ? 'dark:bg-graphite-900 dark:text-white'
                        : 'bg-snow-100 text-graphite-900'
                    }`}
                  >
                    {model.title}
                  </option>
                );
              })}
          </select> */}
          <SelectMultipleControlled
            name="multi_default_models"
            options={models}
            control={control}
            label="Default model"
            formControlProps={{
              className: `w-full ${
                theme === 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white'
                  : 'bg-snow-100 text-graphite-900'
              } border border-snow-900 text-sm border-solid p-[8px] font-barlow rounded-md`,
            }}
            // selectProps={{
            //   inputProps: { 'aria-label': 'Without label' }
            // }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(SectionGeneral));
