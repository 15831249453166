import { create } from 'zustand';

const useTemplateModalStore = create((set) => ({
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  tab: 1,
  setTab: (tab) => set({ tab }),
  errorModal: false,
  errorModalReason: {
    emptyFields: false,
    emptyAttachments: false,
  },
  setErrorModalReason: (errorModalReason) => set({ errorModalReason }),
  setErrorModal: (errorModal) => set({ errorModal }),
  deleteTemplateModal: false,
  setDeleteTemplateModal: (deleteTemplateModal) => set({ deleteTemplateModal }),
  toDelete: null,
  setToDelete: (toDelete) => set({ toDelete }),
  models: [],
  setModels: (models) => set({ models }),
  confirmCloseModal: false,
  setConfirmCloseModal: (confirmCloseModal) => set({ confirmCloseModal }),
}));

export default useTemplateModalStore;
