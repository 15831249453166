export const supportLanguagesMessages = [
  'javascript',
  'typescript',
  'python',
  'java',
  'js',
  'php',
  'css',
  'html',
  'jsx',
  'rust',
  'go',
  'bash',
  'cpp',
  'ruby',
  'swift',
  'php',
  'sql',
  'markdown',
];

export const highlightWordsTextArea = [
  {
    word: /\$\{(\w+)\}/g,
    classnames:
      'bg-snow-900 text-graphite-700 rounded-md py-1 px-2 font-semibold',
  },
  {
    word: /"'(.+?)'"/gs,
    classnames:
      'bg-snow-900 text-graphite-700 rounded-md py-1 px-2 font-semibold',
  },
];

export const highlightWordsTextAreaV2 = [
  {
    highlight: /\$\{(\w+)\}/g,
    className:
      'bg-snow-900 text-graphite-700 rounded-md py-[2px] font-semibold',
    prefix: ' ',
    suffix: ' ',
  },
  {
    highlight: /""'(.+?)'""/gs,
    className:
      'bg-snow-900 text-graphite-700 rounded-md px-[3px] py-[2px] font-semibold',
    prefix: `   `,
    suffix: `   `,
  },
  {
    highlight: /"'(.+?)'"/gs,
    className:
      'bg-snow-900 text-graphite-700 rounded-md py-[2px] font-semibold',
    prefix: `  `,
    suffix: `  `,
  },
];

export const modelLabels = {
  'gpt-3.5-turbo': 'gpt-3.5',
  'gpt-4': 'gpt-4',
};

export const MAX_TITLE_LENGTH = 85;
