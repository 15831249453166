import React, { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// React Router Dom
import { Navigate, useOutlet } from 'react-router-dom';
// Components
import Sidebar from 'Features/App/Components/Sidebar';
import Header from 'Features/App/Components/Header';
import ComingSoonModal from 'Components/Common/ComingSoonModal/ComingSoonModal';

const AuthLayout = ({ store }) => {
  const outlet = useOutlet();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    localStorage.setItem('totalRewards', null);
  }, []);

  if (!store.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Sidebar open={open} onOpen={(value) => setOpen(value)}>
        <Header open={open} onOpen={() => setOpen(true)} />
        {outlet}
      </Sidebar>
      <ComingSoonModal />
    </>
  );
};
export default inject('store')(observer(AuthLayout));
