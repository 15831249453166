import { useState, useMemo, useEffect, useCallback } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import {
  PlusCircleIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import ToolSvg from 'Theme/icons/Tool.svg';
//Contants
import { getIndex } from 'Constants/prompts';
import { groupByCategory } from 'utils/groupByCategoryTool';
// Store
import usePublicLayoutStore from '../State';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
import usePublicPromptStore from '../State/prompts';
// MUI
import { Drawer, useMediaQuery } from '@mui/material';
// Hooks
import useDebounce from 'Hooks/useDebounce';
// Components
import Loader from 'Components/Loader';
import FoldersTemplates from 'Features/FoldersTemplates';

const drawerWidth = 340;

const PromptSideRender = () => {
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const { showPromptSidebar, togglePromptSidebar, closePromptSidebar } =
    usePublicLayoutStore();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (isMobile) {
      closePromptSidebar();
    }
  }, []);
  return (
    <>
      {isMobile ? (
        <Drawer
          container={container}
          variant="temporary"
          open={showPromptSidebar}
          onClose={togglePromptSidebar}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <PromptSide />
        </Drawer>
      ) : (
        <PromptSide />
      )}
    </>
  );
};

const PromptSide = inject('store')(
  observer(({ store }) => {
    // on search
    const [searchValue, setSearchValue] = useState('');
    const onSearch = (e) => setSearchValue(e.target.value);

    const debouncedValueText = useDebounce(searchValue, 500);

    useEffect(() => {
      getToolsAndFilter(debouncedValueText);
      setSearch(debouncedValueText ?? '');
    }, [debouncedValueText]);

    // State
    const [showSearch, setShowSearch] = useState(false);
    const [tools, setTools] = useState([]);
    // get tools
    const getToolsAndFilter = useCallback(async (text) => {
      setLoading(true);
      try {
        const response = await store.api.get('/public/tool', {
          params: { keyword: text.replace('/', '') },
        });
        // Filter tools by permissions
        const filterData = response.data.tools
          ?.filter((tool) => tool.category !== 'Media')
          .filter((tool) => tool?.in_trash !== true)
          .filter((tool) => tool?.visible === true)
          .sort((a, b) => a.title.localeCompare(b.title));
        // Set tools
        if (externalTools.length > 0) {
          setTools([...filterData, ...externalTools]);
        } else {
          setTools(filterData ?? []);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // order by category tools
    const renderTools = useMemo(() => {
      const renderData = Object.entries(groupByCategory(tools)).reverse();
      if (!renderData.some(([key]) => key === 'My Tools')) {
        renderData.push(['My Tools', []]);
      }
      return renderData.sort((a, b) => {
        const indexA = getIndex(a);
        const indexB = getIndex(b);
        // If index values are not available for both items, keep their order unchanged
        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        // If index value is not available for one item, place it at the end
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }
        // Otherwise, sort based on index value
        return indexA - indexB;
      });
    }, [tools]);

    // Global state management
    const { togglePromptSidebar, openRegisterModal } = usePublicLayoutStore();
    const {
      setSelectedPrompt,
      setSelectedTool,
      openModalPrompt,
      tools: externalTools,
    } = usePublicPromptStore();

    useEffect(() => {
      if (externalTools.length > 0) {
        setTools((prev) => [...prev, ...externalTools]);
      }
    }, [externalTools]);

    // onSelect tool
    const onSelectTool = async (tool) => {
      try {
        setSelectedTool(tool);
        const response = await store.api.get(
          `/public/prompt?toolId=${tool._id}`
        );
        const data = response.data.prompts;
        setSelectedPrompt(data);
        openModalPrompt();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    const { setTreeData, setLoading, loading, setSearch } =
      useTreeDNDTemplatesStore();

    useEffect(() => {
      if (!loading && externalTools.length > 0) {
        onSelectTool(externalTools[0]);
      }
    }, [loading]);
    // Build tree data
    useEffect(() => {
      if (renderTools.length > 0) {
        let tree = [];
        renderTools.forEach((tool, index) => {
          const indexFolder = index + 1;
          // Push the folder
          const [nameDad, childrens] = tool;
          tree.push({
            id: indexFolder,
            parent: 0,
            droppable: true,
            text: nameDad,
          });
          // Push the childrens
          childrens.forEach((tool) => {
            tree.push({
              id: tool._id,
              parent: indexFolder,
              droppable: false,
              text: tool.title,
              data: tool,
            });
          });
        });
        setTreeData(tree);
      }
    }, [renderTools]);

    // Methods templates
    const onClickTemplate = (tool) => onSelectTool(tool);
    const onEditTemplate = () => {};
    const onDeleteTemplate = () => {};
    const onShareTemplate = () => {};

    const onOperationsTemplate = {
      onClickTemplate,
      onEditTemplate,
      onDeleteTemplate,
      onShareTemplate,
    };

    return (
      <>
        <div className="grid grid-cols-12 w-full">
          <div className="col-span-12 md:col-span-11 lg:col-span-12 flex flex-col mx-2">
            <div className="flex items-center gap-2 my-2.5">
              <div
                onClick={togglePromptSidebar}
                className="flex w-[20px] cursor-pointer h-[40px] bg-eggplant-700 rounded items-center justify-center hover:bg-crayola-600"
              >
                <ChevronDoubleRightIcon className="h-6 w-6 text-white" />
              </div>
              <div
                onClick={openRegisterModal}
                className="hidden lg:block w-full px-2 py-2 transition rounded-md hover:shadow-md transform hover:bg-flax-300 hover:border-flax-300 hover:text-eggplant-800 cursor-pointer text-eggplant-700 bg-flax-800"
              >
                <div className="flex">
                  <div className="flex items-center justify-center mr-2">
                    <PlusCircleIcon className={'h-6 w-6'} />
                  </div>
                  <div className="flex-grow truncate">
                    <p className="text-base mt-0.5 font-lexend font-medium uppercase">
                      New Template
                    </p>
                  </div>
                </div>
              </div>
              {/* Folder */}
              <div className="hidden bg-flax-800 rounded-[4px] w-[40px] h-[40px] justify-center items-center">
                <ToolSvg />
              </div>
              <div className="flex w-full justify-between lg:hidden mt-2">
                <div className="font-lexend font-bold uppercase text-eggplant-700 text-2xl pl-4 lg:hidden">
                  Templates
                </div>
                <div className="flex gap-2 lg:hidden ">
                  <div className="w-10 flex items-center justify-center p-[4px] border-white border border-solid rounded-sm">
                    <ToolSvg />
                  </div>
                  <div
                    onClick={() => setShowSearch(!showSearch)}
                    className="w-10 flex items-center justify-center p-[4px] border-white border border-solid rounded-sm"
                  >
                    <MagnifyingGlassIcon className="w-6 h-6 text-eggplant-500" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex focus-within:text-teal-500 w-full items-center justify-center">
              <div className="w-full relative bg-[rgba(217, 217, 217, 1)]">
                <label
                  htmlFor="q"
                  className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2 text-graphite-100"
                >
                  <div
                    type="submit"
                    className="p-1 focus:outline-none focus:shadow-outline "
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className={`w-6 h-6 transition ${
                        showSearch ? 'flex' : 'hidden'
                      }`}
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </div>
                </label>
                {showSearch && (
                  <input
                    onChange={onSearch}
                    type="search"
                    placeholder="Search templates"
                    className="appearance-none bg-white md:pl-10 text-base focus:outline-none focus:bg-white focus:text-eggplant-200 transition flex flex-1 w-full p-1 rounded-lg border-2 border-snow-900 font-barlow placeholder-graphite-100"
                    autoComplete="off"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full relative grid col-span-12 overflow-y-auto overflow-auto  h-full lg:h-[calc(100vh-12em)]">
          {loading ? (
            <div className="absolute inset-0 flex items-center justify-center h-full">
              <Loader active className="m-auto w-10 h-10 pr-10" />
            </div>
          ) : (
            <>
              <FoldersTemplates
                onOperationsTemplate={onOperationsTemplate}
                enableDnd={false}
              />
            </>
          )}
        </div>
      </>
    );
  })
);

export default PromptSideRender;
