import { createBrowserRouter } from 'react-router-dom';
// Pages
import ShareChatPage from 'Pages/ShareChatPage';
import EmailSent from 'Features/Login/EmailSent';
import Search from './Pages/Search';
import Pricing from './Pages/Pricing';
import Dashboard from './Pages/Dashboard';
// import Tool from './Features/Core/Tool';
import Chat from './Features/Core/Chat';
import { ChatProvider } from 'Context/Chat/Chat.context';
import {
  ForgotSection,
  LoginSection,
  SignupSection,
} from 'Features/Login/Login';
import LayoutMyProfile, { MyProfilePage } from './Features/Profile';
import LoginSuccess from './Features/Login/Success';
import Oauth from './Features/Login/Oauth';
import History from './Pages/History';
import Remember from './Features/Login/Remember';
import VerifyMail from './Features/Login/VerifyMail';
import MyAvatar from './Components/MyAvatar';
import Loyalty from 'Features/Login/Loyalty';
import Images from './Pages/Images';
import Transactions from './Pages/transactions';
import RewardsRoom from './Pages/rewardsRoom';
import Whisper from 'Features/Core/Whisper';
import Referral from 'Features/Profile/Referral';
import Feedback from 'Features/Profile/Feedback';
import NotFound from 'Pages/NotFoundPage';
import Profile from './Features/Profile';
import Upgrade from 'Features/Core/Upgrade';
import ShareToolPage from 'Pages/ShareToolPage';
// Layouts
import RootLayout from 'Layouts/RootLayout';
import AuthLayout from 'Layouts/AuthLayout';
import AppSumoLayout from 'Layouts/AppSumoLayout';
import PublicLayout from 'Layouts/PublicLayout';
import UserSettings from 'Components/UserSettings';
import { useOutlet } from 'react-router-dom';
import ErrorBoundaryLayout from 'Layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';

// Chat context
const ChatComponent = () => (
  <ChatProvider>
    <Chat />
  </ChatProvider>
);

// Root layout with error boundary
const Root = () => {
  const outlet = useOutlet();
  return <ErrorBoundaryLayout>{outlet}</ErrorBoundaryLayout>;
};

/**
 * Here are the routes for the app.
 */
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <RootLayout />,
      },
      {
        path: 'login',
        element: <LoginSection />,
      },
      {
        path: 'signup',
        element: <SignupSection />,
      },
      {
        path: 'forgot',
        element: <ForgotSection />,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: 'chat',
            element: <ChatComponent />,
          },
          {
            path: 'images',
            element: <Images />,
          },
          {
            path: 'writing/document',
            element: <div />,
          },
          {
            path: 'whisper',
            element: <Whisper />,
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
          {
            path: 'rewards',
            element: <RewardsRoom />,
          },
          {
            path: 'search',
            element: <Search />,
          },
          {
            path: 'my-avatar',
            element: <MyAvatar />,
          },
          {
            path: 'user-settings',
            element: <UserSettings />,
          },
          {
            path: 'transactions',
            element: <Transactions />,
          },
          {
            path: 'history',
            element: <History />,
          },
          {
            element: <LayoutMyProfile />,
            children: [
              {
                path: 'my-profile',
                element: <MyProfilePage />,
              },
              {
                path: 'my-profile/referral',
                element: <Referral />,
              },
              {
                path: 'my-profile/feedback',
                element: <Feedback />,
              },
            ],
          },
          {
            path: 'my-profile/pricing',
            element: <Pricing />,
          },
          {
            path: 'upgrade',
            element: <Upgrade />,
          },
          {
            path: 'signup/success',
            element: <LoginSuccess />,
          },
          {
            path: 'signup/failed',
            element: <Profile />,
          },
        ],
      },
      {
        path: 'activate-appsumo/:code',
        element: <AppSumoLayout />,
      },
      {
        path: 'auth/:token',
        element: <Oauth />,
      },
      {
        element: <PublicLayout />,
        children: [
          {
            path: 'share/chat/:id',
            element: <ShareChatPage />,
          },
          {
            path: 'share/tool/:id',
            element: <ShareToolPage />,
          },
        ],
      },
      {
        path: 'emailsent',
        element: <EmailSent />,
      },
      {
        path: 'remember/:code',
        element: <Remember />,
      },
      { path: 'loyalty', element: <Loyalty /> },
      { path: 'verify/:code', element: <VerifyMail /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
