import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import toast from 'react-hot-toast';

import 'react-lazy-load-image-component/src/effects/blur.css';
import { GiftIcon } from '@heroicons/react/24/outline';
import { inject, observer } from 'mobx-react';

function Loyalty({ store }) {
  const [email, setEmail] = useState('');

  const onChangeAny = (val, attr) => {
    if (attr === 'email') {
      setEmail(val);
    }
  };

  const onSave = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error('Missing email');
      return;
    }

    try {
      await store.api
        .post('/auth/loyalty-program', {
          email: email,
        })
        .then(({ data }) => {
          if (data.status == 'success') {
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Loyalty - Straico`}</title>
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <header className="h-auto bg-eggplant-700  font-medium font-inter text-2xl py-3">
          <NavLink to="/login">
            <img
              className="h-7 pl-4 -mt-1"
              src="/logo-snow-100.svg"
              alt="Logo"
            />
          </NavLink>
        </header>

        <div className="flex m-auto h-screen bg-snow-300">
          {/* Forms container */}
          <div className="flex mt-8">
            <form onSubmit={onSave} className="bg-snow-300">
              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full ${
                  email ? 'bg-celadon-400' : 'bg-vanilla-300'
                }`}
              >
                <GiftIcon
                  className={`h-8 w-8 ${
                    email ? 'text-celadon-900' : 'text-vanilla-700'
                  } text-${email ? 'celadon-700' : 'graphite-500'}`}
                  aria-hidden="true"
                />
              </div>

              <div className="text-center mt-2">
                <div className="font-inter text-3xl font-bold mb-1 text-center font-lexend uppercase text-eggplant-700">
                  Loyalty program
                </div>
                <p className="text-base text-graphite-400 font-barlow">
                  Please fill this data
                </p>
                <div className="flex flex-col flex-1">
                  <label className="text-graphite-400 uppercase font-lexend text-xs block mt-6 text-left font-light tracking-wider">
                    Email
                  </label>
                  <input
                    value={email}
                    onChange={(e) => onChangeAny(e.target.value, 'email')}
                    type="text"
                    className="bg-snow-100 mt-1 border border-snow-900 font-regular focus:border-celadon-700 w-full py-2 px-3 font-barlow text-base text-graphite-900 leading-tight rounded-md shadow-sm"
                    placeholder="user@mail.com"
                    style={{ backgroundColor: 'white' }}
                  />
                </div>
                <div className="flex flex-col mt-6">
                  <button
                    type="submit"
                    className="bg-[#E57A44] hover:bg-celadon-900 font-lexend text-snow-100 font-bold py-2 px-4 rounded-md uppercase"
                  >
                    claim
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default inject('store')(observer(Loyalty));
