import { useEffect, useMemo, useRef } from 'react';
// Tree view
import { Tree } from '@minoru/react-dnd-treeview';
// DND scrolling
import { useDndScrolling } from 'react-dnd-scrolling';
// Mobx
import { observer, inject } from 'mobx-react';
// Mock data
// import { MOCK_DATA } from './Mock';
// Zustand store
import useChatStore from '../store';
import useTreeDNDChatStore from './Store';
// Intersection Observer
import { InView } from 'react-intersection-observer';
// Components
import CustomDragPreview from './Components/CustomDragPreview';
import CustomNode from './Components/CustomNode';
import Loader from 'Components/Loader';

const TreeDNDChatChild = inject('store')(
  observer(({ onSelectChat, store }) => {
    const { treeData, setTreeData, page, setPage, totalPage, search, loading } =
      useTreeDNDChatStore();

    const ref = useRef();
    const refTree = useRef(null);
    useDndScrolling(ref);

    const memoSortTreeData = useMemo(() => {
      return treeData
        .sort((a, b) => {
          const typeOrder = { chat: 1, folder: 2 };

          return typeOrder[a.data.type] - typeOrder[b.data.type];
        })
        .sort((a, b) => {
          const dateA = new Date(a.data.last_modified);
          const dateB = new Date(b.data.last_modified);
          return dateB - dateA;
        }); // order by date
    }, [treeData]);

    /**
     * Fetch move files and folders
     */
    const fecthMoveFilesAndFolders = async (draggedNode, parentId) => {
      const res = await store.api.put(`/folder/chatFolders/add`, {
        id: draggedNode.id,
        type: draggedNode.data?.type === 'chat' ? 'chat' : 'folder',
        parent_folder: parentId ?? null,
      });
      return res;
    };

    const handleDrop = async (tree, options) => {
      const { dragSourceId, dropTargetId } = options;

      const draggedNode = tree.find((node) => node.id === dragSourceId);
      const destinationNode = tree.find((node) => node.id === dropTargetId);

      // If dragged to the same parent, do nothing
      if (destinationNode?.parent === draggedNode?.parent) {
        return;
      }

      const res = await fecthMoveFilesAndFolders(
        draggedNode,
        dropTargetId == 0 ? null : dropTargetId
      );

      if (res.status === 200) {
        setTreeData(tree);
      }
    };

    // Zustand store
    const [selectedChat] = useChatStore((state) => [state.selectedChat]);
    const handleOpen = (nodeId) => refTree.current.open(nodeId);

    // Open chat when selected and chat is not in root
    useEffect(() => {
      if (selectedChat?.chat?._id) {
        const node = treeData.find(
          (node) => node.id === selectedChat?.chat?._id
        );
        if (node && node.parent !== 0) {
          handleOpen(node.parent);
        }
      }
    }, [selectedChat]);

    return (
      <div
        className="h-[100%] overflow-y-auto w-full overflow-x-hidden"
        ref={ref}
        id="scrollableDivTreeDNDChat"
      >
        <Tree
          rootId={0}
          sort={false}
          ref={refTree}
          enableAnimateExpand
          tree={memoSortTreeData}
          initialOpen={search !== '' ? true : false}
          render={(node, { depth, isOpen, onToggle, handleRef }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              handleRef={handleRef}
              onClickChat={onSelectChat}
              matchingMessages={node.matchingMessages}
            />
          )}
          dragPreviewRender={(monitorProps) => (
            <CustomDragPreview monitorProps={monitorProps} />
          )}
          onDrop={handleDrop}
          classes={{
            root: 'h-auto w-full select-none',
            draggingSource: 'opacity-30',
            dropTarget: 'bg-celadon-100 rounded-md',
          }}
        />
        {search === '' &&
          totalPage > page &&
          memoSortTreeData.length > 0 &&
          !loading && (
            <InView
              onChange={(inView) => {
                if (inView) {
                  setPage(page + 1);
                }
              }}
            >
              <div className="flex items-center justify-center">
                <h2>..</h2>
              </div>
            </InView>
          )}
        {loading && page !== 1 && (
          <div className="flex items-center justify-center mt-2">
            <Loader />
          </div>
        )}
        {page === totalPage && totalPage > 1 && (
          <div className="flex items-center justify-center mt-2">
            <h2>End chat list</h2>
          </div>
        )}
        {memoSortTreeData.length === 0 && search !== '' && !loading && (
          <div className="flex items-center justify-center mt-2">
            <h2>
              Sorry, no results for your search. Adjust your keywords and try
              again. :(
            </h2>
          </div>
        )}
      </div>
    );
  })
);

const TreeDNDChat = ({ onSelectChat }) => {
  return <TreeDNDChatChild onSelectChat={onSelectChat} />;
};

export default TreeDNDChat;
