/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
// Icons
import {
  WrenchIcon,
  PlusIcon,
  ChatBubbleLeftRightIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/solid';
// Context
import { useChat } from '../../../Context/Chat/Chat.context';
// Chat
import { onNewChat } from 'Features/Core/Chat';

const ToolsList = React.forwardRef(
  ({ setShowModelsDialog, filteredOptions, handleScriptForTTS }, ref) => {
    const {
      tools,
      setSelectedTool,
      promptListRef,
      toggleShowPromptModal,
      setShowPromptList,
      handleShowMobilePrompts,
      activePromptIndex,
      setActivePromptIndex,
    } = useChat();

    const [mouseIsOver, setMouseIsOver] = useState(false);

    const ToolItem = ({ onClick, isActive, label, icon: Icon, isHidden }) => {
      if (isHidden) return null;
      return (
        <>
          <div
            onClick={onClick}
            className={`${
              !mouseIsOver && isActive ? 'bg-flax-600 ' : ''
            } cursor-pointer flex items-center px-2 gap-1 ${
              mouseIsOver && 'hover:bg-flax-500'
            }`}
            style={{ display: isHidden ? 'none' : 'flex' }}
          >
            <Icon className="w-5 h-5 text-eggplant-700 p-[1px] font-bold" />
            <li className="py-2 text-sm font-bold font-lexend uppercase">
              {label}
            </li>
          </div>
          <hr className="px-2 bg-flax-700 border-0 h-[2px] my-1" />
        </>
      );
    };

    const handleSelectPrompt = (name) => {
      const selectedToolLocal = tools.find((tool) => tool?.title === name);
      setSelectedTool(selectedToolLocal);
      toggleShowPromptModal();
      setShowPromptList(false);
    };

    useEffect(() => {
      if (promptListRef.current && activePromptIndex != null) {
        promptListRef.current.scrollTop =
          activePromptIndex * (activePromptIndex === 0 ? 30 : 36);
      }
    }, [activePromptIndex, promptListRef]);

    useEffect(() => {
      const handleMouseMove = () => setMouseIsOver(true);
      const handleMouseInactivity = () => setMouseIsOver(false);

      if (promptListRef.current) {
        const promptElement = promptListRef.current;
        promptElement.addEventListener('mousemove', handleMouseMove);
        promptElement.addEventListener('mouseout', handleMouseInactivity);

        return () => {
          promptElement.removeEventListener('mousemove', handleMouseMove);
          promptElement.removeEventListener('mouseout', handleMouseInactivity);
        };
      }
    }, [promptListRef, activePromptIndex]);

    useEffect(() => {
      setActivePromptIndex(0);
    }, [setActivePromptIndex]);

    const visibleOptions = filteredOptions.filter((option) => !option.isHidden);
    const totalItems = visibleOptions.length;
    let renderIndex = -totalItems + 1;

    return (
      <ul
        ref={ref}
        className="absolute bottom-0 w-96 max-w-full max-h-52 overflow-y-scroll rounded border border-graphite-100 bg-flax-100 text-graphite-400 shadow-[0_0_15px_rgba(0,0,0,0.10)]"
        style={{ minWidth: '50px', margin: '10px 0' }}
      >
        <div>
          {visibleOptions.map((option) => {
            const actualIndex = renderIndex;
            renderIndex += 1;

            return (
              <ToolItem
                key={actualIndex}
                onClick={
                  option.name === 'New Chat'
                    ? onNewChat
                    : option.name === 'Select Model'
                    ? () => setShowModelsDialog(true)
                    : option.name === 'Text To Speech'
                    ? () => handleScriptForTTS([])
                    : handleShowMobilePrompts
                }
                isActive={actualIndex === activePromptIndex}
                label={option.name}
                icon={
                  option.name === 'New Chat'
                    ? PlusIcon
                    : option.name === 'Select Model'
                    ? ChatBubbleLeftRightIcon
                    : option.name === 'Text To Speech'
                    ? SpeakerWaveIcon
                    : WrenchIcon
                }
              />
            );
          })}
        </div>
        {tools.length === 0 && (
          <li className="px-3 py-2 text-base">No tools available</li>
        )}
        {tools.map((tool, index) => (
          <li
            key={index + 1}
            className={`${
              !mouseIsOver && index + 1 === activePromptIndex
                ? 'bg-flax-600 '
                : ''
            } cursor-pointer px-3 py-2 text-sm ${
              mouseIsOver && 'hover:bg-flax-500'
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSelectPrompt(tool?.title);
            }}
          >
            {tool?.title || tool?.name}
          </li>
        ))}
      </ul>
    );
  }
);

export default ToolsList;
