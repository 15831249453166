import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
// RHF
import { useController } from 'react-hook-form';

const SelectControlled = ({
  name,
  options,
  control,
  label,
  formControlProps = {},
  selectProps = {},
  variant = 'outlined',
  disabled = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <FormControl {...formControlProps} error={!!error}>
      {!!label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
      {!field.value && (
        <InputLabel
          shrink={false}
          sx={{ top: selectProps.size ?? '-7px !important' }}
        >
          Choose one
        </InputLabel>
      )}
      <Select
        variant={variant}
        label={label}
        size={selectProps.size ?? 'small'}
        labelId={`${name}-label`}
        id={`${name}-select`}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        inputRef={field.ref}
        disabled={disabled}
        {...selectProps}
        inputProps={{ ...selectProps.inputProps }}
      >
        {options.map(({ id, title }) => (
          <MenuItem key={`${id}-${title}`} value={id}>
            {title}
          </MenuItem>
        ))}
      </Select>
      {error && error.message && (
        <FormHelperText sx={{ marginLeft: 0 }} id={`${name}-helper-text`}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectControlled;
